import { Box, Flex, Text } from "@mantine/core";
import Card from "src/components/cards/Card";
import Admin from "src/types/Admin.type";
import Avatar from "../Avatar";
import RoleBadge from "../RoleBadge";

interface Props {
  admin?: Admin;
}

const AdminCard = ({ admin }: Props) => {
  if (!admin) {
    return null;
  }
  const { name, surname, profilePic, role } = admin;

  return (
    <Card to={`user/${admin.id}`}>
      <Flex wrap="nowrap">
        <Box mr="md" w={50}>
          <Avatar size={48} src={profilePic} alt="profile pic" />
        </Box>
        <Flex direction="column">
          <Text fw={800} size="xl" tt="capitalize">
            {surname} {name}
          </Text>
          <RoleBadge role={role} />
        </Flex>
      </Flex>
    </Card>
  );
};

export default AdminCard;

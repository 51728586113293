import { Table, Text } from "@mantine/core";
import { DateTag } from "src/components/badges/DateTag";
import { Fragment } from "react";
import JobOffer from "src/types/JobOffer.type";
import FieldList from "src/components/badges/FieldList";
import Status from "src/components/badges/Status";
import { TableRow } from "src/types/TableRow.type";
import Seniority from "src/components/badges/Seniority";

const JobOfferTableRow = ({ item, onClick }: TableRow<JobOffer>) => {
  return (
    <Fragment key={item.id}>
      <Table.Td onClick={onClick}>
        <Text fw={700} tt={"capitalize"}>
          {item.title}
        </Text>
      </Table.Td>
      <Table.Td onClick={onClick}>
        <Seniority level={item.level} />
      </Table.Td>
      <Table.Td onClick={onClick}>
        <FieldList list={item.fields} cap />
      </Table.Td>
      <Table.Td onClick={onClick}>
        <DateTag date={item.dateFrom} />
      </Table.Td>
      <Table.Td onClick={onClick}>
        <Status item={item} />
      </Table.Td>
    </Fragment>
  );
};
export default JobOfferTableRow;

import { Stack, Title } from "@mantine/core";
import activityLinksConst from "src/constants/activityLinks.const";
import { useTranslation } from "react-i18next";
import Tabs from "src/components/Tabs";

export default function Activity() {
  const { t } = useTranslation();

  return (
    <Stack p="md">
      <Title p="md">{t("activity")}</Title>
      <Tabs tabs={activityLinksConst} />
    </Stack>
  );
}

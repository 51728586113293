import { Box, Stack } from "@mantine/core";
import { InfoList } from "src/components/InfoList";
import FieldList from "src/components/badges/FieldList";
import SkillsList from "src/components/badges/SkillsList";

import Employee from "src/types/Employee.type";
import JobTitle from "../badges/JobTitle";
import Seniority from "../badges/Seniority";

interface Props {
  employee: Employee;
}

const EmployeeInfoSection = ({ employee }: Props) => {
  const {
    level,
    jobTitle,
    fields = [],
    // zones = [],
    skills = [],
    // languages = [],
    // Company,
  } = employee;

  return (
    <Stack gap={5}>
      <InfoList info="job_title">
        <JobTitle value={jobTitle} />
      </InfoList>
      <InfoList info="level">
        <Seniority level={level} />
      </InfoList>
      <InfoList info="fields">
        <Box>
          <FieldList list={fields} cap />
        </Box>
      </InfoList>
      <InfoList info="knowledge">
        <Box>
          <SkillsList list={skills} cap />
        </Box>
      </InfoList>
      {/* <InfoList info="languages">
        <LanguageList languages={languages} />
      </InfoList> */}
      {/* <Group mt="md" wrap="nowrap" align="start">
        <Group w={50}>
          <Rating rate={Company?.score_total} />
        </Group>
        <Group justify="end">
          <ZoneList zones={zones} />
        </Group>
      </Group> */}
    </Stack>
  );
};

export default EmployeeInfoSection;

import { useTranslation } from "react-i18next";
import { Badge, Text } from "@mantine/core";
import { IconCircleFilled } from "@tabler/icons-react";
import PillType from "src/types/pill.type";
import { getRandomColor } from "src/utils/getRandomColor";

const Pill = (props: PillType) => {
  const { t } = useTranslation();
  const rand = getRandomColor(`${props.label}`);
  const {
    Icon = IconCircleFilled,
    label,
    c = rand[0],
    bg = rand[1],
    rightIcon,
    maw,
    naked = false,
  } = props;

  if (!Icon && !label) {
    return null;
  }
  const rightSection = rightIcon && Icon && <Icon size={10} />;
  const leftSection = !rightIcon && Icon && <Icon size={10} />;
  const midSection = props.dontT ? label : t(`${label}`);

  if (naked) {
    return <Text maw={maw}>{midSection}</Text>;
  }

  return (
    <Badge
      c={c}
      maw={maw}
      color={bg}
      rightSection={rightSection}
      leftSection={leftSection}
    >
      {midSection}
    </Badge>
  );
};

export default Pill;

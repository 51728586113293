import { statusColors } from "src/constants/statusColors.const";
import { SOON } from "src/constants/expirySoon.const";

function getExpiryStatus(expiryDate: Date | null) {
  if (!expiryDate) {
    return statusColors.unknown;
  }

  const now = new Date();
  if (expiryDate < now) {
    return "elapsed";
  }

  if (expiryDate.getTime() - SOON < now.getTime()) {
    return "expiring";
  }

  return "active";
}

export default getExpiryStatus;

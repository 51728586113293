import { notifications } from "@mantine/notifications";
import { methodType } from "src/types/Method.type";
import { NotificationType } from "src/types/Notification.type";
import { translate as t } from "./initLanguage";

export function notifyError(
  err: string,
  status: number,
  type?: NotificationType
) {
  console.error({ err });

  return notifications.show({
    title: t(err),
    message: `status code: ${status}`,
    variant: type ?? NotificationType.danger,
    withCloseButton: false,
  });
}

export function notifySuccess(method: methodType) {
  switch (method) {
    case "POST": {
      return notifications.show({
        title: t("create_success"),
        message: "",
        withCloseButton: false,
      });
    }
    case "PATCH":
    case "PUT": {
      return notifications.show({
        title: t("update_success"),
        message: "",
        withCloseButton: false,
      });
    }
    case "DELETE": {
      return notifications.show({
        title: t("remove_success"),
        message: "",
        withCloseButton: false,
      });
    }
    case "GET":
    case "UPLOAD":
    default: {
      return;
    }
  }
}

import { Badge, NavLink, Space } from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import { IconChevronRight } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { SidebarLink } from "src/types/SidebarLinks";

interface Props {
  link: SidebarLink;
}

function NavbarLink({ link }: Props) {
  const { t } = useTranslation();
  const { icon = <Space w={30} /> } = link;
  const location = useLocation();
  const isCurrent = (path: string) => location.pathname === path;

  return (
    <NavLink
      fw={800}
      active
      variant="filled"
      autoContrast={isCurrent(link?.to ?? "")}
      to={link?.to ?? ""}
      bg={isCurrent(link.to ?? "") ? "white" : "transparent"}
      component={Link}
      disabled={link.disabled}
      leftSection={icon}
      rightSection={
        link.notifications ? (
          <Badge size="sm" variant="filled">
            {link.notifications}
          </Badge>
        ) : (
          <IconChevronRight size={14} />
        )
      }
      label={t(link.label)}
      childrenOffset={42}
    />
  );
}

export default NavbarLink;

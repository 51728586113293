import { signOut } from "aws-amplify/auth";

const logout = async () => {
  try {
    await signOut({ global: true });
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

export default logout;

import { Box, Flex, Text } from "@mantine/core";
import Employee from "src/types/Employee.type";
import Avatar from "../Avatar";
import Availability from "../Availability";
import Rate from "../Rate";
import useIsMobile from "src/hooks/useIsMobile";

interface Props {
  employee: Partial<Employee>;
}

const EmployeeSection = ({ employee }: Props) => {
  const {
    name,
    surname,
    profilePic,
    availableFrom,
    availableTo,
    hourlyRate,
    costsIncluded,
  } = employee;
  const isMobile = useIsMobile();
  const direction = isMobile ? "column" : "row";

  return (
    <Flex wrap="nowrap" direction={direction}>
      <Box mr="md" w={50}>
        <Avatar size={48} src={profilePic} alt="profile pic" />
      </Box>
      <Flex flex={1} direction="column">
        <Flex
          gap={5}
          justify="space-between"
          wrap="nowrap"
          direction={direction}
        >
          <Text fw={800} size="xl" tt="capitalize">
            {surname} {name}
          </Text>
          <Text size="lg" fw={700}>
            <Rate rate={hourlyRate} costsIncluded={costsIncluded} />
          </Text>
        </Flex>
        <Flex justify="end">
          <Availability from={availableFrom} to={availableTo} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EmployeeSection;

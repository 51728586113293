import _get from "lodash/get";
import isCompany from "./isCompany";

export default function getCompanyId(item?: object) {
  if (!item) {
    return null;
  }
  if (isCompany(item)) {
    return _get(item, "id");
  }
  return _get(item, "companyId") || _get(item, ["CompanyFrom", "id"]);
}

import { Divider, Group, Stack, Text, Title } from "@mantine/core";
import DateRange from "src/components/badges/DateRange";
import { Row } from "src/components/Row";
import NotFound from "../NotFound";
import Admin from "src/types/Admin.type";
import Avatar from "../Avatar";
import Actions from "../Actions";
import { UserRole } from "src/types/UserRole";
import { useTranslation } from "react-i18next";
import useUser from "src/hooks/useUser";
import RoleBadge from "../RoleBadge";
import logout from "src/utils/logout";

interface Props {
  admin: Admin;
}

export default function AdminView({ admin }: Props) {
  const { t } = useTranslation();
  const { user } = useUser();

  if (!admin) {
    return <NotFound label={t("admin")} />;
  }

  return (
    <>
      <Title m="md">
        {admin.name} {admin.surname}
      </Title>
      <Divider my="md" />
      <Row title={<Avatar src={admin.profilePic} alt="profile pic" />}>
        <Stack>
          <Group my="md">
            <Title order={5}>{t("info_name")}</Title>
            <Text>
              {admin.name} {admin.surname}
            </Text>
          </Group>

          <Group my="md">
            <Title order={5}>{t("info_email_address")}</Title>
            <Text>{admin.email}</Text>
          </Group>

          <Group my="md">
            <Title order={5}>{t("role")}</Title>
            <RoleBadge role={admin.role} />
          </Group>

          <Group my="md">
            <Title order={5}>{t("user_created")}</Title>
            <DateRange from={admin.meta_created_at} />
          </Group>
        </Stack>
      </Row>

      <Actions
        actions={[
          {
            label: t("back"),
            to: "/app",
            variant: "default",
          },
          {
            label: t("edit"),
            to: `/app/edit/user/${admin.id}`,
            disabled: user?.role === UserRole.ReadOnly,
          },
          user?.id === admin?.id
            ? {
                label: t("logout"),
                onClick: () => logout(),
                variant: "default",
              }
            : null,
        ]}
      />
    </>
  );
}

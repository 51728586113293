import getUser from "src/utils/getUser";
import useSWR from "swr";

const useCognitoUser = () => {
  const {
    error,
    isLoading,
    data: cognitoUser,
  } = useSWR("cognitouser", () => getUser());

  return {
    error,
    isLoading,
    cognitoUser,
  };
};

export default useCognitoUser;

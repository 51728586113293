import { useTranslation } from "react-i18next";
import _map from "lodash/map";
import { Flex, Paper, Title } from "@mantine/core";
import ActionButton from "./ActionButton";
import useIsMobile from "src/hooks/useIsMobile";

interface Props {
  title?: string;
  center?: boolean;
  isReady?: boolean;
  actions?: ({
    to?: string;
    label?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    variant?: string;
    color?: string;
    disabled?: boolean;
    Icon?: React.FC;
    tooltip?: string;
    cost?: number;
  } | null)[];
}

const Actions = (props: Props) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const direction = isMobile ? "column" : "row";
  const { actions = [], title = "", center, isReady = true } = props;
  const style = {
    background: title
      ? "linear-gradient(90deg, var(--mantine-color-primary-filled), transparent)"
      : "transparent",
    boxShadow: "none",
    border: "none",
  };

  return (
    <Paper c="white" px="md" py="xs" mb="xl" style={style}>
      <Flex justify="space-between" align="center" direction={direction}>
        <Flex direction="column" my="md">
          <Title order={4} tt="uppercase">
            {t(title)}
          </Title>
        </Flex>
        <Flex
          direction={direction}
          justify={center || isMobile ? "center" : "end"}
          gap="sm"
          wrap="wrap"
        >
          {_map(actions, (action) => {
            if (!action) {
              return null;
            }
            const {
              to = "/",
              label,
              variant = "primary",
              onClick,
              color,
              disabled = false,
              Icon = undefined,
              tooltip,
              cost = 0,
            } = action;

            return (
              <ActionButton
                key={to + label}
                to={to}
                label={label}
                variant={variant}
                onClick={onClick}
                color={color}
                disabled={disabled}
                Icon={Icon}
                tooltip={tooltip}
                cost={cost}
                isReady={isReady}
              />
            );
          })}
        </Flex>
      </Flex>
    </Paper>
  );
};

export default Actions;

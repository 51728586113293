import { Badge, Group } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface Props {
  list?: string[];
  big?: boolean;
  naked?: boolean;
}

const VisaList = ({ list = [], naked = false }: Props) => {
  const { t } = useTranslation();

  if (naked) {
    return <>{list.map((label) => t(label)).join(", ")}</>;
  }
  return (
    <Group>
      {list.map((label) => (
        <Badge key={label} color="gray">
          {t(label)}
        </Badge>
      ))}
    </Group>
  );
};

export default VisaList;

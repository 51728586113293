import _map from "lodash/map";
import _floor from "lodash/floor";
import _isEmpty from "lodash/isEmpty";
import { Button, Card, List, Notification, Title } from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Package } from "src/types/Package.type";
import useUser from "src/hooks/useUser";
import { useNavigate } from "react-router-dom";

export default function CreditsCard({ p }: { p: Package }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, hasSubscription, isReadOnlyRole } = useUser();
  const email = user?.Company?.contact_mail;
  const isBetaMode = import.meta.env.VITE_BETA_MODE === "true";

  const proceed = () => {
    const url = `${p.link}?prefilled_email=${email}&client_reference_id=${user?.companyId}`;
    navigate("/app");
    window?.open(url, "_blank")?.focus();
  };

  return (
    <Card
      key={p.id}
      miw={300}
      maw={300}
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      mx={0}
    >
      <Title order={3} fw={900} ta="center" my="md" c="darkred">
        {p.title}
      </Title>

      <Title fw={900} ta="center">
        {_floor(p.cost ?? 0, 2)} €
      </Title>

      {!_isEmpty(p.desc.join()) && (
        <List
          spacing="xs"
          size="sm"
          center
          icon={<IconCircleCheck color="orange" />}
        >
          {_map(p.desc, (d) => (
            <List.Item fw={600} key={d}>
              {d}
            </List.Item>
          ))}
        </List>
      )}

      {!email && (
        <Notification variant="danger">{t("email_required_buy")}</Notification>
      )}

      <Button
        fullWidth
        mt="xl"
        radius="md"
        onClick={proceed}
        disabled={!hasSubscription || isReadOnlyRole || isBetaMode || !email}
      >
        {t("buy_now")}
      </Button>
    </Card>
  );
}

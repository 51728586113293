import Card from "src/components/cards/Card";
import ApplicationSection from "src/components/Sections/Application.Section";
import Application from "src/types/Application.type";
import CompanyFromTo from "../CompanyFromTo";
import { Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import JobOfferCard from "./Job.Card";

interface Props {
  application: Application;
}

const ApplicationCard = ({ application }: Props) => {
  const { JobOffer } = application;
  const { t } = useTranslation();

  return (
    <Card to={`application/${application.id}`}>
      <CompanyFromTo el={application} />
      <ApplicationSection application={application} />
      <Title order={4}>{t("job_offer")}</Title>
      <JobOfferCard jobOffer={JobOffer} />
    </Card>
  );
};

export default ApplicationCard;

import _noop from "lodash/noop";
import {
  Button,
  Flex,
  LoadingOverlay,
  Space,
  Text,
  Title,
} from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { IconCoins } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TradeType } from "src/types/TradeType";

const SpendCreditsModal = (
  props: ContextModalProps<{
    type: TradeType;
    elementId: string;
    cost: number;
    balance: number;
    reason: string;
    onCancel: () => void;
    onBuyMore: () => void;
    onSuccess: () => void;
  }>
) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<"success" | "error" | "initial">(
    "initial"
  );
  const { id, context, innerProps } = props;
  const {
    cost,
    balance,
    reason,
    onCancel = _noop,
    onBuyMore = _noop,
    onSuccess = _noop,
  } = innerProps;
  const { t } = useTranslation();

  const notEnoughCredits = balance - cost < 0;
  const close = () => {
    context.closeModal(id);
  };
  const cancel = () => {
    onCancel();
    close();
  };
  const buyMore = () => {
    onBuyMore();
    close();
  };
  const proceed = async () => {
    setLoading(true);
    try {
      await onSuccess();
      setLoading(false);
      setStatus("success");
    } catch (err) {
      setLoading(false);
      setStatus("error");
    }
  };

  if (status === "error") {
    return (
      <Flex direction="column" align="center" px="md">
        <Title>{t("spend_error")}</Title>
        <Text>{t("spend_error_desc")}</Text>

        <Flex direction="column" w="100%" mt="md">
          <Button fullWidth mt="sm" onClick={cancel} variant="default">
            {t("close")}
          </Button>
        </Flex>
      </Flex>
    );
  }

  if (status === "success") {
    close();
    return;
    // return (
    //   <Flex direction="column" align="center" px="md">
    //     <Title>{t("spend_success")}</Title>
    //     <Text>{t("spend_success_desc")}</Text>

    //     <Flex direction="column" w="100%" mt="md">
    //       <Button fullWidth mt="sm" onClick={close} variant="default">
    //         {t("close")}
    //       </Button>
    //     </Flex>
    //   </Flex>
    // );
  }

  return (
    <Flex direction="column" align="center" px="md">
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Text>{t("spend_credits_explain_1")}</Text>
      <Text fw={800}>{reason}</Text>
      <Text>{t("spend_credits_explain_2")}</Text>
      <Space h="md" />
      <IconCoins size={36} />
      <Text fw={800}>{cost} credits</Text>
      <Space h="md" />
      <Text c={notEnoughCredits ? "red" : "black"}>
        ({t("current_balance")}: {balance} credits)
      </Text>

      <Flex direction="column" w="100%" mt="md">
        <Button fullWidth mt="sm" onClick={proceed} disabled={notEnoughCredits}>
          {t("proceed") + ` (${cost} credits)`}
        </Button>
        <Button fullWidth mt="sm" onClick={buyMore}>
          {t("buy_more_credits")}
        </Button>
        <Button fullWidth mt="sm" onClick={cancel} variant="default">
          {t("cancel")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default SpendCreditsModal;

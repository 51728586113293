import { ActionIcon, Group, rem } from "@mantine/core";
import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";

interface Props {
  id: string;
  section: string;
  readOnly?: boolean;
  showViewBtn?: boolean;
}

const RowActions = (props: Props) => {
  const { id, section, readOnly = false, showViewBtn = false } = props;
  const navigate = useNavigate();
  const s = { width: rem(16) };
  const goTo = (path: string) => {
    navigate(path);
  };

  return (
    <Group gap={0} justify="center" wrap="nowrap">
      {showViewBtn && (
        <ActionIcon
          variant="subtle"
          color="gray"
          component={Link}
          to={`/app/view/${section}/${id}`}
        >
          <IconEye style={s} />
        </ActionIcon>
      )}
      {readOnly ? null : (
        <>
          <ActionIcon
            variant="subtle"
            color="gray"
            onClick={() => goTo(`/app/edit/${section}/${id}`)}
          >
            <IconPencil style={s} />
          </ActionIcon>
          <ActionIcon
            variant="subtle"
            color="red"
            onClick={() => goTo(`/app/remove/${section}/${id}`)}
          >
            <IconTrash style={s} />
          </ActionIcon>
        </>
      )}
    </Group>
  );
};

export default RowActions;

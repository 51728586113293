import { Stack } from "@mantine/core";
import useUser from "src/hooks/useUser";

export default function Account() {
  const { user } = useUser();

  return (
    <Stack>
      <pre>{JSON.stringify({ user }, null, 2)}</pre>
    </Stack>
  );
}

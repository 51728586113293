const notificationsSettings = [
  {
    id: "matches",
    title: "new_matches",
    desc: "matches_desc",
  },
  {
    id: "applications",
    title: "new_applications",
    desc: "applications_desc",
  },
  {
    id: "proposals",
    title: "new_proposals",
    desc: "proposals_desc",
  },
  {
    id: "marketing",
    title: "marketing",
    desc: "marketing_desc",
  },
];

export default notificationsSettings;

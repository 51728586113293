import _get from "lodash/get";
import Avatar from "src/components/Avatar";
import { Box, Divider, Flex, Paper, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { getDistanceFromDate } from "src/helpers/formatDate";
import JobOffer from "src/types/JobOffer.type";
// import { DateTag } from "./badges/DateTag";
import Rate from "./Rate";
import Employee from "src/types/Employee.type";
import { Row } from "./Row";
import JobTitle from "./badges/JobTitle";
import Availability from "./Availability";
import ExpiryStatusPill from "./ExpiryStatusPill";
import CompanySection from "./Sections/Company.Section";
import useIsMobile from "src/hooks/useIsMobile";
// import SeeMore from "./SeeMore";

interface Props {
  post: Partial<JobOffer | Employee>;
  isOwn: boolean;
}

const PostHeader = ({ post, isOwn }: Props) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const availableFrom = _get(post, "availableFrom");
  const availableTo = _get(post, "availableTo");
  const profilePic = _get(post, "profilePic");
  const title = _get(post, "title");
  const name = [_get(post, "name"), _get(post, "surname", "")].join(" ");

  return (
    <>
      <Title
        order={2}
        lineClamp={1}
        c={title ? "primary" : "black"}
        mx="md"
        tt={"capitalize"}
        ta={isMobile ? "center" : "left"}
      >
        {title}
        {name}
      </Title>
      {post.meta_created_at && (
        <Text
          fw={300}
          size="sm"
          c="dimmed"
          mx="md"
          ta={isMobile ? "center" : "left"}
        >
          {`${t("posted")} ${getDistanceFromDate({
            from: post?.meta_created_at,
          })}`}
        </Text>
      )}
      <Divider my="md" />
      <Row title={<Avatar size={180} src={profilePic} />}>
        <Flex
          direction="column"
          flex={1}
          gap="sm"
          justify={isMobile ? "center" : "space-between"}
          wrap="wrap"
        >
          {post?.jobTitle && (
            <Title my="md" order={2} miw={300} ta="center">
              <JobTitle value={post?.jobTitle} naked />
            </Title>
          )}

          <Box>
            <Title order={5} my="md">
              {t("level") + ":"}
            </Title>
            <Title mx="md" order={4} ta={isMobile ? "center" : "left"}>
              {t(post?.level ?? "")}
            </Title>
          </Box>
        </Flex>

        <Flex direction="column" flex={1} gap="sm" justify="space-between">
          <Box>
            <Title order={5} my="md">
              {t("hourly_rate") + ":"}
            </Title>
            <Title m="md" order={4} ta={isMobile ? "center" : "left"}>
              <Rate
                rate={post?.hourlyRate}
                costsIncluded={post?.costsIncluded}
              />
            </Title>
          </Box>
          <Box>
            <Title order={5} my="md">
              {t("availability") + ":"}
            </Title>
            <Box mx="md" ta={isMobile ? "center" : "left"}>
              <Availability from={availableFrom} to={availableTo} />
            </Box>
          </Box>
        </Flex>
      </Row>
      <Row title={t("company") + ":"}>
        <Paper my="md">
          <CompanySection company={post?.Company} />
        </Paper>
      </Row>
      {isOwn && (
        <Row title={t("expiry_date_title") + ":"}>
          <ExpiryStatusPill item={post} type="jobOffer" />
        </Row>
      )}
    </>
  );
};

export default PostHeader;

// import { Paper, Text, Title } from "@mantine/core";
// import { useTranslation } from "react-i18next";

interface Props {
  isOwn: boolean;
  type:
    | "application"
    | "proposal"
    | "jobOffer"
    | "employee"
    | "company"
    | "review";
  isConnected?: boolean;
}

// const getTextSchema = (prefix: string) => {
//   return {
//     title: `${prefix}_title`,
//     text: `${prefix}_text`,
//   };
// };

// const getText = (
//   props: Props
// ): {
//   title?: string;
//   text?: string;
//   value?: string;
// } | null => {
//   const { isOwn, type, isConnected } = props;
//   if (isConnected) {
//     return null;
//   }

//   if (isOwn && (type === "jobOffer" || type === "employee")) {
//     return null;
//   }

//   switch (type) {
//     case "jobOffer":
//       return getTextSchema("job_offer");

//     case "employee":
//       return getTextSchema("employee");

//     case "proposal":
//       return getTextSchema(isOwn ? "own_proposal" : "proposal");

//     case "application":
//       return getTextSchema(isOwn ? "own_application" : "application");

//     case "company":
//       return isOwn ? null : getTextSchema("company");

//     default:
//       return null;
//   }
// };

// const SeeMore = (props: Props) => {
//   const { t } = useTranslation();
//   const res = getText(props);
//   if (!res) {
//     return null;
//   }
//   const { title = "", text = "" } = res;

//   return (
//     <Paper c="primary">
//       <Title order={3}>{t(title)}</Title>
//       <Text>{t(text)}</Text>
//     </Paper>
//   );
// };

const SeeMore = (props: Props) => {
  console.log({ props });
  return null;
};
export default SeeMore;

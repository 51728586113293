import _map from "lodash/map";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _includes from "lodash/includes";
import { Table, rem, Checkbox, Paper } from "@mantine/core";
import style from "./SelectTable.module.css";
import { ComponentType } from "react";
import RowActions from "./RowActions";
import { useTranslation } from "react-i18next";

interface Props<T> {
  Row: ComponentType<{ item: T }>;
  rows: (T & { id: string })[];
  cols: string[];
  section: string;
  selection: string[] | string;
  multiValue?: boolean;
  setSelection: (curr: string[] | string) => void;
  readOnly?: boolean;
}

function SelectTable<T>({
  Row,
  rows,
  cols,
  section,
  selection,
  multiValue = false,
  setSelection,
  readOnly = false,
}: Props<T>) {
  {
    const { t } = useTranslation();

    const toggleRow = (id: string) => {
      const newSelection = multiValue
        ? _includes(selection, id)
          ? _filter(selection, (item) => item !== id)
          : [...selection, id]
        : selection === id
        ? ""
        : id;
      setSelection(newSelection);
    };
    const toggleAll = () =>
      multiValue
        ? setSelection(_size(selection) ? [] : _map(rows, (item) => item.id))
        : {};

    const isSelected = (rowId: string) =>
      multiValue ? _includes(selection, rowId) : rowId === selection;

    return (
      <Paper p={0} radius="lg">
        <Table.ScrollContainer h={500} minWidth={900} m="auto">
          <Table verticalSpacing="sm" highlightOnHover>
            <Table.Thead>
              <Table.Tr>
                <Table.Th style={{ width: rem(40) }}>
                  {multiValue && (
                    <Checkbox
                      onChange={toggleAll}
                      checked={_size(selection) === _size(rows)}
                      indeterminate={
                        _size(selection) > 0 && _size(selection) !== _size(rows)
                      }
                    />
                  )}
                </Table.Th>
                {_map(cols, (c, index) => (
                  <Table.Th key={c + index}>{t(c)}</Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {_map(rows, (row, index) => (
                <Table.Tr
                  key={row.id + index}
                  className={isSelected(row.id) ? style.rowSelected : ""}
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleRow(row.id)}
                >
                  <Table.Td>
                    <Checkbox
                      checked={isSelected(row.id)}
                      onChange={() => toggleRow(row.id)}
                    />
                  </Table.Td>
                  <Row item={row} />
                  {!readOnly && (
                    <Table.Td>
                      <RowActions
                        id={row.id}
                        showViewBtn
                        section={section}
                        readOnly={readOnly}
                      />
                    </Table.Td>
                  )}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Paper>
    );
  }
}

export default SelectTable;

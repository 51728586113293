import { ChangeEvent } from "react";

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function InputFile({ onChange }: Props) {
  return (
    <input
      type="file"
      onChange={onChange}
      accept=".jpg,.jpeg,.png,.webp,.gif"
    />
  );
}
export default InputFile;

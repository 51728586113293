import _includes from "lodash/includes";
import CONFIG_KEYS from "src/constants/config.const";

export function getTLabel(id?: string) {
  if (id && _includes(CONFIG_KEYS, id)) {
    return id.split("_cost")[0];
  } else {
    return "publish_for";
  }
}

import { useTranslation } from "react-i18next";
import { Badge, Tooltip } from "@mantine/core";
import getExpiryDate from "src/utils/getExpiryDate";
import useExpiryValues from "src/hooks/useExpiryValues";

interface Props {
  item: {
    status?:
      | "draft"
      | "open"
      | "closed"
      | "cancelled"
      | "accepted"
      | "denied"
      | "unknown"
      | "warning"
      | string;
    expiryDate?: string | Date;
  };
}

const Status = ({ item }: Props) => {
  const { t } = useTranslation();

  const expiryDate = getExpiryDate(item);
  const { color, variant, label, status } = useExpiryValues(expiryDate);

  if (!expiryDate) {
    return null;
  }

  return (
    <Tooltip label={t(status)}>
      <Badge color={color} variant={variant} w={130}>
        {label}
      </Badge>
    </Tooltip>
  );
};

export default Status;

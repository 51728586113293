import { Accordion, Box, Flex, Title } from "@mantine/core";
import _get from "lodash/get";
import _each from "lodash/each";
import _map from "lodash/map";
import _set from "lodash/set";
import { useMemo } from "react";
import { InputMatrixOption } from "src/types/InputMatrixOption";
import InputMatrix from "./InputMatrix";
import countActiveValues from "src/utils/countActiveValues";

interface Props {
  values: string[];
  setValues: (a: string[]) => void;
  readonly?: boolean;
  availableValueGroups: {
    type: string;
    title: string;
    availableValues: InputMatrixOption[];
  }[];
}

const MultiInputMatrix = ({
  values,
  setValues,
  readonly = false,
  availableValueGroups,
}: Props) => {
  const sizes = useMemo(() => {
    const res = {};
    _each(availableValueGroups, (group, index) => {
      const count = countActiveValues(values, group.availableValues);
      _set(res, [index], count);
    });
    return res;
  }, [availableValueGroups, values]);

  if (readonly) {
    return (
      <Flex direction="column">
        {_map(availableValueGroups, (item, index) => {
          const count = _get(sizes, [index], 0);
          if (count === 0) {
            return null;
          }

          return (
            <Flex direction="column" key={item.title}>
              <Title order={5}>
                {item.title} {count ? `[${count}]` : null}
              </Title>
              <Box>
                <InputMatrix
                  readonly={readonly}
                  values={values}
                  setValues={setValues}
                  availableValues={item.availableValues}
                />
              </Box>
            </Flex>
          );
        })}
      </Flex>
    );
  }

  return (
    <Accordion variant="contained" defaultValue={null}>
      {_map(availableValueGroups, (item, index) => {
        const count = _get(sizes, [index], 0);

        return (
          <Accordion.Item key={item.title} value={`${index}`}>
            <Accordion.Control miw={235}>
              {item.title} {count ? `[${count}]` : null}
            </Accordion.Control>
            <Accordion.Panel>
              <InputMatrix
                readonly={readonly}
                values={values}
                setValues={setValues}
                availableValues={item.availableValues}
              />
            </Accordion.Panel>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

export default MultiInputMatrix;

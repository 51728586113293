import { useNavigate, useParams } from "react-router-dom";
import ProposalForm from "src/components/forms/Proposal.form";
import Loader from "src/components/Loader";
import useApi, { useGET } from "src/hooks/useApi";
import Proposal from "src/types/Proposal.type";

export default function EditProposal() {
  const navigate = useNavigate();
  const { id } = useParams();
  const api = useApi();

  const url = `res/proposal/${id}`;
  const { data, isLoading, error } = useGET<Proposal>(url);

  const onSubmit = async (values: Partial<Proposal>) => {
    const res = await api.PUT(`res/proposal/${id}`, values);
    if (res?.success) {
      navigate(`/app/view/proposal/${id}`);
    }
  };

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <ProposalForm
        title="update_proposal"
        initialValues={data}
        onSubmit={onSubmit}
      />
    </Loader>
  );
}

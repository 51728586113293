import Company from "./Company.type";

export enum NotificationType {
  info = "info",
  success = "success",
  warning = "warning",
  danger = "danger",
  modal = "modal",
}
export enum NotificationStatus {
  unread = "unread",
  read = "read",
  deleted = "deleted",
}

export default interface Notification {
  id: string;
  title: string;
  desc: string;
  descFull: string;
  type: NotificationType;
  sticky: boolean;
  read: boolean;
  shown: boolean;
  companyId?: string;
  link?: string;
  Company?: Company;
  meta_created_at: string;
  meta_updated_at: string;
}

import useUser from "./useUser";
import { useGET } from "./useApi";
import getCompanyId from "src/utils/getCompanyId";

export default function useIsConnected(item?: object) {
  const { user } = useUser();
  const companyId = getCompanyId(item);
  const { data } = useGET<{ result: boolean }>(
    `account/isMatchedTo/${companyId}`
  );

  // no subject
  if (!item) {
    return false;
  }

  // same company
  if (user.companyId === companyId) {
    return true;
  }

  // endpoint result
  return data?.result || false;
}

import _map from "lodash/map";
import { useTranslation } from "react-i18next";
import { Button, Stack, Title } from "@mantine/core";
import Loader from "src/components/Loader";
import { useGET } from "src/hooks/useApi";
import { Tutorial as TutorialType } from "src/types/Tutorial.type";
import useUser from "src/hooks/useUser";
import { Link } from "react-router-dom";

export default function TutorialsList() {
  const { user } = useUser();
  const { t } = useTranslation();
  const lang = user?.settings_language ?? "en";
  const { data, isLoading, error } = useGET<TutorialType[]>(
    `config/tutorials/${lang}`
  );

  return (
    <Stack align="center">
      <Title>{t("tutorial")}</Title>
      <Loader isLoading={isLoading} error={error} hasData={!!data}>
        {_map(data, (el) => (
          <Button variant="subtle" component={Link} to={`./${el.id}`} fullWidth>
            {el.title}
          </Button>
        ))}
      </Loader>
    </Stack>
  );
}

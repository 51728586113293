import _get from "lodash/get";
import _toLower from "lodash/toLower";
import languagesByCode from "src/constants/languagesByCode";
import { Badge } from "@mantine/core";
import CountryIcon from "./CountryIcon";

interface Props {
  id: string;
}

const LanguageIcon = ({ id }: Props) => {
  const label = _get(languagesByCode, [_toLower(id), "name"], id);
  const icon = <CountryIcon id={id} />;

  return (
    <Badge variant="light" color="gray" leftSection={icon}>
      {label}
    </Badge>
  );
};

export default LanguageIcon;

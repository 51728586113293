import { Avatar as MatineAvatar } from "@mantine/core";
import { ReactNode } from "react";

interface Props {
  src?: string | null | undefined;
  size?: number;
  alt?: string;
  children?: ReactNode;
  radius?: "sm" | "md" | "lg" | "xl";
}
const baseUrl = import.meta.env.VITE_API_URL;

const Avatar = (props: Props) => {
  const { src, size = 160, alt, children, radius = "xl" } = props;

  return (
    <MatineAvatar
      w={size}
      h={size}
      src={src ? `${baseUrl}/uploads/${src}` : undefined}
      miw={size}
      maw={size}
      alt={alt}
      radius={radius}
    >
      {children}
    </MatineAvatar>
  );
};

export default Avatar;

import { Box, Rating, Stack, Text, Title } from "@mantine/core";
import { getDistanceFromDate } from "src/helpers/formatDate";
import Description from "src/components/Description";
import { InfoList } from "src/components/InfoList";
import Review from "src/types/Review.type";

interface Props {
  review: Partial<Review>;
}

const ReviewSection = ({ review }: Props) => {
  const {
    title,
    meta_created_at,
    score_reliability,
    score_skills,
    score_timelines,
    score_total,
    desc,
  } = review;

  return (
    <>
      <Stack gap="sm" h={250}>
        <Box>
          <Box w={300} h={40} style={{ overflow: "hidden" }}>
            <Title order={3}>{title}</Title>
          </Box>
          {meta_created_at && (
            <Text fw={300} size="xs">
              {`Posted ${getDistanceFromDate({ from: meta_created_at })}`}
            </Text>
          )}
        </Box>
        <Box>
          <InfoList info="reliability">
            <Rating readOnly defaultValue={score_reliability} />
          </InfoList>
          <InfoList info="skills">
            <Rating readOnly defaultValue={score_skills} />
          </InfoList>
          <InfoList info="timelines">
            <Rating readOnly defaultValue={score_timelines} />
          </InfoList>
          <InfoList info="total">
            <Rating readOnly defaultValue={score_total} />
          </InfoList>
        </Box>
        <Description text={desc} />
      </Stack>
    </>
  );
};

export default ReviewSection;

import _get from "lodash/get";
import { Badge, Tooltip } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface Props {
  level?: string;
}

const colors = {
  junior: "red",
  mid: "yellow",
  senior: "green",
  expert: "blue",
};

const Seniority = ({ level = "" }: Props) => {
  const { t } = useTranslation();
  const color = _get(colors, [level], "default");

  if (!level) {
    return null;
  }

  return (
    <Tooltip label={t(level)}>
      <Badge color={color} w={100}>
        {t(level + "_sm")}
      </Badge>
    </Tooltip>
  );
};

export default Seniority;

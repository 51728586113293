import { Center, Image, Stack } from "@mantine/core";
import Avatar from "./Avatar";
interface Props {
  value?: File;
  defaultValue?: string | undefined | null;
}

function ImagePreview({ value, defaultValue }: Props) {
  const image = value ? (
    <Image src={URL.createObjectURL(value)} w={150} radius="lg" />
  ) : (
    <Avatar src={defaultValue} />
  );

  return (
    <Center>
      <Stack>{image}</Stack>
    </Center>
  );
}

export default ImagePreview;

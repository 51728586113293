import _isArray from "lodash/isArray";
import Employee from "src/types/Employee.type";
import SelectTable from "./SelectTable";
import EmployeeTableRow from "./rows/EmployeeTable.Row";
import { useGET } from "src/hooks/useApi";
import { useTranslation } from "react-i18next";
import { Text } from "@mantine/core";

interface Props {
  value?: string[];
  onChange: (curr: unknown) => void;
}

const cols = [
  "",
  "name",
  "title",
  "level",
  "availability",
  "place_of_work",
  "active",
  "",
];

function SelectEmployeeTable({ value = [], onChange }: Props) {
  const url = `res/employee?active=true`;
  const { data: employees } = useGET<Employee[]>(url);
  const { t } = useTranslation();

  if (!employees) {
    <Text>{t("no_employees_found_create_one")}</Text>;
  }
  return (
    <SelectTable<Employee>
      rows={employees}
      cols={cols}
      Row={EmployeeTableRow}
      section="employee"
      selection={_isArray(value) ? value : [value]}
      setSelection={onChange}
      multiValue
    />
  );
}

export default SelectEmployeeTable;

import { Fragment } from "react";
import { Anchor, Table, Text } from "@mantine/core";
import Admin from "src/types/Admin.type";
import Avatar from "src/components/Avatar";
import { TableRow } from "src/types/TableRow.type";
import RoleBadge from "src/components/RoleBadge";

const AdminTableRow = ({ item, onClick }: TableRow<Admin>) => (
  <Fragment key={item.id}>
    <Table.Td onClick={onClick}>
      <Avatar size={26} src={item.profilePic} />
    </Table.Td>
    <Table.Td onClick={onClick}>
      <Text fw={700} tt={"capitalize"}>
        {item.name} {item.surname}
      </Text>
    </Table.Td>
    <Table.Td onClick={onClick}>
      <RoleBadge role={item.role} />
    </Table.Td>
    <Table.Td onClick={onClick}>
      <Anchor href={`mailto:${item.email}`} size="sm">
        {item.email}
      </Anchor>
    </Table.Td>
  </Fragment>
);

export default AdminTableRow;

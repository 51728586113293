import Actions from "src/components/Actions";
import SearchEmployees from "src/components/filters/SearchEmployees";

export default function SearchEmployee() {
  return (
    <>
      <Actions title={"find_employees"} />
      <SearchEmployees />
    </>
  );
}

import { ActionIcon, Flex } from "@mantine/core";
import {
  IconBrandLinkedin,
  IconBrandTwitter,
  IconBrandWhatsapp,
  IconLink,
  IconMail,
  IconPhone,
} from "@tabler/icons-react";

interface Props {
  twitter?: string;
  linkedIn?: string;
  mail?: string;
  phone?: string;
  whatsapp?: string;
  website?: string;
  inColumn?: boolean;
}
const CompanySocials = ({
  twitter,
  linkedIn,
  mail,
  phone,
  whatsapp,
  website,
  inColumn = false,
}: Props) => {
  return (
    <Flex justify="end" direction={inColumn ? "column" : "row"} gap="sm">
      {mail && (
        <ActionIcon
          p="md"
          color="red"
          variant="filled"
          component="a"
          href={`mailto:${mail}`}
          target="_blank"
        >
          <IconMail />
        </ActionIcon>
      )}
      {website && (
        <ActionIcon
          p="md"
          color="orange"
          variant="filled"
          component="a"
          href={`https://${website}`}
          target="_blank"
        >
          <IconLink />
        </ActionIcon>
      )}
      {phone && (
        <ActionIcon
          p="md"
          color="dark"
          variant="filled"
          component="a"
          href={`tel:${phone}`}
          target="_blank"
        >
          <IconPhone />
        </ActionIcon>
      )}
      {whatsapp && (
        <ActionIcon
          p="md"
          color="green"
          variant="filled"
          component="a"
          href={`https://wa.me/+${whatsapp}`}
          target="_blank"
        >
          <IconBrandWhatsapp />
        </ActionIcon>
      )}
      {twitter && (
        <ActionIcon
          p="md"
          color="blue"
          variant="filled"
          component="a"
          href={`https://www.twitter.com/${twitter}`}
          target="_blank"
        >
          <IconBrandTwitter />
        </ActionIcon>
      )}
      {linkedIn && (
        <ActionIcon
          p="md"
          color="darkblue"
          variant="filled"
          component="a"
          href={`https://www.linkedin.com/${linkedIn}`}
          target="_blank"
        >
          <IconBrandLinkedin />
        </ActionIcon>
      )}
    </Flex>
  );
};

export default CompanySocials;

import { Badge } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { ApplicationStatus } from "src/types/ApplicationStatus.type";

interface Props {
  status: ApplicationStatus;
}

const ApplicationStatusPill = (props: Props) => {
  const { status } = props;
  const { t } = useTranslation();

  switch (status) {
    case ApplicationStatus.cancelled: {
      return <Badge color="yellow">{t("canceled")}</Badge>;
    }
    case ApplicationStatus.denied: {
      return <Badge color="orange">{t("denied")}</Badge>;
    }
    case ApplicationStatus.open: {
      return <Badge color="blue">{t("open")}</Badge>;
    }
    case ApplicationStatus.accepted: {
      return <Badge color="green">{t("accepted")}</Badge>;
    }
    case ApplicationStatus.draft: {
      return <Badge color="grey">{t("draft")}</Badge>;
    }
    default: {
      return <>{t("draft")}</>;
    }
  }
};

export default ApplicationStatusPill;

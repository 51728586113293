import Company from "src/types/Company.type";

// returns true if the item type is a company
// should include all the required fields for a valid company
// havent found a better way to type check
export default function isCompany(item: object) {
  return (
    (item as Company)?.id !== undefined &&
    (item as Company)?.vat !== undefined &&
    (item as Company)?.slug !== undefined &&
    (item as Company)?.name !== undefined
  );
}

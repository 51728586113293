import { useTranslation } from "react-i18next";
import Error from "src/pages/Error";

interface Props {
  label: string;
}

const NotFound = (props: Props) => {
  const { label } = props;
  const { t } = useTranslation();

  return (
    <Error
      title={`${label} ${t("not_found")}`}
      desc={t("not_found_desc")}
      showBackToHome
    />
  );
};

export default NotFound;

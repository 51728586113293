import { Box } from "@mantine/core";
import MDEditor from "@uiw/react-md-editor";

interface Props {
  text?: string;
  w?: number;
  clamp?: number;
}

const Description = ({ text, w }: Props) => {
  if (!text) return null;
  return (
    <Box w={w}>
      <MDEditor.Markdown
        style={{
          background: "transparent",
        }}
        source={text}
      />
    </Box>
  );
};

export default Description;

import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const LANGUAGES = {
  en: "en",
  it: "it",
};
const NAMESPACES = {
  common: "common",
};

export const initLanguage = () => {
  return i18n
    .use(HttpBackend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init(
      {
        supportedLngs: Object.keys(LANGUAGES),
        detection: {
          convertDetectedLanguage: (lng) => lng.substring(0, 2),
        },
        defaultNS: NAMESPACES.common,
        ns: [NAMESPACES.common],
        fallbackNS: [NAMESPACES.common],
        fallbackLng: LANGUAGES.en,
        interpolation: {
          escapeValue: false,
        },
        backend: {
          loadPath: `/locales/{{lng}}/{{ns}}.json`,
        },
        load: "currentOnly",
        parseMissingKeyHandler: (k) => k?.replace(/\//g, " ") ?? k,
        // debug: import.meta.env.DEV,
      },
      (err) => {
        err && console.log(err);
      }
    )
    .then(() => i18n.loadNamespaces(["common"]));
};

export function translate(key: string) {
  return i18n.t(key);
}

export default i18n;

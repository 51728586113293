import _isDate from "lodash/isDate";
import { formatDistance } from "date-fns";

export const formatDate = (
  date: number | string | Date | undefined | null,
  withTime?: boolean
): string => {
  if (!date) {
    return "";
  }
  const d = _isDate(date) ? date : new Date(date);

  return withTime ? d.toLocaleString() : d.toLocaleDateString();
};

interface Props2 {
  from?: string;
  to?: string | null;
}

export const getDistanceFromDate = ({ from, to = null }: Props2) => {
  if (!from) {
    return "";
  }
  const fromDate = new Date(from);
  const toDate = to ? new Date(to) : new Date();

  return formatDistance(fromDate, toDate, { addSuffix: true });
};

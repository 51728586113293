import _get from "lodash/get";
import { notifications } from "@mantine/notifications";
import { ChangeEvent } from "react";
import { translate } from "src/helpers/initLanguage";

const handleFileUpload =
  (setFile: (f: File) => void) => (e: ChangeEvent<HTMLInputElement>) => {
    const MAX_ALLOWED_UPLOAD_FILE_SIZE = 3 * 1024 * 1024;
    const file = _get(e, ["target", "files", 0], {});

    if (!file || !file?.size) {
      return;
    }

    if (file?.size > MAX_ALLOWED_UPLOAD_FILE_SIZE) {
      notifications.show({
        title: translate("file_upload_canceled"),
        message: translate("file_size_too_large"),
        withCloseButton: false,
      });
      return;
    }

    setFile(file);
  };

export default handleFileUpload;

import Loader from "../Loader";
import { Group, Space, Text, Title } from "@mantine/core";
import _map from "lodash/map";
import { useGET } from "src/hooks/useApi";
import Review from "src/types/Review.type";
import ReviewCard from "../cards/Review.Card";
import { useTranslation } from "react-i18next";

interface Response {
  Reviews_done: Review[];
  Reviews_received: Review[];
}

const SearchReviews = () => {
  const url = `account/company/reviews`;
  const { data, isLoading, error } = useGET<Response>(url);
  const { t } = useTranslation();

  return (
    <>
      <Title>{t("reviews_title")}</Title>
      <Text size="sm">{t("reviews_sub")}</Text>
      <Loader isLoading={isLoading} error={error} hasData={!!data}>
        <Group align="top">
          {_map(data?.Reviews_received, (r: Review) => (
            <ReviewCard key={r.id} review={r} />
          ))}
        </Group>
      </Loader>
      <Space p="md" />
      <Title>{t("revies_done")}</Title>
      <Text size="sm">{t("reviews_made")}</Text>
      <Loader isLoading={isLoading} error={error} hasData={!!data}>
        <Group align="top">
          {_map(data?.Reviews_done, (r: Review) => (
            <ReviewCard key={r.id} review={r} />
          ))}
        </Group>
      </Loader>
    </>
  );
};

export default SearchReviews;

import { Flex, Paper } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import useIsMobile from "src/hooks/useIsMobile";

interface Props {
  to?: string;
  children?: React.ReactNode;
}

const Card = ({ children, to }: Props) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const w = isMobile ? "100%" : 430;
  const mx = isMobile ? 0 : "xs";
  const onClick = () => {
    if (to) {
      navigate(`/app/view/${to}`);
    }
  };
  return (
    <Paper
      shadow="md"
      p="md"
      mx={mx}
      my="xs"
      radius="md"
      withBorder
      maw={w}
      miw={w}
      onClick={onClick}
    >
      <Flex direction="column" gap="sm">
        {children}
      </Flex>
    </Paper>
  );
};

export default Card;

import _omit from "lodash/omit.js";
import { Box, Button, Select, Stack, TextInput, Title } from "@mantine/core";
import FormSection from "src/components/forms/FormSection";
import Company from "src/types/Company.type";
import Actions from "../Actions";
import { useForm } from "@mantine/form";
import { CompanyValidate } from "src/pages/data/validates/company.validate";
import { useState } from "react";
import ImagePreview from "../ImagePreview";
import CustomSelect from "../CustomSelect";
import useFormActions from "src/hooks/useFormActions";
import handleFileUpload from "src/utils/handleFileUpload";
import COMPANY_PROTECTED_FIELDS from "src/constants/companyProtectedFields";
import useUnloadAlert from "src/hooks/useUnloadAlert";
import { useTranslation } from "react-i18next";
import getSelectValues from "src/utils/getSelectValues";
import InputUnique from "../InputUnique";
import DescriptionInput from "src/components/DescriptionInput";
import InputFile from "../InputFile";

interface Props {
  title?: string;
  initialValues?: Partial<Company>;
  onSubmit?: (values: Partial<Company>, file?: File) => void;
}

export default function CompanyForm({
  title,
  initialValues = {},
  onSubmit = () => {},
}: Props) {
  const [file, setFile] = useState<File>();
  const form = useForm<Partial<Company>>({
    validateInputOnChange: true,
    initialValues,
    validate: CompanyValidate,
    transformValues: (values) => _omit(values, COMPANY_PROTECTED_FIELDS),
  });
  useUnloadAlert();
  const formActions = useFormActions(form, onSubmit, file);
  const handleFileChange = handleFileUpload(setFile);
  const { t } = useTranslation();

  return (
    <Stack maw={1000} m="auto">
      {title && <Title>{t(title)}</Title>}

      <FormSection title="company_logo">
        <Box m="auto">
          <ImagePreview value={file} defaultValue={form.values.profilePic} />
          <InputFile onChange={handleFileChange} />
        </Box>
      </FormSection>
      <FormSection title="public_profile">
        <TextInput
          my="md"
          required
          label={t("company_name")}
          {...form.getInputProps("name")}
        />

        <TextInput
          my="md"
          label={t("email")}
          {...form.getInputProps("contact_mail")}
        />

        <TextInput
          my="md"
          type="string"
          label={t("phone_number")}
          placeholder="+39 123 1237 123 32"
          {...form.getInputProps("contact_phone")}
        />

        <TextInput
          my="md"
          type="string"
          label={t("whatsapp")}
          placeholder="+39 123 1237 123 32"
          {...form.getInputProps("contact_whatsapp")}
        />

        <TextInput
          my="md"
          label={t("website")}
          placeholder="https://example.com"
          {...form.getInputProps("contact_website")}
        />

        <InputUnique
          fieldName="slug"
          value={form.values.slug ?? ""}
          setValue={(value: string) => {
            form.setFieldValue("slug", value);
          }}
          leftSectionWidth={145}
          leftSection={
            <Button size="xs" variant="default" tt="lowercase" disabled>
              autom8deal.com/c/
            </Button>
          }
        />

        <DescriptionInput
          required
          label={t("description")}
          {...form.getInputProps("desc")}
        />
      </FormSection>
      <FormSection title={t("company_details")}>
        <Select
          my="md"
          required
          label={t("company_type")}
          placeholder=""
          data={getSelectValues(
            ["NonProfit", "ForProfit", "Cooperative", "PublicSector", "Other"],
            t
          )}
          {...form.getInputProps("type")}
        />

        <InputUnique
          fieldName="vat"
          value={form.values.vat ?? ""}
          setValue={(value: string) => {
            form.setFieldValue("vat", value);
          }}
        />

        <Select
          my="md"
          label={t("company_size")}
          placeholder=""
          data={[
            {
              value: "individual",
              label: t("individual_comp"),
            },
            {
              value: "small",
              label: t("small_comp"),
            },
            {
              value: "medium",
              label: t("medium_comp"),
            },
            {
              value: "large",
              label: t("large_comp"),
            },
          ]}
          {...form.getInputProps("size")}
        />

        <Select
          label={t("turnover")}
          placeholder={t("turnover_ph")}
          data={getSelectValues(
            ["0M-1M", "2M-5M", "5M-10M", "10M-100M", "100M+"],
            t
          )}
          {...form.getInputProps("turnover")}
        />
      </FormSection>
      <FormSection title={t("company_location")}>
        <TextInput
          mt="md"
          label={t("address")}
          placeholder={t("address_ph")}
          {...form.getInputProps("loc_address")}
        />
        <CustomSelect
          label={t("nation")}
          source="countries"
          single
          inputProps={form.getInputProps("loc_nation")}
        />
        <TextInput
          mt="md"
          label={t("city")}
          placeholder=""
          {...form.getInputProps("loc_city")}
        />
        <TextInput
          mt="md"
          label={t("house_nb")}
          placeholder=""
          {...form.getInputProps("loc_num")}
        />
        <TextInput
          mt="md"
          maxLength={2}
          label={t("province")}
          placeholder=""
          {...form.getInputProps("loc_prov")}
        />
        <TextInput
          mt="md"
          label={t("zip_code")}
          placeholder=""
          {...form.getInputProps("loc_zip")}
        />
      </FormSection>
      <FormSection title={t("social_profiles")}>
        <TextInput
          mt="md"
          label={t("linkedin")}
          placeholder={t("company_name_ph")}
          leftSectionWidth={120}
          leftSection={
            <Button size="xs" variant="default" disabled tt="lowercase">
              linkedin.com/
            </Button>
          }
          {...form.getInputProps("contact_linkedin")}
        />
        <TextInput
          mt="md"
          label={t("twitter")}
          placeholder={t("company_name_ph")}
          leftSectionWidth={110}
          leftSection={
            <Button size="xs" variant="default" disabled tt="lowercase">
              twitter.com/
            </Button>
          }
          {...form.getInputProps("contact_twitter")}
        />
      </FormSection>
      <Actions actions={formActions} isReady={form.isValid()} />
    </Stack>
  );
}

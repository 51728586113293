import _get from "lodash/get";
import _pick from "lodash/pick";
import _includes from "lodash/includes";
import useApi from "./useApi";
import useUser from "./useUser";

const SETTING_KEYS = [
  "settings_newsletter",
  "settings_language",
  "settings_theme",
  "settings_show_matches",
  "settings_show_applications",
  "settings_show_proposals",
  "settings_show_marketing",
  "settings_2fa_sms",
  "settings_2fa_app",
];

function useSettings() {
  const API = useApi();
  const { user, isLoading, mutate } = useUser();

  const saveSetting = (id: string, value: boolean | string | number) => {
    API.PUT(`res/user/${user.id}`, { [id]: value });
    mutate({ ...user, [id]: value });
  };

  const loadSetting = (id: string) => {
    if (!_includes(SETTING_KEYS, id)) {
      return null;
    }
    return _get(user, id, null);
  };

  return {
    all: _pick(user, SETTING_KEYS),
    load: loadSetting,
    save: saveSetting,
    isLoading,
  };
}

export default useSettings;

import { Flex } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { DateTag } from "src/components/badges/DateTag";

interface Props {
  from?: string;
  to?: string;
  naked?: boolean;
}

const DateRange = ({ from, to, naked }: Props) => {
  if (!to) {
    return <DateTag date={from} naked={naked} />;
  }

  return (
    <Flex align="center">
      <DateTag date={from} naked={naked} />
      <IconChevronRight size={15} />
      <DateTag date={to} naked={naked} />
    </Flex>
  );
};

export default DateRange;

import _isEmpty from "lodash/isEmpty";
import { Box, Select, Stack, TextInput, Title } from "@mantine/core";
import FormSection from "src/components/forms/FormSection";
import Admin from "src/types/Admin.type";
import Actions from "../Actions";
import { useForm } from "@mantine/form";
import { AdminValidate } from "src/pages/data/validates/admin.validate";
import ImagePreview from "../ImagePreview";
import { useState } from "react";
import useFormActions from "../../hooks/useFormActions";
import handleFileUpload from "src/utils/handleFileUpload";
import useUnloadAlert from "src/hooks/useUnloadAlert";
import { useTranslation } from "react-i18next";
import getSelectValues from "src/utils/getSelectValues";
import useUser from "src/hooks/useUser";
import InputFile from "../InputFile";

interface Props {
  title: string;
  initialValues?: Partial<Admin>;
  onSubmit: (values: Partial<Admin>, file?: File) => void;
  hideRole?: boolean;
}

export default function AdminForm({
  title,
  initialValues = {},
  onSubmit = () => {},
  hideRole = false,
}: Props) {
  const [file, setFile] = useState<File>();
  const { user } = useUser();
  const { t } = useTranslation();

  const form = useForm<Partial<Admin>>({
    validateInputOnChange: true,
    initialValues,
    validate: AdminValidate,
  });
  useUnloadAlert();
  const formActions = useFormActions(form, onSubmit, file);

  const handleFileChange = handleFileUpload(setFile);

  return (
    <Stack maw={1000} m="auto">
      <Title>{t(title)}</Title>
      <FormSection title={t("pro_pic")}>
        <Box m="auto">
          <ImagePreview value={file} defaultValue={form.values.profilePic} />
          <InputFile onChange={handleFileChange} />
        </Box>
      </FormSection>
      <FormSection title={t("public_profile")}>
        <TextInput
          required
          label={t("name")}
          placeholder={t("name")}
          {...form.getInputProps("name")}
        />

        <TextInput
          required
          label={t("surname")}
          placeholder={t("surname")}
          {...form.getInputProps("surname")}
        />

        <TextInput
          required
          readOnly={!_isEmpty(initialValues?.email)}
          label="email"
          placeholder={t("email")}
          {...form.getInputProps("email")}
        />

        <TextInput
          required
          readOnly={!!initialValues?.phone}
          label="phone"
          placeholder="your phone number"
          {...form.getInputProps("phone")}
        />

        {!hideRole && (
          <Select
            readOnly={user.role !== "Owner"}
            label={t("role")}
            placeholder={t("role")}
            disabled={false}
            data={getSelectValues(["Admin", "Owner", "ReadOnly"], t)}
            {...form.getInputProps("role")}
          />
        )}
      </FormSection>

      <Actions actions={formActions} isReady={form.isValid()} />
    </Stack>
  );
}

import { ReactNode, useEffect, useState } from "react";
import AuthContext from "./auth.context";
import getToken from "src/utils/getToken";
import useCognitoUser from "src/hooks/useCognitoUser";

interface Props {
  children: ReactNode;
}

function AuthProvider({ children }: Props) {
  const { cognitoUser, isLoading } = useCognitoUser();
  const [loading, setLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [origin, setOrigin] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [token, setToken] = useState<string>("");

  useEffect(() => {
    if (!isLoading) {
      setEmail(cognitoUser?.email ?? "");
      setName(cognitoUser?.name ?? "");
      setSurname(cognitoUser?.surname ?? "");
      setPhone(cognitoUser?.phone ?? "");
      setOrigin(cognitoUser?.origin ?? "");
    }
  }, [isLoading, cognitoUser]);

  useEffect(() => {
    const func = async () => {
      // saves token
      const t = await getToken();
      if (t) {
        setToken(t);
        setLoading(false);
      }
    };
    if (email) {
      func();
    }
  }, [email]);

  const value = {
    isLoading: loading || isLoading,
    email,
    name,
    surname,
    phone,
    origin,
    token,
    setEmail,
    setToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;

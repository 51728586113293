import _max from "lodash/max";
import _min from "lodash/min";
import _map from "lodash/map";
import Employee from "src/types/Employee.type";
import Availability from "./Availability";

interface Props {
  employees: Employee[];
}

function GroupAvailability({ employees }: Props) {
  const datesFrom = _map(employees, "availableFrom");
  const datesTo = _map(employees, "availableTo");

  const dateFrom = _max(datesFrom);
  const dateTo = _min(datesTo);

  return <Availability from={dateFrom} to={dateTo} />;
}

export default GroupAvailability;

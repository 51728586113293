import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// scroll to to top on page change
const useScrollTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
};


export default useScrollTop;
import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import JobOfferView from "src/components/view/Job.view";
import { useGET } from "src/hooks/useApi";
import JobOffer from "src/types/JobOffer.type";

export default function ViewJobPage() {
  const params = useParams();

  const url = `res/job/${params.id}`;
  const { data, isLoading, error } = useGET<JobOffer>(url);

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <JobOfferView jobOffer={data} />
    </Loader>
  );
}

import { AdminTable } from "src/components/tables/AdminTable";
import Loader from "src/components/Loader";
import { UserRole } from "src/types/UserRole";
import Actions from "src/components/Actions";
import { useGET } from "src/hooks/useApi";
import Admin from "src/types/Admin.type";
import useUser from "src/hooks/useUser";

const ListAdmin = () => {
  const { user } = useUser();
  const { data, isLoading, error } = useGET<Admin[]>("account/company/admins");

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <Actions
        title="admins"
        actions={[
          {
            label: "invite_new",
            to: "/app/add/admin",
          },
        ]}
      />
      <AdminTable data={data} readOnly={user?.role !== UserRole.Owner} />
    </Loader>
  );
};

export default ListAdmin;

import _noop from "lodash/noop";
import _map from "lodash/map";
import _groupBy from "lodash/groupBy";
import { useGET } from "src/hooks/useApi";
import MultiInputMatrix from "./MultiInputMatrix";
import { InputMatrixOption } from "src/types/InputMatrixOption";
import { MultiSelect, Stack, Text, Title } from "@mantine/core";
import { Fragment, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  values: string[];
  setValues?: (a: string[]) => void;
  errors?: ReactNode | null;
  asSelect?: boolean;
}

interface Skill {
  type: string;
  title: string;
  availableValues: InputMatrixOption[];
}

const SkillSelect = (props: Props) => {
  const { setValues, values, errors, asSelect = false } = props;
  const { data } = useGET<Skill[]>(`static/skills`);
  const { t } = useTranslation();

  const groups = useMemo(() => _groupBy(data, "type"), [data]);

  if (asSelect) {
    const skills = _map(data, (row) => ({
      group: row.title,
      items: row.availableValues,
    }));

    const onChange = (v: string[]) => {
      setValues && setValues(v);
    };

    return (
      <MultiSelect
        searchable
        limit={10}
        label="skills"
        value={values}
        onChange={onChange}
        data={skills}
        error={errors}
      />
    );
  }

  return (
    <>
      {errors && (
        <Text size="xs" c="red">
          {errors}
        </Text>
      )}
      <Stack style={{ border: errors ? "1px solid red" : "none" }}>
        {_map(groups, (g, id) => (
          <Fragment key={id}>
            <Title order={4}>{t(id)}</Title>
            <MultiInputMatrix
              values={values}
              readonly={!setValues}
              setValues={setValues ?? _noop}
              availableValueGroups={g}
            />
          </Fragment>
        ))}
      </Stack>
    </>
  );
};

export default SkillSelect;

import { Anchor, Divider, Group, Stack, Text, Title } from "@mantine/core";
import { Row } from "src/components/Row";
import NotFound from "../NotFound";
import Avatar from "../Avatar";
import Actions from "../Actions";
import { UserRole } from "src/types/UserRole";
import { useTranslation } from "react-i18next";
import { InviteRequest } from "src/types/InviteRequest";
import useUser from "src/hooks/useUser";
import { IconCheck } from "@tabler/icons-react";
import useApi from "src/hooks/useApi";
import { useNavigate } from "react-router-dom";

interface Props {
  invite: InviteRequest;
}

export default function InviteRequestView({ invite }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const API = useApi();
  const { user } = useUser();
  if (!invite) {
    return <NotFound label={t("invite")} />;
  }

  const decline = async () => {
    await API.PUT(`account/invite`, { id: invite.id, accept: false });
    navigate("/app/settings");
  };

  const accept = async (role: UserRole) => {
    await API.PUT(`account/invite`, {
      id: invite.id,
      accept: true,
      role,
    });
    navigate("/app/settings");
  };

  return (
    <>
      <Title m="md">
        {invite?.User?.name} {invite?.User?.surname}
      </Title>
      <Divider my="md" />
      <Row title={<Avatar src={invite?.User?.profilePic} alt="profile pic" />}>
        <Stack>
          <Group my="md">
            <Title order={5}>{t("info_name")}</Title>
            <Text>
              {invite?.User?.name} {invite?.User?.surname}
            </Text>
          </Group>

          <Group my="md">
            <Title order={5}>{t("info_email_address")}</Title>
            <Anchor href={`mailto:${invite?.User?.email}`} size="sm">
              {invite?.User?.email}
            </Anchor>
            {invite.User.account_verified_mail && <IconCheck color="green" />}
          </Group>

          <Group my="md">
            <Title order={5}>{t("phone")}</Title>
            <Anchor href={`tel:${invite?.User?.phone}`} size="sm">
              {invite?.User?.phone}
            </Anchor>
            {invite.User.account_verified_phone && <IconCheck color="green" />}
          </Group>

          <Group my="md">
            <Title order={5}>{t("desc")}</Title>
            <Text>{invite?.desc}</Text>
          </Group>
        </Stack>
      </Row>

      <Actions
        actions={[
          {
            label: t("back"),
            to: "/app/settings",
            variant: "default",
          },
          {
            label: t("deny"),
            onClick: decline,
            disabled: user?.role === UserRole.ReadOnly,
          },
          {
            label: t("accept_as_readonly"),
            onClick: () => accept(UserRole.ReadOnly),
            disabled: user?.role === UserRole.ReadOnly,
          },
          {
            label: t("accept_as_admin"),
            onClick: () => accept(UserRole.Admin),
            disabled: user?.role === UserRole.ReadOnly,
          },
        ]}
      />
    </>
  );
}

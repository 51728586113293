import Card from "src/components/cards/Card";
import { Divider } from "@mantine/core";
import CompanySection from "src/components/Sections/Company.Section";
import EmployeeSection from "src/components/Sections/Employee.Section";
import EmployeeInfoSection from "src/components/Sections/EmployeeInfo.Section";
import Employee from "src/types/Employee.type";
import Company from "src/types/Company.type";

interface Props {
  employee?: Employee;
  company?: Partial<Company>;
}

const EmployeeCard = ({ company, employee }: Props) => {
  if (!employee) {
    return null;
  }

  return (
    <Card to={`employee/${employee.id}`}>
      <EmployeeSection employee={employee} />
      <EmployeeInfoSection employee={employee} />
      {company && (
        <>
          <Divider my="md" />
          <CompanySection company={company} />
        </>
      )}
    </Card>
  );
};

export default EmployeeCard;

import { Stack } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import Actions from "src/components/Actions";
import ReviewCard from "src/components/cards/Review.Card";
import Loader from "src/components/Loader";
import useApi, { useGET } from "src/hooks/useApi";
import Review from "src/types/Review.type";

export default function RemoveReview() {
  const { id } = useParams();
  const api = useApi();

  const url = `res/review/${id}`;
  const { data, isLoading, error } = useGET<Review>(url);

  const navigate = useNavigate();
  const handleConfirm = () => {
    api.DELETE(`res/review/${id}`);
    navigate("/app");
  };
  const handleCancel = () => navigate(-1);

  if (error || (!isLoading && !data)) {
    navigate("/404");
  }
  return (
    <Stack align="center">
      Are you sure to remove this review ?
      <Loader isLoading={isLoading} error={error} hasData={!!data}>
        <ReviewCard review={data} />
      </Loader>
      <Actions
        actions={[
          {
            label: "cancel",
            onClick: handleCancel,
            variant: "default",
          },
          {
            label: "confirm",
            onClick: handleConfirm,
          },
        ]}
      />
    </Stack>
  );
}

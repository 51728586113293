import { InviteRequest } from "src/types/InviteRequest";
import SimpleTable from "./SimpleTable";
import InviteRequestTableRow from "./rows/InviteRequestTable.Row";

interface Props {
  data: InviteRequest[];
  readOnly?: boolean;
}

const cols = ["", "name", "email", "phone", "desc"];

export function InviteRequestTable({ data, readOnly }: Props) {
  return (
    <SimpleTable
      cols={cols}
      rows={data}
      section="inviteRequest"
      Row={InviteRequestTableRow}
      readOnly={readOnly}
    />
  );
}

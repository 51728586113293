import { Flex, Paper, Stack, Text, Title } from "@mantine/core";
import Application from "src/types/Application.type";
import Actions from "../Actions";
import HorizontalScroll from "../HorizontalScroll";
import { Row } from "../Row";
import JobOfferCard from "../cards/Job.Card";
import EmployeeCard from "../cards/Employee.Card";
import GroupAvailability from "../GroupAvailability";
import _map from "lodash/map";
import useApi from "src/hooks/useApi";
import { ApplicationStatus } from "src/types/ApplicationStatus.type";
import { useNavigate } from "react-router-dom";
import ApplicationStatusPill from "../ApplicationStatusPill";
import CompanyFromTo from "../CompanyFromTo";
import SeeMore from "../SeeMore";
import { useTranslation } from "react-i18next";
import ExpiryStatusPill from "../ExpiryStatusPill";
import Description from "../Description";
import useIsOwn from "src/hooks/useIsOwn";

interface Props {
  application: Application;
}

const ApplicationView = ({ application }: Props) => {
  const {
    id,
    desc,
    durationMin,
    durationMax,
    price,
    Employees,
    JobOffer,
    status,
  } = application;

  const API = useApi();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const editApplication = (newStatus: ApplicationStatus) => {
    API.PUT(`res/application/${id}`, { status: newStatus });
    navigate(`/app/view/application/${id}`);
  };

  const isOwn = useIsOwn(application);
  const actions = isOwn
    ? [
        {
          label: "send_application",
          onClick: () => editApplication(ApplicationStatus.open),
          disabled: status !== ApplicationStatus.draft,
        },
        {
          label: "cancel_application",
          onClick: () => editApplication(ApplicationStatus.cancelled),
          disabled: status !== ApplicationStatus.open,
        },
        // {
        //   label: "add_review",
        //   to: `/app/add/review/${JobOffer.id}`,
        //   disabled: status !== ApplicationStatus.accepted,
        // },
      ]
    : [
        {
          label: "decline_application",
          onClick: () => editApplication(ApplicationStatus.denied),
          disabled: status !== ApplicationStatus.open,
        },
        {
          label: "accept_application",
          onClick: () => editApplication(ApplicationStatus.accepted),
          disabled: status !== ApplicationStatus.open,
        },
      ];

  return (
    <Stack gap="md">
      <Title>{t("application")}</Title>

      <Row title={t("company")}>
        <Flex direction="column">
          <Paper my="md">
            <CompanyFromTo el={application} />
          </Paper>
          <SeeMore
            isOwn={isOwn}
            type="application"
            isConnected={status === ApplicationStatus.accepted}
          />
        </Flex>
      </Row>

      <Row title={t("job_offer")}>
        <JobOfferCard jobOffer={JobOffer} />
      </Row>

      <Row title={t("status")}>
        <ApplicationStatusPill status={status} />
      </Row>

      {isOwn && (
        <Row title={t("expiry_date_title")}>
          <ExpiryStatusPill item={application} type="application" />
        </Row>
      )}

      <Row title={t("application_description")}>
        <Description text={desc} />
      </Row>

      <Row title={t("price")}>
        {price} {t("euro_hour")}
      </Row>

      <Row title={t("duration_expected")}>
        <Stack>
          <Text>
            {t("minimum") + ": "} {durationMin} {t("days")}
          </Text>
          <Text>
            {t("maximum") + ": "}
            {durationMax} {t("days")}
          </Text>
        </Stack>
      </Row>

      <Row title={t("availability")}>
        <GroupAvailability employees={Employees} />
      </Row>

      <Row title={t("selected_employees")}>
        <HorizontalScroll>
          {_map(Employees, (employee) => (
            <EmployeeCard employee={employee} key={employee.id} />
          ))}
        </HorizontalScroll>
      </Row>

      <Actions actions={actions} />
    </Stack>
  );
};

export default ApplicationView;

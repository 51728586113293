import { Box, Divider, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
}
const FormSection = ({ title, subtitle, children }: Props) => {
  const { t } = useTranslation();
  return (
    <Box mx="xs">
      <Divider />
      <Title order={3} mt={0}>
        {t(title)}
      </Title>
      {subtitle && <Text size="xs">{t(subtitle)}</Text>}
      <Box>{children}</Box>
    </Box>
  );
};

export default FormSection;

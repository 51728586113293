import _map from 'lodash/map';
import _intersection from 'lodash/intersection';
import _size from 'lodash/size';
import { InputMatrixOption } from 'src/types/InputMatrixOption';

function countActiveValues(
  values: string[],
  valueList: InputMatrixOption[]
) {
  const sectionValues = _map(valueList, 'value');
  const intersection = _intersection(values, sectionValues);
  return _size(intersection);
}

export default countActiveValues;
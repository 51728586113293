import { useEffect } from "react";

const useUnloadAlert = () => {
  useEffect(() => {
    const unloadCallback = (event: Event) => {
      event.preventDefault();
      // event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);
};

export default useUnloadAlert;

import _map from "lodash/map";
import Card from "src/components/cards/Card";
import JobSection from "src/components/Sections/Job.Section";
import { EmployeeList } from "../badges/EmployeeList";
import JobOffer from "src/types/JobOffer.type";
import EmptyCard from "./EmptyCard";
import Employee from "src/types/Employee.type";
import { useTranslation } from "react-i18next";

interface Props {
  jobOffer?: Partial<JobOffer>;
}

const JobOfferCard = ({ jobOffer }: Props) => {
  const appliers = _map(jobOffer?.Applications, (p) => ({
    id: p.CompanyFrom?.id ?? "",
    name: p.CompanyFrom?.name ?? "",
    profilePic: p.CompanyFrom?.profilePic ?? "",
  }));
  const { t } = useTranslation();

  if (!jobOffer) {
    return <EmptyCard name={t("job_offer")} />;
  }

  return (
    <Card to={`job/${jobOffer?.id}`}>
      <JobSection jobOffer={jobOffer} />
      <EmployeeList data={appliers as Employee[]} />
    </Card>
  );
};

export default JobOfferCard;

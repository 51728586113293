import { Button, Group, Paper, Stack, Text, Title } from "@mantine/core";
import _map from "lodash/map";
import _groupBy from "lodash/groupBy";
import _sortBy from "lodash/sortBy";
import { useGET } from "src/hooks/useApi";
import { Package } from "src/types/Package.type";
import CreditsCard from "src/components/cards/Credits.Card";
import BalanceCard from "src/components/cards/Balance.Card";
import { useTranslation } from "react-i18next";
import SubscriptionCard from "src/components/cards/Subscription.Card";
import { useState } from "react";
import Prices from "src/components/cards/Prices";
import CreditsBetaCard from "src/components/cards/CreditsBeta.Card";
import useUser from "src/hooks/useUser";
import useIsMobile from "src/hooks/useIsMobile";

const isBetaMode = import.meta.env.VITE_BETA_MODE === "true";

export default function Credits() {
  const isMobile = useIsMobile();
  const url = "static/packages";
  const { hasSubscription } = useUser();
  const { data: packages } = useGET<Package[]>(url);
  const g = _groupBy(packages, "type");
  const { t } = useTranslation();
  const [yearly, setYearly] = useState(false);

  return (
    <Stack mb="xl" pb="xl" gap="xl">
      <Paper>
        <Group justify="space-between" align="top">
          <Stack justify="start" align="start">
            <Title>{t("your_balance")}</Title>
            <Text>{t("manage_your_credits")}</Text>
          </Stack>
          <BalanceCard />
        </Group>
      </Paper>

      {hasSubscription || isBetaMode ? (
        <Paper>
          <Title mb={"md"}>{t("credit_packs")}</Title>
          <Group justify="center">
            {_map(
              _sortBy(g?.single, (p) => Number(p.cost)),
              (p) => (
                <CreditsCard p={p} key={p?.id} />
              )
            )}
            <CreditsBetaCard />
          </Group>
        </Paper>
      ) : (
        <Paper>
          <Group
            justify={isMobile ? "center" : "space-between"}
            align="top"
            mb={"md"}
          >
            <Title>{t("subscription")}</Title>
            <Group>
              <Button.Group>
                <Button
                  variant={yearly ? "default" : "primary"}
                  onClick={() => setYearly(false)}
                >
                  {t("monthly_plan")}
                </Button>
                <Button
                  variant={yearly ? "primary" : "default"}
                  onClick={() => setYearly(true)}
                >
                  {t("yearly_plan")}
                </Button>
              </Button.Group>
            </Group>
          </Group>
          <Group justify="center"></Group>
          <Group justify="center">
            {_map(_sortBy(g?.subscription, "credits"), (p) => (
              <SubscriptionCard p={p} key={p?.id} showYearly={yearly} />
            ))}
          </Group>
        </Paper>
      )}

      <Paper>
        <Title mb={"md"}>{t("prices")}</Title>
        <Prices />
      </Paper>
    </Stack>
  );
}

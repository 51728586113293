import { Checkbox, Flex, Text, UnstyledButton } from "@mantine/core";
import _map from "lodash/map";
import _get from "lodash/get";
import _set from "lodash/set";
import _includes from "lodash/includes";
import _filter from "lodash/filter";
import _each from "lodash/each";
import { useMemo } from "react";
import { InputMatrixOption } from "src/types/InputMatrixOption";
import { IconCircleFilled } from "@tabler/icons-react";

interface Props {
  values: string[];
  setValues: (a: string[]) => void;
  readonly?: boolean;
  availableValues: InputMatrixOption[];
}

const InputMatrix = ({
  values,
  setValues,
  availableValues,
  readonly = false,
}: Props) => {
  const objectValues = useMemo(() => {
    const obj = {};
    _each(availableValues, ({ value: v }) => {
      _set(obj, [v], _includes(values, v));
    });
    return obj;
  }, [values, availableValues]);

  const handleChange = (id: string) => {
    const prev = _get(objectValues, [id], false);
    const res = prev ? _filter(values, (v) => v !== id) : [...values, id];
    setValues(res);
  };

  return (
    <Flex wrap="wrap" maw={1000} my="md">
      {_map(availableValues, ({ label, value }) => {
        const v = _get(objectValues, [value], false);
        if (readonly && !v) {
          return null;
        }
        return (
          <UnstyledButton
            key={value}
            w={300}
            px="xs"
            onClick={() => handleChange(value)}
          >
            <Flex h={50} align="center">
              {readonly ? (
                <Text c="primary" mr="xs">
                  <IconCircleFilled size={15} />
                </Text>
              ) : (
                <Checkbox pr="xs" readOnly checked={v} />
              )}
              {label}
            </Flex>
          </UnstyledButton>
        );
      })}
    </Flex>
  );
};

export default InputMatrix;

import _toLower from "lodash/toLower";

function getSelectValues(list: string[], t: (s: string) => string) {
  return list.map((s) => ({
    value: s,
    label: t(_toLower(s)),
  }));
}

export default getSelectValues;

import { Title } from "@mantine/core";
import Card from "src/components/cards/Card";
import Proposal from "src/types/Proposal.type";
import EmployeeSection from "../Sections/Employee.Section";
import CompanyFromTo from "../CompanyFromTo";
import { useTranslation } from "react-i18next";

interface Props {
  proposal: Proposal;
}

const ProposalCard = ({ proposal }: Props) => {
  const { toEmployee } = proposal;
  const { t } = useTranslation();

  return (
    <Card to={`proposal/${proposal.id}`}>
      <CompanyFromTo el={proposal} />
      <Title order={4}>{t("employee_info")}</Title>
      <EmployeeSection employee={toEmployee} />
    </Card>
  );
};

export default ProposalCard;

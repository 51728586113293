import { UserRole } from "src/types/UserRole";
import { useGET } from "./useApi";
import Admin from "src/types/Admin.type";

const useUser = () => {
  const {
    data: user,
    isLoading,
    error,
    mutate,
  } = useGET<Admin>("account/user");
  const hasSubscription = !!user?.Company?.account_Subscription?.id ?? false;
  const isReadOnlyRole = user?.role === UserRole.ReadOnly;

  return {
    user,
    hasSubscription,
    isReadOnlyRole,
    isLoading,
    error,
    mutate,
  };
};

export default useUser;

import { Box, Flex, Text } from "@mantine/core";
import Card from "src/components/cards/Card";
import Avatar from "../Avatar";
import { InviteRequest } from "src/types/InviteRequest";

interface Props {
  inviteRequest?: InviteRequest;
}

const InviteRequestCard = ({ inviteRequest }: Props) => {
  if (!inviteRequest) {
    return null;
  }
  const { User } = inviteRequest;
  const { name, surname, profilePic } = User;

  return (
    <Card to={`inviteRequest/${inviteRequest.id}`}>
      <Flex wrap="nowrap">
        <Box mr="md" w={50}>
          <Avatar size={48} src={profilePic} alt="profile pic" />
        </Box>
        <Flex direction="column">
          <Text fw={800} size="xl" tt="capitalize">
            {surname} {name}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};

export default InviteRequestCard;

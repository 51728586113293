import { useNavigate, useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import EmployeeForm from "src/components/forms/Employee.form";
import useApi, { useGET } from "src/hooks/useApi";
import Employee from "src/types/Employee.type";

export default function EditEmployee() {
  const navigate = useNavigate();
  const { id } = useParams();
  const api = useApi();

  const url = `res/employee/${id}`;
  const { data, isLoading, error } = useGET<Employee>(url);

  const onSubmit = async (values: Partial<Employee>, file?: File) => {
    if (file) {
      const res = await api.UPLOAD(file);
      values.profilePic = res.name;
    }
    api.PUT(`res/employee/${id}`, values);
    navigate(`/app/view/employee/${id}`);
  };

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <EmployeeForm
        title="update_employee"
        initialValues={data}
        onSubmit={onSubmit}
      />
    </Loader>
  );
}

import { Group } from "@mantine/core";
import Pill from "./Pill";

interface Props {
  zones: string[] | undefined;
  naked?: boolean;
}

const ZoneList = ({ zones = [], naked = false }: Props) => {
  return (
    <Group wrap="wrap" gap="xs">
      {zones.map((c) => (
        <Pill Icon={null} label={c} key={c} naked={naked} />
      ))}
    </Group>
  );
};

export default ZoneList;

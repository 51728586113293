import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import ApplicationView from "src/components/view/Application.view";
import { useGET } from "src/hooks/useApi";
import Application from "src/types/Application.type";

export default function ViewApplicationPage() {
  const params = useParams();
  const url = `res/application/${params?.id}`;
  const { data, isLoading, error } = useGET<Application>(url);

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <ApplicationView application={data} />
    </Loader>
  );
}

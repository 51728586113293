import ApplicationForm from "src/components/forms/Application.form";
import { useNavigate, useParams } from "react-router-dom";
import Application from "src/types/Application.type";
import useApi, { useGET } from "src/hooks/useApi";
import Loader from "src/components/Loader";
import JobOffer from "src/types/JobOffer.type";
import CONFIG_KEYS from "src/constants/config.const";

export default function AddApplication() {
  const navigate = useNavigate();
  const api = useApi();
  const { jobId } = useParams();
  const url = `res/job/${jobId}`;
  const { data, isLoading, error } = useGET<JobOffer>(url);

  const initialValues: Partial<Application> = {
    JobOffer: data,
    jobOfferId: jobId,
  };

  const onSubmit = async (values: Partial<Application>) => {
    const res = await api.POST(`res/application`, values);
    if (res?.success) {
      navigate("/app/section/applications_sent");
    }
  };

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <ApplicationForm
        title="create_application"
        onSubmit={onSubmit}
        initialValues={initialValues}
        costId={CONFIG_KEYS.ADD_APPLICATION_COST}
        expiryId={CONFIG_KEYS.APPLICATION_EXPIRY_PERIOD}
      />
    </Loader>
  );
}

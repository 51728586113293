export const statusColors = {
  draft: "lightgrey",
  elapsed: "red",
  active: "blue",
  open: "teal",
  closed: "orange",
  cancelled: "red",
  accepted: "blue",
  denied: "red",
  archived: "grape",
  warning: "yellow",
  unknown: "default",
};

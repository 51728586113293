import _get from "lodash/get";
import _isNaN from "lodash/isNaN";
import _isDate from "lodash/isDate";
import _isNull from "lodash/isNull";

function getExpiryDate(item: object) {
  const med = _get(item, "meta_expiry_date");
  const medn = _get(item, ["meta", "expiryDate"]);
  const ed = _get(item, "expiryDate");

  const expiry = med ?? medn ?? ed ?? null;

  if (_isNull(expiry)) {
    return null;
  }

  if (_isDate(expiry)) {
    return expiry;
  }

  const expiryDate = new Date(expiry);
  if (_isNaN(expiryDate)) {
    return null;
  }

  return expiryDate;
}

export default getExpiryDate;

import { Text, Button, Flex } from "@mantine/core";
import { Link } from "react-router-dom";
import Avatar from "./Avatar";
import Admin from "src/types/Admin.type";

interface Props {
  user: Admin;
}
function UserButton({ user }: Props) {
  return (
    <Button
      fullWidth
      leftSection={<Avatar src={user?.profilePic ?? ""} size={48} />}
      justify="left"
      my="md"
      ml="-10"
      h={50}
      component={Link}
      to={`/app/view/user/${user?.id}`}
    >
      <Flex direction="column">
        <Text size="sm" fw={500} ta="left">
          {user?.name}
        </Text>

        <Text size="xs" tt={"initial"} c="black" ta="left">
          {user?.email}
        </Text>
      </Flex>
    </Button>
  );
}

export default UserButton;

import _map from "lodash/map";
import _floor from "lodash/floor";
import _isEmpty from "lodash/isEmpty";
import { Button, Card, Flex, Group, List, Text, Title } from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Package } from "src/types/Package.type";
import useUser from "src/hooks/useUser";
import { useNavigate } from "react-router-dom";

export default function SubscriptionCard({
  p,
  showYearly = false,
}: {
  p: Package;
  showYearly: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, hasSubscription, isReadOnlyRole } = useUser();
  const email = user?.Company?.contact_mail;
  const isBetaMode = import.meta.env.VITE_BETA_MODE === "true";

  if (showYearly && p.cost_year == 0) {
    return null;
  }
  if (!showYearly && p.cost_month == 0) {
    return null;
  }

  const proceed = () => {
    const url = `${p.link}?prefilled_email=${email}&client_reference_id=${user?.companyId}`;
    navigate("/app");
    window?.open(url, "_blank")?.focus();
  };

  return (
    <Card
      key={p.id}
      miw={300}
      maw={300}
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      mx={0}
      bg="#FAE1BB"
    >
      <Flex direction="column" mih={380} maw={320} justify="space-evenly">
        <Group justify="space-around" mt="md" mb="xs">
          <Title order={2} fw={900} ta="center">
            {p.title}
          </Title>
        </Group>

        <Group justify="center" mb="md">
          <Title fw={900}>
            {_floor((showYearly ? p.cost_year : p.cost_month) ?? 0, 2)}
          </Title>
          <Text fw={700}>€/{t(showYearly ? "year" : "month")}</Text>
          <Text fw={700}>
            {p.credits} {t("credits_per_month")}
          </Text>
        </Group>
        {p.discount && showYearly && (
          <Group justify="center" mb="xl">
            <Text fw={500}>
              {t("discount")} {p.discount} %
            </Text>
          </Group>
        )}

        {!_isEmpty(p.desc.join()) && (
          <List
            spacing="xs"
            size="sm"
            center
            c="darkred"
            icon={<IconCircleCheck color="orange" />}
          >
            {_map(p.desc, (d) => (
              <List.Item fw={600} key={d}>
                {d}
              </List.Item>
            ))}
          </List>
        )}

        <Button
          fullWidth
          mt="xl"
          onClick={proceed}
          radius="md"
          disabled={isBetaMode || hasSubscription || isReadOnlyRole}
        >
          {t("subscribe_title")}
        </Button>
      </Flex>
    </Card>
  );
}

import { Stack } from "@mantine/core";
import { InfoList } from "src/components/InfoList";
import JobOffer from "src/types/JobOffer.type";
import SkillsList from "src/components/badges/SkillsList";
import FieldList from "../badges/FieldList";
import JobTitle from "../badges/JobTitle";
import Seniority from "../badges/Seniority";
import JobHeader from "../JobHeader";

interface Props {
  jobOffer: Partial<JobOffer>;
}

const JobSection = ({ jobOffer }: Props) => {
  const { level = "N/A", skills = [], fields, jobTitle } = jobOffer;

  return (
    <Stack gap="sm" justify="space-between">
      <JobHeader jobOffer={jobOffer} />
      <Stack>
        <InfoList info="job_title">
          <JobTitle value={jobTitle} />
        </InfoList>
        <InfoList info="level">
          <Seniority level={level} />
        </InfoList>
        <InfoList info="areas">
          <FieldList list={fields} cap />
        </InfoList>
        <InfoList info="knowledge">
          <SkillsList list={skills} cap />
        </InfoList>
        {/* <InfoList info="languages">
          <LanguageList languages={languages} />
        </InfoList> */}
      </Stack>
      {/* <Group mt="md" wrap="nowrap" align="end" justify="end">
        <Group w={50}>
          <Rating rate={Company?.score_total} />
        </Group>
        <Group>
          <ZoneList zones={zones} />
        </Group>
      </Group> */}
    </Stack>
  );
};

export default JobSection;

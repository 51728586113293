import _isEmpty from "lodash/isEmpty";
import { Text } from "@mantine/core";
import { Row } from "src/components/Row";
import Actions from "src/components/Actions";
import Employee from "src/types/Employee.type";
import NotFound from "../NotFound";
import FieldList from "../badges/FieldList";
import SkillSelect from "../SkillSelect";
import VisaList from "../badges/VisaList";
import ZoneList from "../badges/ZoneList";
import LanguageList from "../badges/LanguageList";
import { useTranslation } from "react-i18next";
import Description from "../Description";
import useIsOwn from "src/hooks/useIsOwn";
import CountryList from "../badges/CountryList";
import PostHeader from "../PostHeader";

interface Props {
  employee: Employee;
}

export default function EmployeeView({ employee }: Props) {
  const isOwn = useIsOwn(employee);
  const { t } = useTranslation();

  if (!employee) {
    return <NotFound label={t("employee")} />;
  }

  const {
    id,
    languages,
    fields,
    zones,
    desc,
    visa,
    skills = [],
    certifications,
    training,
    residentialCity = "N/A",
    residentialNation = "N/A",
  } = employee;

  return (
    <>
      <PostHeader post={employee} isOwn={isOwn} />

      <Row title={t("description") + ":"}>
        <Description text={desc} />
      </Row>

      <Row title={t("languages") + ":"}>
        <LanguageList languages={languages} />
      </Row>

      <Row title={t("info_place_of_work") + ":"}>
        <ZoneList zones={zones} naked />
      </Row>

      <Row title={t("residence") + ":"}>
        <Text mr="md">{residentialCity}</Text>
        <CountryList countries={[residentialNation]} big />
      </Row>

      <Row title={t("info_fields") + ":"}>
        <FieldList list={fields} />
      </Row>

      <Row title={t("info_skills") + ":"}>
        <SkillSelect values={skills} />
      </Row>

      <Row title={t("info_visa_access") + ":"}>
        <VisaList list={visa} naked />
      </Row>

      {!_isEmpty(certifications) && (
        <Row title={t("certifications") + ":"}>{certifications.join(", ")}</Row>
      )}

      {!_isEmpty(training) && (
        <Row title={t("training") + ":"}>{training.join(", ")}</Row>
      )}

      <Actions
        actions={[
          {
            label: "back",
            to: "/app/section/employees",
            variant: "default",
          },
          isOwn
            ? {
                label: "edit_employee",
                to: `/app/edit/employee/${id}`,
                variant: "default",
              }
            : {
                label: "submit_job",
                to: `/app/add/proposal/${id}`,
              },
        ]}
      />
    </>
  );
}

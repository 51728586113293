import CONFIG_KEYS from "./config.const";

const pricingRows = [
  {
    id: "create_employee",
    cost: "-",
    duration: "-",
  },
  {
    id: "add_employee",
    cost: CONFIG_KEYS.ADD_EMPLOYEE_COST,
    duration: CONFIG_KEYS.EMPLOYEE_EXPIRY_PERIOD,
  },
  {
    id: "add_jobOffer",
    cost: CONFIG_KEYS.ADD_JOBOFFER_COST,
    duration: CONFIG_KEYS.JOBOFFER_EXPIRY_PERIOD,
  },
  {
    id: "add_application",
    cost: CONFIG_KEYS.ADD_APPLICATION_COST,
    duration: CONFIG_KEYS.APPLICATION_EXPIRY_PERIOD,
  },
  {
    id: "add_proposal",
    cost: CONFIG_KEYS.ADD_PROPOSAL_COST,
    duration: CONFIG_KEYS.PROPOSAL_EXPIRY_PERIOD,
  },
  {
    id: "accept_application",
    cost: CONFIG_KEYS.ACCEPT_APPLICATION_COST,
    duration: "",
  },
];

export default pricingRows;

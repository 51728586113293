import { useTranslation } from "react-i18next";

interface Props {
  rate?: number | string;
  costsIncluded?: boolean;
}

const supportedValues = ["0", "25", "50", "75", "100"];

function Rate({ rate = 0, costsIncluded = false }: Props) {
  const { t } = useTranslation();
  const content = supportedValues.includes(`${rate}`) ? t(`hr-${rate}`) : rate;

  return (
    <>
      {content} {t("euro_hour")} {costsIncluded && `(${t("costs_included")})`}
    </>
  );
}

export default Rate;

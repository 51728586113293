import { useMantineColorScheme, Button, ButtonGroup } from "@mantine/core";
import { IconMoon, IconSun } from "@tabler/icons-react";

function ThemeSwitch() {
  const { toggleColorScheme, colorScheme } = useMantineColorScheme();

  return (
    <ButtonGroup>
      <Button variant="default" onClick={() => toggleColorScheme()}>
        {colorScheme === "light" ? <IconSun /> : <IconMoon />}
      </Button>
    </ButtonGroup>
  );
}
export default ThemeSwitch;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from "react-i18next";
import { notifications } from "@mantine/notifications";
import { useMemo } from "react";
import { FormErrors } from "@mantine/form";
import useConfig from "./useConfig";
import { getTLabel } from "src/utils/getTLabel";

interface IForm {
  values: any;
  validate: () => {
    errors: FormErrors;
    hasErrors: boolean;
  };
  reset: () => void;
  isValid: () => boolean;
  getTransformedValues: () => any;
}

type IOnSubmit = (values: any, file?: any) => void;

function submitHandler(form: IForm, onSubmit: IOnSubmit, file?: any) {
  const report = form.validate();
  if (report.hasErrors) {
    notifications.show({
      title: "Error during submit",
      message: "Please, check all the field values",
      withCloseButton: false,
    });
    console.log("invaild form values", {
      values: form.values,
      transformed: form.getTransformedValues(),
    });
    return;
  }
  const values = form.getTransformedValues();
  onSubmit(values, file);
}

export default function useFormActions(
  form: IForm,
  onSubmit: IOnSubmit,
  file?: File,
  costId?: string,
  expiryId?: string
) {
  // check action cost
  const { t } = useTranslation();
  const config = useConfig();
  const cost = config.get(costId) ?? 0;
  const expiry = config.get(expiryId);
  const tLabel = getTLabel(costId);

  const submitButton = useMemo(
    () =>
      cost && cost < 0
        ? {
            label: t("submit"),
            tooltip: t("action_disabled"),
            disabled: true,
            onClick: () => {},
            cost,
          }
        : {
            label: "submit",
            tooltip: expiry ? `${t(tLabel)} ${expiry} ${t("days")}` : undefined,
            disabled: config.isLoading || config.error,
            onClick: () => submitHandler(form, onSubmit, file),
            cost,
          },
    [
      config.error,
      config.isLoading,
      cost,
      expiry,
      file,
      form,
      onSubmit,
      t,
      tLabel,
    ]
  );

  return useMemo(
    () => [
      {
        label: "reset",
        onClick: () => {
          form.reset();
        },
        variant: "default",
      },
      submitButton,
    ],
    [form, submitButton]
  );
}

import { createBrowserRouter } from "react-router-dom";
import Home from "src/pages/Home";
import Error from "src/pages/Error";
import appRoutes from "./app.routes";
import CompanySlug from "src/pages/CompanySlug";
import Register from "src/components/Register";
import RootLayout from "src/pages/Root.Layout";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <Error showBackToHome />,
    children: [
      // authenticated routes
      // with useRegisterIfNeeded()
      {
        index: true,
        element: <Home />,
      },
      appRoutes,
      // public routes
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "c/:slug",
        element: <CompanySlug />,
      },
    ],
  },
]);

export default routes;

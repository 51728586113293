import _omit from "lodash/omit.js";
import {
  Box,
  InputLabel,
  Stack,
  Switch,
  TextInput,
  Title,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import FormSection from "src/components/forms/FormSection";
import JobOffer from "src/types/JobOffer.type";
import Actions from "../Actions";
import { useForm } from "@mantine/form";
import { JobValidate } from "src/pages/data/validates/job.validate";
import CustomSelect from "../CustomSelect";
import useFormActions from "src/hooks/useFormActions";
import { JobOfferStatus } from "src/types/JobOfferStatus.type";
import useUnloadAlert from "src/hooks/useUnloadAlert";
import { useTranslation } from "react-i18next";
import SkillSelect from "../SkillSelect";
import { META_FIELDS } from "src/constants/metaFields.const";
import DescriptionInput from "src/components/DescriptionInput";

interface Props {
  title: string;
  onSubmit: (values: Partial<JobOffer>) => void;
  initialValues?: Partial<JobOffer>;
  costId?: string;
  expiryId?: string;
}

const stringToDate = (date?: string) => {
  return date ? new Date(date) : new Date();
};

// const dateToString = (date: Date) => {
//   return date.toISOString();
// };

export default function JobForm({
  title,
  initialValues = {},
  onSubmit = () => {},
  costId,
  expiryId,
}: Props) {
  const form = useForm<Partial<JobOffer>>({
    validateInputOnChange: true,
    initialValues,
    validate: JobValidate,
    transformValues: (values) => ({
      ..._omit(values, [
        "Company",
        "companyId",
        "Review",
        "Applications",
        "Proposal",
        ...META_FIELDS,
      ]),
      hourlyRate: Number(values.hourlyRate),
      duration: Number(values.duration),
      status: JobOfferStatus.open,
    }),
  });
  useUnloadAlert();
  const formActions = useFormActions(
    form,
    onSubmit,
    undefined,
    costId,
    expiryId
  );
  const { t } = useTranslation();

  return (
    <Stack maw={1000} m="auto">
      <Title>{t(title)}</Title>

      <FormSection title="job_offer">
        <TextInput
          required
          maxLength={50}
          label={t("title")}
          placeholder={t("title")}
          {...form.getInputProps("title")}
        />

        <DescriptionInput
          required
          label={t("desc_job")}
          {...form.getInputProps("desc")}
        />

        <CustomSelect
          single
          required
          source="hourlyRates"
          label={"hourly_rate_long"}
          inputProps={form.getInputProps("hourlyRate")}
        />
      </FormSection>

      <InputLabel my="md">{t("costs_included")}</InputLabel>
      <Switch
        mb="md"
        checked={!!form.values?.costsIncluded}
        onChange={() =>
          form.setFieldValue("costsIncluded", !form.values?.costsIncluded)
        }
      />

      <FormSection title="job_details">
        <CustomSelect
          single
          required
          label="job_title_required"
          source="roles"
          inputProps={form.getInputProps("jobTitle")}
        />

        <CustomSelect
          single
          required
          source="levels"
          inputProps={form.getInputProps("level")}
        />

        <CustomSelect
          source="fields"
          inputProps={form.getInputProps("fields")}
        />

        <InputLabel mt="xs">{t("skills")} *</InputLabel>
        <Box m="md">
          <SkillSelect
            errors={form.errors?.skills}
            values={form.values.skills ?? []}
            setValues={(v) => {
              form.setFieldValue("skills", v);
            }}
          />
        </Box>

        <CustomSelect
          label="languages_required"
          source="languages"
          inputProps={form.getInputProps("languages")}
        />

        <CustomSelect
          required
          label="location_of_work"
          source="zones"
          inputProps={form.getInputProps("zones")}
        />

        <CustomSelect source="visas" inputProps={form.getInputProps("visa")} />
      </FormSection>
      <FormSection title="dates">
        <DatePickerInput
          my="md"
          label={t("starting_from")}
          placeholder={t("pick_date")}
          {...form.getInputProps("dateFrom")}
          value={stringToDate(form.getInputProps("dateFrom")?.value)}
          onChange={form.getInputProps("dateFrom")?.onChange}
        />
        <TextInput
          my="md"
          required
          label={t("expected_duration_days")}
          placeholder={t("duration_ph")}
          {...form.getInputProps("duration")}
        />
      </FormSection>

      <Actions actions={formActions} isReady={form.isValid()} />
    </Stack>
  );
}

import { RouterProvider } from "react-router-dom";
import routes from "./routes";
import { useContext, useEffect } from "react";
import { Amplify } from "aws-amplify";
import type { WithAuthenticatorProps } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import config from "./amplifyconfiguration.json";
import { NotificationContext } from "./providers/notification.provider";
import useAuth from "./hooks/useAuth";
import Maintenance from "./pages/Maintenance";
import { useGET } from "./hooks/useApi";

Amplify.configure(config);
// test commit
console.log("test commit");

const App = ({ user }: WithAuthenticatorProps) => {
  const { email } = useAuth();
  const notifCtx = useContext(NotificationContext);
  const { data, isLoading, error } = useGET(`health`);

  useEffect(() => {
    email && console.log("logged in as ", email);
  }, [email]);

  useEffect(() => {
    notifCtx?.setReady(!!user);
  }, [user, notifCtx]);

  if (!isLoading && (error || !data)) {
    return <Maintenance />;
  }

  return <RouterProvider router={routes} />;
};

export default App;

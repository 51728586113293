import { Tooltip } from "@mantine/core";
import Avatar from "../Avatar";

interface Props {
  label?: string;
  src?: string | null;
}

export const TooltipAvatar = ({ label, src }: Props) => {
  return (
    <Tooltip label={label} withArrow>
      <Avatar alt="profile pic" src={src} size={36} />
    </Tooltip>
  );
};

import Card from "src/components/cards/Card";
import { Divider } from "@mantine/core";
import CompanySection from "src/components/Sections/Company.Section";
import ReviewSection from "src/components/Sections/Review.Section";
import Review from "src/types/Review.type";
import CompanyFromTo from "../CompanyFromTo";

interface Props {
  review?: Review;
}

const ReviewCard = ({ review }: Props) => {
  if (!review) {
    return null;
  }
  return (
    <Card to={`review/${review.id}`}>
      <CompanyFromTo el={review} />
      <ReviewSection review={review} />
      {review.CompanyTo && (
        <>
          <Divider my="md" />
          <CompanySection company={review.CompanyTo} />{" "}
        </>
      )}
    </Card>
  );
};

export default ReviewCard;

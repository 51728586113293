import { Flex, Group, NavLink } from "@mantine/core";
import {
  IconBarcode,
  IconBrandWhatsapp,
  IconLink,
  IconMail,
  IconMapPin,
  IconPhone,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import Company from "src/types/Company.type";
import CompanySocials from "./cards/CompanySocials";
import { Link } from "react-router-dom";

interface Props {
  company: Company;
  isMatched: boolean;
}

const CompanyContacts = (props: Props) => {
  const { company, isMatched } = props;
  const { t } = useTranslation();

  if (!isMatched) {
    return null;
  }
  const { loc_city, loc_prov, loc_zip, loc_address, loc_num } = company;
  const fullAddr = [loc_city, loc_prov, loc_zip, loc_address, loc_num]
    .filter((e) => e)
    .join();

  return (
    <Flex direction="column">
      <NavLink
        leftSection={<IconBarcode />}
        description={t("vat")}
        label={company.vat}
      />
      {company.contact_website && (
        <NavLink
          component={Link}
          to={company.contact_website}
          leftSection={<IconLink />}
          description={t("website")}
          label={company.contact_website}
        />
      )}
      {fullAddr && (
        <NavLink
          leftSection={<IconMapPin />}
          description={`${loc_city ?? ""}, ${loc_prov ?? ""} ${loc_zip ?? ""}`}
          label={`${loc_address ?? ""} ${loc_num ?? ""}`}
        />
      )}
      {company.contact_phone && (
        <NavLink
          component={Link}
          to={`tel:${company.contact_phone}`}
          leftSection={<IconPhone />}
          description={t("phone")}
          label={company.contact_phone}
        />
      )}
      {company.contact_whatsapp && (
        <NavLink
          component={Link}
          to={`https://wa.me/+${company.contact_whatsapp}`}
          leftSection={<IconBrandWhatsapp />}
          description={t("whatsapp")}
          label={company.contact_whatsapp}
        />
      )}
      {company.contact_mail && (
        <NavLink
          component={Link}
          to={`mailto:${company.contact_mail}`}
          leftSection={<IconMail />}
          description={t("email")}
          label={company.contact_mail}
        />
      )}
      <Group m="md">
        <CompanySocials
          linkedIn={company.contact_linkedin}
          twitter={company.contact_twitter}
          mail={company.contact_mail}
          phone={company.contact_phone}
          whatsapp={company.contact_whatsapp}
          website={company.contact_website}
        />
      </Group>
    </Flex>
  );
};

export default CompanyContacts;

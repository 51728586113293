import { Flex, Indicator, Notification, Text } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import getTypeAttributes from "../helpers/getTypeAttributes";
import NotificationType from "src/types/Notification.type";
// import Description from "./Description";
import { useMemo } from "react";
import _endsWith from "lodash/endsWith";
interface Props {
  notification: NotificationType;
}

const NotificationEvent = ({ notification }: Props) => {
  const navigate = useNavigate();
  const { title, id, type, read, meta_created_at } = notification;
  const { icon, color } = getTypeAttributes({ type });
  const { pathname } = useLocation();
  const isSelected = useMemo(() => _endsWith(pathname, id), [pathname, id]);

  const created = useMemo(
    () => new Date(meta_created_at).toLocaleString(),
    [meta_created_at]
  );

  return (
    <Indicator disabled={read} size={20} withBorder>
      <Notification
        w={333}
        title={title}
        icon={icon}
        color={color}
        bg={isSelected ? "primary" : undefined}
        radius="md"
        withBorder
        withCloseButton={false}
        onClick={() => navigate(`/app/notifications/${id}`)}
        style={{
          cursor: "pointer",
        }}
      >
        {/* <Description text={desc} /> */}
        <Flex justify={"end"} w="100%">
          <Text size="xs" c="grey">
            {created}
          </Text>
        </Flex>
      </Notification>
    </Indicator>
  );
};

export default NotificationEvent;

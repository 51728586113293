import { Center, Stack, Text, Title } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

export default function Maintenance() {
  const { t } = useTranslation();

  return (
    <Center>
      <Stack p="md" mx="auto" my="xl">
        <IconAlertTriangle size={90} style={{ margin: "30px auto" }} />
        <Title pt="md">{t("maintenance")}</Title>
        <Text>{t("maintenance_desc")}</Text>
      </Stack>
    </Center>
  );
}

import {
  ActionIcon,
  Box,
  Flex,
  Group,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import { Outlet, useParams } from "react-router-dom";
import Actions from "src/components/Actions";
import Loader from "src/components/Loader";
import getTypeAttributes from "src/helpers/getTypeAttributes";
import { useContext, useEffect, useMemo } from "react";
import { NotificationContext } from "src/providers/notification.provider";
import { useTranslation } from "react-i18next";

export default function NotificationView() {
  const { notificationId } = useParams();
  const { getById, isLoading, error, markAsRead } =
    useContext(NotificationContext);
  const notification = getById(notificationId);
  const { t } = useTranslation();
  const created = useMemo(
    () =>
      notification?.meta_created_at
        ? new Date(notification?.meta_created_at).toLocaleString()
        : "",
    [notification?.meta_created_at]
  );
  useEffect(() => {
    if (notificationId && !notification?.read) {
      markAsRead(notificationId);
    }
  });

  if (!notificationId || !notification) {
    return (
      <Box miw={300} maw={800} p="md">
        <Title order={3}>{t("nothing_to_see")}</Title>
        <Text>{t("notification_id_not_found")}</Text>
        <Actions
          actions={[
            {
              to: "/app/notifications",
              label: "back",
              variant: "default",
            },
          ]}
        />
      </Box>
    );
  }
  const { title, type, desc, descFull, link } = notification;
  const { icon, color } = getTypeAttributes({ type });
  const viewLink = link
    ? {
        to: link,
        label: "view",
      }
    : null;

  return (
    <Paper maw={800} w="100%" p="md" mx="auto" withBorder shadow="lg">
      <Loader isLoading={isLoading} error={error}>
        <Flex direction="column" m="md">
          <Group wrap="nowrap">
            <ActionIcon radius="xl" color={color}>
              {icon}
            </ActionIcon>
            <Title order={3}>{t(title)}</Title>
          </Group>
          <Flex justify={"end"} w="100%">
            <Text size="xs" c="grey">
              {created}
            </Text>
          </Flex>
          <Flex direction="column" mih={150} mt="xl">
            <Text dangerouslySetInnerHTML={{ __html: descFull || t(desc) }} />
          </Flex>
        </Flex>
      </Loader>
      <Actions
        actions={[
          {
            to: "/app/notifications",
            label: "close",
            variant: "default",
          },
          viewLink,
        ]}
      />
      <Outlet />
    </Paper>
  );
}

const steps = [
  "start",
  "employees",
  "jobs",
  "find_job",
  "find_employee",
  "support",
  "tutorial",
  "tour",
];

function TourSteps(t: (s: string) => string) {
  return steps.map((s) => ({
    selector: `.tour_${s}`,
    content: (
      <>
        <h3>{t(`tour_${s}_title`)}</h3>
        <p>{t(`tour_${s}`)}</p>
      </>
    ),
  }));
}

export default TourSteps;

import _size from "lodash/size";
import { Group, Text } from "@mantine/core";

interface Props {
  cap?: number | null;
  children?: Iterable<React.ReactNode>;
}

export const CroppedList = ({ children = [], cap = null }: Props) => {
  if (cap === null) {
    return children;
  }
  const list = Array.from(children);
  const visibleEls = list.slice(0, cap);
  const hiddenEls = list.slice(cap);

  return (
    <Group gap={0} wrap="wrap">
      {visibleEls}
      {_size(hiddenEls) > 0 && <Text size="xs">+{_size(hiddenEls)}</Text>}
    </Group>
  );
};

import {
  Collapse as MantineCollapse,
  UnstyledButton,
  Flex,
  Paper,
  Box,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { Fragment, ReactNode } from "react";

interface Props {
  title: ReactNode;
  children: ReactNode;
  c?: string;
  p?: string;
  withBg?: boolean;
  isOpen?: boolean;
  spaced?: string;
}

function Collapse({
  title,
  children,
  withBg = false,
  isOpen = false,
  c = "black",
  p = "0",
  spaced = "xl",
}: Props) {
  const [open, { toggle }] = useDisclosure(isOpen);
  const Wrapper = withBg ? Paper : Fragment;
  const toggleHandler = (e: { stopPropagation: () => void }) => {
    e?.stopPropagation();
    toggle();
  };

  return (
    <Wrapper>
      <Flex direction="column">
        <UnstyledButton right="+" onClick={toggleHandler} p={p}>
          <Flex direction="row" justify="space-between" align="center" c={c}>
            <Box>{title}</Box>
            {open ? <IconChevronUp size={20} /> : <IconChevronDown size={20} />}
          </Flex>
        </UnstyledButton>
        <MantineCollapse in={open} mt={spaced}>
          {children}
        </MantineCollapse>
      </Flex>
    </Wrapper>
  );
}

export default Collapse;

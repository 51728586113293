import _includes from "lodash/includes";
import { useMemo } from "react";
import {
  DEFAULT_LOCALE,
  SUPPORTED_LOCALES,
} from "src/constants/supportedLocales.const";

export function useDetectLocale() {
  const locale = useMemo(() => {
    const browser = window?.navigator?.language?.substring(0, 2);
    return _includes(SUPPORTED_LOCALES, browser) ? browser : DEFAULT_LOCALE;
  }, []);

  return locale;
}

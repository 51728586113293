import Loader from "../Loader";
import JobOfferCard from "../cards/Job.Card";
import { Flex, Pagination } from "@mantine/core";
import JobOffer from "src/types/JobOffer.type";
import { useState } from "react";
import { JobFilters } from "src/types/JobFilters.type";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import SearchNoResults from "./SearchNoResults";
import { useGET } from "src/hooks/useApi";
import SearchFilter from "./SearchFilter";
import { PaginationResponse } from "src/types/Pagination.Type";

function encodeJobFilters(filter: JobFilters): string {
  if (_isEmpty(filter)) {
    return "";
  }
  return new URLSearchParams(filter as Record<string, string>).toString();
}

const SearchJobs = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<JobFilters>({});
  const params = encodeJobFilters(filters);
  const url = `search/jobs?page=${page}&${params}`;
  const { data, isLoading, error } = useGET<PaginationResponse<JobOffer>>(url, {
    isPublic: true,
  });

  return (
    <Flex direction="column">
      <SearchFilter setData={setFilters} mode="jobOffer" />
      <Flex direction="column">
        <Loader isLoading={isLoading} error={error} hasData={data?.total > 0}>
          <Flex direction="row" justify="center" align="top" wrap="wrap">
            {_isEmpty(data?.result) && (
              <SearchNoResults resetFilters={() => setFilters({})} />
            )}
            {_map(data?.result, (j: JobOffer) => (
              <JobOfferCard key={j.id} jobOffer={j} />
            ))}
          </Flex>
        </Loader>
        <Pagination value={page} onChange={setPage} total={data?.count} />
      </Flex>
    </Flex>
  );
};

export default SearchJobs;

import { Button, Stack } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LinkProps } from "src/types/LinkProps.type";

const CardButtonLinks = ({ links }: { links: LinkProps[] }) => {
  const { t } = useTranslation();

  return (
    <Stack>
      {links.map(({ title, Icon = Fragment, to, classes }) => (
        <Button
          key={title + to}
          size="lg"
          fullWidth
          to={to}
          className={classes}
          variant="filled"
          component={Link}
          justify="space-between"
          leftSection={<Icon />}
          rightSection={<IconChevronRight size={14} />}
        >
          {t(title)}
        </Button>
      ))}
    </Stack>
  );
};

export default CardButtonLinks;

import { Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import Card from "src/components/cards/Card";

interface Props {
  name?: string;
}

const EmptyCard = ({ name = "element" }: Props) => {
  const { t } = useTranslation();

  return (
    <Card to="/app">
      <Title order={2}>{name} {t('card_not_found')}</Title>
    </Card>
  );
};

export default EmptyCard;

import { Flex } from "@mantine/core";
import LanguageIcon from "./LanguageIcon";

interface Props {
  languages: string[] | undefined;
}

const LanguageList = ({ languages = [] }: Props) => {
  return (
    <Flex mt="xs">
      {languages.map((c) => (
        <LanguageIcon id={c} key={c} />
      ))}
    </Flex>
  );
};

export default LanguageList;

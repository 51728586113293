import _get from "lodash/get";
import _map from "lodash/map";
import { Paper, Table } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useGET } from "src/hooks/useApi";
import pricingRows from "src/constants/pricing.const";

export default function Prices() {
  const { t } = useTranslation();
  const { data } = useGET<object>(`config/all`);

  function getPrice(cost: number) {
    if (cost < 0) {
      return t("disabled");
    }
    return cost;
  }

  return (
    <Paper p={0} radius="lg" mx="auto" maw={900}>
      <Table>
        <Table.Thead c="white" bg="primary" tt="capitalize">
          <Table.Tr>
            <Table.Th w={"60%"}></Table.Th>
            <Table.Th ta="center">{t("pricing_cost")}</Table.Th>
            <Table.Th ta="center">{t("pricing_duration")}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {_map(pricingRows, ({ id, cost, duration }) => (
            <Table.Tr key={id}>
              <Table.Td fw={700}>{t(`pricing_${id}`)}</Table.Td>
              <Table.Td ta="center">{getPrice(_get(data, cost, 0))}</Table.Td>
              <Table.Td ta="center">{_get(data, duration, "-")}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Paper>
  );
}

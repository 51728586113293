import { Badge, Flex } from "@mantine/core";
import { useTranslation } from "react-i18next";
import useExpiryValues from "src/hooks/useExpiryValues";
import getExpiryDate from "src/utils/getExpiryDate";
import SpendCreditsButton from "./SpendCreditsButton";
import { TradeType } from "src/types/TradeType";
import useApi from "src/hooks/useApi";
import Employee from "src/types/Employee.type";
import JobOffer from "src/types/JobOffer.type";
import Application from "src/types/Application.type";
import Proposal from "src/types/Proposal.type";
import { useSWRConfig } from "swr";
import useTradeTypeValues from "src/hooks/useTradeTypeValues";

interface Props {
  item: Partial<Employee | JobOffer | Application | Proposal>;
  type: "employee" | "jobOffer" | "application" | "proposal";
}

const ExpiryStatusPill = ({ item, type }: Props) => {
  const { t } = useTranslation();
  const API = useApi();
  const { mutate } = useSWRConfig();
  const expiryDate = getExpiryDate(item);
  const { status, color, label } = useExpiryValues(expiryDate);
  const tradeType =
    type === "employee" ? TradeType.add_employee : TradeType.add_jobOffer;
  const { cost, label: tradeLabel, desc } = useTradeTypeValues(tradeType);
  const onSuccess = async () => {
    // NOTE api uses "job" instead of jobOffer
    const section = type === "jobOffer" ? "job" : type;
    await API.POST(`res/${section}/activate/${item?.id}`, {});
    mutate(`res/${section}/${item?.id}`);
  };

  const cta =
    status === "active" &&
    (type === "employee" || type === "jobOffer") ? null : (
      <SpendCreditsButton
        cost={cost}
        desc={desc}
        label={tradeLabel}
        tooltip="buy_more_time_desc"
        onSuccess={onSuccess}
      />
    );

  return (
    <Flex align="center" mt="xs">
      <Badge color={color} mr="md">
        {t("status_" + status)} {label}
      </Badge>
      {cta}
    </Flex>
  );
};

export default ExpiryStatusPill;

import { Paper, Title } from "@mantine/core";
import CompanySection from "src/components/Sections/Company.Section";
import Company from "src/types/Company.type";
import EmptyCard from "./EmptyCard";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  company?: Partial<Company>;
  title?: string;
}

const CompanyCard = ({ company, title }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!company) {
    return <EmptyCard name={t("company")} />;
  }

  return (
    <Paper
      onClick={() => navigate(`/app/view/company/${company.id}`)}
      style={{ cursor: "pointer" }}
    >
      {title && <Title order={3}>{title}</Title>}
      <CompanySection company={company} />
    </Paper>
  );
};

export default CompanyCard;

import { useInterval } from "@mantine/hooks";
import { useEffect } from "react";
import getUser from "src/utils/getUser";

// this simple hook keeps the session alive
// even if the page runs in the background
const usePing = () => {
  const interval = useInterval(() => {
    getUser();
  }, 60 * 1000);

  useEffect(() => {
    interval.start();
    return interval.stop;
  });
};

export default usePing;

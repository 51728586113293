import { Badge } from "@mantine/core";
import _get from "lodash/get";
import { UserRole } from "src/types/UserRole";

interface Props {
  role?: UserRole;
}

const BADGE_COLORS = {
  [UserRole.Owner]: "blue",
  [UserRole.Admin]: "green",
  [UserRole.ReadOnly]: "yellow",
};

const RoleBadge = (props: Props) => {
  const { role = "" } = props;
  const bg = _get(BADGE_COLORS, role, "grey");

  return <Badge color={bg}>{role}</Badge>;
};

export default RoleBadge;

import { useNavigate, useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import AdminForm from "src/components/forms/Admin.form";
import Admin from "src/types/Admin.type";
import useApi, { useGET } from "src/hooks/useApi";
import useAuth from "src/hooks/useAuth";

export default function EditAdmin() {
  const { email } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const api = useApi();

  const url = `res/user/${id}`;
  const { data, isLoading, error } = useGET<Admin>(url);

  if (!data.email) {
    data.email = email;
  }

  const onSubmit = async (values: Partial<Admin>, file?: File) => {
    if (file) {
      const res = await api.UPLOAD(file);
      values.profilePic = res.name;
    }
    const res = await api.PUT(`res/user/${id}`, values);
    if (res?.success) {
      navigate(`/app/view/user/${id}`);
    }
  };

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <AdminForm
        title="update_admin"
        initialValues={data}
        onSubmit={onSubmit}
      />
    </Loader>
  );
}

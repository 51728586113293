import { useParams } from "react-router-dom";
import EmployeeView from "src/components/view/Employee.view";
import Loader from "src/components/Loader";
import Employee from "src/types/Employee.type";
import { useGET } from "src/hooks/useApi";

export default function ViewEmployeePage() {
  const params = useParams();
  const url = `res/employee/${params.id}`;
  const { data, isLoading, error } = useGET<Employee>(url);

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <EmployeeView employee={data} />
    </Loader>
  );
}

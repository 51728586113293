import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import InviteRequestView from "src/components/view/InviteRequest.view";
import { useGET } from "src/hooks/useApi";
import { InviteRequest } from "src/types/InviteRequest";

export default function ViewInviteRequestPage() {
  const params = useParams();

  const url = `res/inviteRequest/${params.id}`;
  const { data, isLoading, error } = useGET<InviteRequest>(url);

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <InviteRequestView invite={data} />
    </Loader>
  );
}

import _map from "lodash/map";
import { Tutorial } from "src/types/Tutorial.type";
import { useGET } from "./useApi";
import { useMemo } from "react";
import { SidebarLink } from "src/types/SidebarLinks";
import iconMaps from "src/constants/iconMaps";
import useUser from "./useUser";

export function useLinksWithTutorials(links: SidebarLink[]) {
  const { user } = useUser();
  const locale = user?.settings_language ?? "en";
  const { data, isLoading, error } = useGET<Tutorial[]>(
    `config/tutorials/${locale}`
  );

  const linksWithTutorials = useMemo(() => {
    if (isLoading || error) {
      return [];
    }
    return [
      {
        to: "/app/tutorial",
        icon: iconMaps.tutorial,
        label: "tutorial",
        isOpen: false,
        links: _map(data, (t) => ({
          to: `/app/tutorial/${t.id}`,
          label: t.title,
        })),
        classes: "tour_tutorial",
      },
      ...links,
    ];
  }, [data, isLoading, error, links]);

  return linksWithTutorials;
}

import Loader from "src/components/Loader";
import Actions from "src/components/Actions";
import JobOfferTable from "src/components/tables/JobOfferTable";
import { useGET } from "src/hooks/useApi";
import JobOffer from "src/types/JobOffer.type";
import { IconPlus } from "@tabler/icons-react";

const ListJobOffer = () => {
  const { data: jobOffers, isLoading, error } = useGET<JobOffer[]>(`res/job`);

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!jobOffers}>
      <Actions
        title="my_job_offers"
        actions={[{ to: "/app/add/job", label: "add_new", Icon: IconPlus }]}
      />
      <JobOfferTable jobOffers={jobOffers} />
    </Loader>
  );
};

export default ListJobOffer;

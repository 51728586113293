export function getRandomColor(label: string): string[] {
  const stringUniqueHash = [...label].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);

  // return `hsl(${stringUniqueHash % 360}, 65%, ${light ? 90 : 25}%)`;
  return [
    `hsl(${stringUniqueHash % 360}, 65%, 25%)`,
    `hsl(${stringUniqueHash % 360}, 65%, 90%)`,
  ];
}

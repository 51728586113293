import _includes from "lodash/includes";
import _startsWith from "lodash/startsWith";
import { Button } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { IconChevronLeft } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const shouldHide = (path: string): boolean => {
  return _includes(["/app", "app/view/job", "/app/view/employee"], path);
};

const shouldGoBack = (path: string): boolean => {
  return !_startsWith(path, "/app/section/");
};

const NavigationBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const url = useLocation();
  const path = url.pathname || "";

  if (shouldHide(path)) {
    return null;
  }

  const goBack = () => {
    shouldGoBack(path) ? navigate(-1) : navigate("/app");
  };

  return (
    <Button
      variant="subtle"
      aria-label="Back"
      color="default"
      onClick={goBack}
      visibleFrom="sm"
      my="xs"
    >
      <IconChevronLeft />
      {t("back")}
    </Button>
  );
};

export default NavigationBar;

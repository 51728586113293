import { Box, Button, Group, Stack } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import RegisterCompanyCreate from "./RegisterCompanyCreate";
import RegisterCompanyJoin from "./RegisterCompanyJoin";

interface Props {
  onSuccess: () => void;
  isLoading: boolean;
}

// user decides here his role
// - create new company -> owner
// - join existing company -> readonly

export default function RegisterCompany({ onSuccess, isLoading }: Props) {
  const [choice, setChoice] = useState(true);
  const { t } = useTranslation();

  return (
    <Stack>
      <Group justify="center">
        <Button.Group>
          <Button
            variant={choice ? "primary" : "default"}
            onClick={() => setChoice(true)}
          >
            {t("create_company")}
          </Button>
          <Button
            variant={choice ? "default" : "primary"}
            onClick={() => setChoice(false)}
          >
            {t("join_company")}
          </Button>
        </Button.Group>
      </Group>
      <Box px="md">
        {choice ? (
          <RegisterCompanyCreate nextTab={onSuccess} isLoading={isLoading} />
        ) : (
          <RegisterCompanyJoin nextTab={onSuccess} />
        )}
      </Box>
    </Stack>
  );
}

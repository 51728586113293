import { Button, Text } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { useTranslation } from "react-i18next";

const NotificationModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<{
  modalBody: string;
  onClose: () => void;
}>) => {
  const close = () => {
    context.closeModal(id);
    innerProps.onClose();
  };
  const { t } = useTranslation();

  return (
    <>
      <Text dangerouslySetInnerHTML={{ __html: innerProps.modalBody }} />
      <Button fullWidth mt="md" onClick={close}>
        {t('okay')}
      </Button>
    </>
  );
};

export default NotificationModal;

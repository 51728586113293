import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import ReviewView from "src/components/view/Review.view";
import { useGET } from "src/hooks/useApi";
import Review from "src/types/Review.type";

export default function ViewReviewPage() {
  const params = useParams();
  const url = `res/review/${params.id}`;
  const { data, isLoading, error } = useGET<Review>(url);

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <ReviewView review={data} />
    </Loader>
  );
}

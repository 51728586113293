import { Divider, Flex, Grid, GridCol, Text } from "@mantine/core";
import { ReactNode } from "react";
import { Title, TitleOrder } from "@mantine/core";
import useIsMobile from "src/hooks/useIsMobile";

interface Props {
  children: ReactNode;
  title?: ReactNode;
  desc?: string;
  order?: TitleOrder;
}

export const Row = ({ children, title, desc, order = 4 }: Props) => {
  const isMobile = useIsMobile();
  const cols = isMobile ? 1 : 12;
  const offset = isMobile ? 1 : 3;

  return (
    <>
      <Grid align="stretch" columns={cols}>
        {(title || desc) && (
          <GridCol span={offset}>
            <Flex justify={isMobile ? "center" : "flex-end"} direction="column">
              <Title order={order} mx={isMobile ? "auto" : "xs"}>
                {title}
              </Title>
              <Text size="sm">{desc}</Text>
            </Flex>
          </GridCol>
        )}
        <GridCol span="auto">
          <Flex
            wrap="wrap"
            direction="row"
            justify={isMobile ? "center" : "left"}
            mb="md"
          >
            {children}
          </Flex>
        </GridCol>
      </Grid>
      <Divider my="md" />
    </>
  );
};

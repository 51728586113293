import _get from "lodash/get";
import { Button, Flex, Paper, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Company from "src/types/Company.type";
import SubscriptionStatus from "./SubscriptionStatus";
import useIsMobile from "src/hooks/useIsMobile";

interface Props {
  company?: Company;
}

const CompanySubscription = (props: Props) => {
  const { company } = props;
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  if (!company) {
    return null;
  }

  const email = company?.contact_mail;
  const credits = _get(company, ["account_Subscription", "Package", "credits"]);
  const costMM = Number(
    _get(company, ["account_Subscription", "Package", "cost_month"], 0)
  );
  const costYY = Number(
    _get(company, ["account_Subscription", "Package", "cost_year"], 0)
  );
  const ccy = _get(company, ["account_Subscription", "Package", "ccy"]);
  const period = costYY ? "year" : "month";
  const subName = _get(company, ["account_Subscription", "Package", "title"]);

  const subPortalUrl = import.meta.env.VITE_STRIPE_DASHBOARD_URL;
  const goToDashboard = () => {
    const url = `${subPortalUrl}?prefilled_email=${email}`;
    window?.open(url, "_blank")?.focus();
  };

  const remainingCredits = (
    <Paper
      ta="center"
      miw={180}
      w={isMobile ? "100%" : "30%"}
      mih={160}
      py="xl"
    >
      <Title>{company.account_credits}</Title>
      <Title order={4}>{t("credits_left")}</Title>
    </Paper>
  );

  if (!subName) {
    return (
      <Flex direction="column" my="md" gap="md">
        <Flex justify="center" wrap="wrap" gap="xs">
          <Paper
            ta="center"
            mih={160}
            py="xl"
            miw={180}
            w={isMobile ? "100%" : "60%"}
          >
            <Title order={2}>{t("no_subscription")}</Title>
            <Text fw="400">{t("no_subscription_desc")}</Text>
          </Paper>
          {remainingCredits}
        </Flex>
        <Button fullWidth component={Link} to="/app/credits">
          {t("buy_subscription")}
        </Button>
      </Flex>
    );
  }

  const active = company.account_Subscription?.isActive ?? false;

  return (
    <Flex direction="column" my="md" gap="md">
      <Title order={3} ta="center" tt="capitalize">
        {subName}
        <br />
        <SubscriptionStatus subscription={company.account_Subscription} />
      </Title>
      <Flex justify="center" wrap="wrap" gap="xs">
        {/* sub cost */}
        <Paper
          ta="center"
          miw={180}
          w={isMobile ? "100%" : "30%"}
          h={160}
          py="xl"
          c={!active ? "lightgrey" : "black"}
        >
          <Flex direction="column">
            <Title td={active ? "" : "line-through"}>
              {costMM || costYY || "-"}
            </Title>
            {(!!costMM || !!costYY) && (
              <Title order={4}>{`${ccy}/${t(period)}`}</Title>
            )}
          </Flex>
        </Paper>

        {/* credits per month */}
        <Paper
          ta="center"
          miw={180}
          w={isMobile ? "100%" : "30%"}
          h={160}
          py="xl"
          c={!active ? "lightgrey" : "black"}
        >
          <Title td={active ? "" : "line-through"}>{credits}</Title>
          <Title order={4}>{t("monthly_credits")}</Title>
        </Paper>

        {remainingCredits}
      </Flex>
      <Button fullWidth component={Link} to="/app/credits">
        {t("buy_more_credits")}
      </Button>
      <Button fullWidth onClick={goToDashboard}>
        {t("manage_subscription")}
      </Button>
    </Flex>
  );
};

export default CompanySubscription;

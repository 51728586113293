import _size from "lodash/size";
import _isEmpty from "lodash/isEmpty";
import { Seniority } from "src/types/Seniority.type";
import includesEmailAddress from "src/utils/includesEmailAddress";
import includesPhoneNumber from "src/utils/includesPhoneNumber";
import { translate as t } from "src/helpers/initLanguage";
import { UserRole } from "src/types/UserRole";
import { CompanyType } from "src/types/CompanyType.type";

export const title = (value: string | undefined = "") =>
  value && value.length > 3 ? null : "Invalid title (min lenght 4)";

export const jobTitle = (value: string[] | string | undefined) => {
  if (!value || _isEmpty(value)) {
    return t("job_title_req");
  }
};

export const skills = (value: string[] | undefined) => {
  if (!value || _isEmpty(value)) {
    return t("skill_req");
  }
};

export const level = (value: Seniority | undefined) => {
  if (!value || _isEmpty(value)) {
    return t("level_req");
  }
};

export const desc = (value: string | undefined = "") => {
  if (!value || value.length < 3) {
    return t("desc_req");
  }
  if (includesEmailAddress(value) || includesPhoneNumber(value)) {
    return t("desc_invalid");
  }

  return null;
};

export const hourlyRate = (value: number | undefined) => {
  if (!value) {
    return t("rate_req");
  }
};

export const duration = (value: number | undefined) => {
  if (!value) {
    return t("duration_req");
  }
};

export const price = (value: number | undefined) => {
  if (!value) {
    return t("price_req");
  }
};

export const jobOfferId = (value: string | undefined) => {
  if (!value) {
    return t("job_req");
  }
};

export const employeeId = (value: string | string[] | undefined) => {
  if (_isEmpty(value) || !value) {
    return t("employees_req");
  }
};

export const name = (value: string | undefined = "") => {
  if (_isEmpty(value)) {
    return t("name_req");
  }
  if (_size(value) < 2) {
    return t("name_size");
  }
};

export const email = (value: string | undefined = "") => {
  if (!value) {
    return t("email_req");
  }
  if (_size(value) < 4) {
    return t("email_size");
  }
  if (!value.includes("@") || !value.includes(".")) {
    return t("email_format");
  }
  return null;
};

export const phone = (value: string | number | undefined) => {
  if (_isEmpty(value)) {
    return t("phone_req");
  }
};

export const role = (value: UserRole | undefined) => {
  if (!value) {
    return t("role_req");
  }
  return null;
};

export const vat = (value: string | undefined) => {
  if (_isEmpty(value)) {
    return t("vat_req");
  }
};

export const slug = (value: string | undefined) => {
  if (_isEmpty(value)) {
    return t("slug_req");
  }
};

export const companyType = (value: CompanyType | undefined) => {
  if (_isEmpty(value)) {
    return t("company_type_req");
  }
};

export const birthYear = (value: string | undefined) => {
  if (!value) {
    return null;
  }

  const currYY = new Date().getFullYear();
  const parsedYY = parseInt(value, 10);
  if (isNaN(parsedYY) || currYY < parsedYY) {
    return t("value_invalid");
  }

  if (currYY < parsedYY + 17) {
    return t("invalid_birth_date");
  }
  return null;
};

// address required
// loc_address: (value: string | undefined) => {
//   if (!value) {
//     return "Address is required";
//   }
// },
// loc_city: (value: string | undefined) => {
//   if (!value) {
//     return "City is required";
//   }
// },
// loc_nation: (value: string | undefined) => {
//   if (!value) {
//     return "Nation is required";
//   }
// },
// loc_num: (value: string | undefined) => {
//   if (!value) {
//     return "Location number is required";
//   }
// },
// loc_prov: (value: string | undefined) => {
//   if (!value) {
//     return "Prov is required";
//   }
// },
// loc_zip: (value: string | undefined) => {
//   if (!value) {
//     return "Zip is required";
//   }
// },

import JobForm from "src/components/forms/Job.form";
import { useNavigate } from "react-router-dom";
import JobOffer from "src/types/JobOffer.type";
import useApi from "src/hooks/useApi";
import CONFIG_KEYS from "src/constants/config.const";

export default function AddJob() {
  const navigate = useNavigate();
  const API = useApi();

  const onSubmit = async (values: Partial<JobOffer>) => {
    const res = await API.POST(`res/job`, values);
    if (res?.success) {
      navigate("/app/section/jobs");
    }
  };

  return (
    <JobForm
      title="create_job_offer"
      onSubmit={onSubmit}
      costId={CONFIG_KEYS.ADD_JOBOFFER_COST}
      expiryId={CONFIG_KEYS.JOBOFFER_EXPIRY_PERIOD}
    />
  );
}

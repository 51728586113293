import _get from "lodash/get";
import { statusColors } from "src/constants/statusColors.const";
import { SOON } from "src/constants/expirySoon.const";

function getExpiryColor(expiryDate: Date | null, status: string) {
  if (!expiryDate) {
    return statusColors.unknown;
  }
  const now = new Date();
  if (expiryDate < now) {
    return statusColors.elapsed;
  }

  if (expiryDate.getTime() - SOON < now.getTime()) {
    return statusColors.warning;
  }

  return _get(statusColors, status, statusColors.active);
}

export default getExpiryColor;

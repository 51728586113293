import { Box, InputLabel, Text, useMantineColorScheme } from "@mantine/core";
import MDEditor from "@uiw/react-md-editor";

interface Props {
  value?: string;
  onChange: (s: unknown) => void;
  required?: boolean;
  error?: string;
  label?: string;
  placeholder?: string;
}

const DescriptionInput = ({
  value,
  onChange,
  required,
  label,
  placeholder,
  error,
}: Props) => {
  const { colorScheme } = useMantineColorScheme();
  // TEMP hack to adapt to current theme
  // use actual theme color values directly from theme obj
  const s = {
    outline: error ? "1px solid red" : "",
    background: colorScheme === "dark" ? "#2e2e2e" : undefined,
    border: colorScheme === "dark" ? "1px solid #424242" : "1px solid #ced4da",
    borderRadius: 4,
  };
  return (
    <Box>
      <InputLabel mt="md" mb="2">
        {label}
        {required && <span style={{ color: "red" }}> *</span>}
      </InputLabel>
      <div data-color-mode={colorScheme}>
        <MDEditor
          hideToolbar
          enableScroll
          preview="edit"
          textareaProps={{
            placeholder,
            maxLength: 500,
          }}
          style={s}
          value={value}
          onChange={onChange}
        />
      </div>
      {error && (
        <Text size="xs" c="red" mt="xs">
          {error}
        </Text>
      )}
    </Box>
  );
};

export default DescriptionInput;

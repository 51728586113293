import { Fragment } from "react";
import { Table, Text } from "@mantine/core";
import { TableRow } from "src/types/TableRow.type";
import Company from "src/types/Company.type";
import Avatar from "src/components/Avatar";
import CompanySocials from "src/components/cards/CompanySocials";

const MatchesTableRow = ({ item, onClick }: TableRow<Partial<Company>>) => (
  <Fragment key={item.id}>
    <Table.Td onClick={onClick}>
      <Avatar size={40} src={item.profilePic} />
    </Table.Td>
    <Table.Td onClick={onClick}>
      <Text fw={700} tt={"capitalize"}>
        {item?.name}
      </Text>
    </Table.Td>
    <Table.Td onClick={onClick}>{item?.vat}</Table.Td>
    <Table.Td onClick={onClick}>{item?.type}</Table.Td>
    <Table.Td onClick={onClick}>{item?.size}</Table.Td>
    <Table.Td onClick={onClick}>
      <CompanySocials
        twitter={item?.contact_twitter}
        linkedIn={item?.contact_linkedin}
        mail={item?.contact_mail}
        phone={item?.contact_phone}
        website={item?.contact_website}
      />
    </Table.Td>
  </Fragment>
);

export default MatchesTableRow;

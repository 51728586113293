import { Badge, Text } from "@mantine/core";
import { formatDate } from "src/helpers/formatDate";

const style = {
  fontWeight: 400,
  color: "black",
  background: "#F2F4F7",
};

interface Props {
  date?: string;
  naked?: boolean;
  withTime?: boolean;
}

export const DateTag = ({ date, naked = false, withTime = false }: Props) => {
  if (!date) {
    return null;
  }

  const formatted = formatDate(date, withTime);

  return naked ? (
    <Text size="xs">{formatted}</Text>
  ) : (
    <Badge style={style}>{formatted}</Badge>
  );
};

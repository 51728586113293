import {
  Grid,
  GridCol,
  Rating,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { Row } from "src/components/Row";
import Actions from "src/components/Actions";
import Review from "src/types/Review.type";
import JobOfferCard from "../cards/Job.Card";
import CompanyFromTo from "../CompanyFromTo";
import { useTranslation } from "react-i18next";
import Description from "../Description";

interface Props {
  review: Review;
}

export default function ReviewView({ review }: Props) {
  const {
    title,
    desc,
    score_reliability,
    score_skills,
    score_timelines,
    score_total,
    JobOffer,
  } = review;
  const { t } = useTranslation();

  return (
    <>
      <Title m="md">{title}</Title>

      <Grid>
        <GridCol span={4}>
          <JobOfferCard jobOffer={JobOffer} />
        </GridCol>
        <Stack>
          <CompanyFromTo el={review} />
        </Stack>
      </Grid>

      <Space my="md" />

      <Row>
        <GridCol span="auto">
          <Description text={desc} />
        </GridCol>
      </Row>

      <Row title={t("reliability")}>
        <GridCol span={4}>
          <Text>{t("rating")}</Text>
          <Rating readOnly defaultValue={score_reliability} />
        </GridCol>
      </Row>

      <Row title={t("skills")}>
        <GridCol span={4}>
          <Text>{t("rating")}</Text>
          <Rating readOnly defaultValue={score_skills} />
        </GridCol>
      </Row>

      <Row title={t("timelines")}>
        <GridCol span={4}>
          <Text>{t("rating")}</Text>
          <Rating readOnly defaultValue={score_timelines} />
        </GridCol>
      </Row>

      <Row title={t("total")}>
        <GridCol span={4}>
          <Text>{t("rating")}</Text>
          <Rating readOnly defaultValue={score_total} />
        </GridCol>
      </Row>

      <Actions
        actions={[
          {
            label: "back",
            to: "/app/section/reviews",
            variant: "default",
          },
        ]}
      />
    </>
  );
}

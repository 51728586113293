import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import { Outlet } from "react-router-dom";
import useRegisterIfNeeded from "src/hooks/useRegisterIfNeeded";

function RootLayout() {
  useRegisterIfNeeded();

  return (
    <>
      <Outlet />
    </>
  );
}

export default RootLayout;

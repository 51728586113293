import SimpleTable from "./SimpleTable";
import Application from "src/types/Application.type";
import ApplicationTableRow from "./rows/ApplicationTable.Row";

interface Props {
  data?: Application[];
}

const cols = ["for_job_offer", "employee", "desc", "status"];

function ApplicationsTable({ data = [] }: Props) {
  return (
    <SimpleTable
      section="application"
      cols={cols}
      rows={data}
      Row={ApplicationTableRow}
      readOnly
    />
  );
}

export default ApplicationsTable;

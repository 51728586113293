import { GridCol, Stack } from "@mantine/core";
import { useTranslation } from "react-i18next";
import CompanySubscription from "src/components/CompanySubscription";
import { Row } from "src/components/Row";
import useIsMobile from "src/hooks/useIsMobile";
import useUser from "src/hooks/useUser";

export default function SettingsBilling() {
  const { t } = useTranslation();
  const { user } = useUser();
  const isMobile = useIsMobile();
  const span = isMobile ? 1 : 8;

  return (
    <Stack>
      <Row title={t("subscription")}>
        <GridCol span={span}>
          <CompanySubscription company={user?.Company} />
        </GridCol>
      </Row>
    </Stack>
  );
}

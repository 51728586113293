import _toLower from "lodash/toLower";
import _get from "lodash/get";
import { Image, Tooltip } from "@mantine/core";
import countries from "src/constants/countries";

interface Props {
  id: string;
  size?: number;
  noTooltip?: boolean;
}

const CountryIcon = ({ id, size = 5, noTooltip = false }: Props) => {
  const label = _get(countries, id);
  const icon = (
    <Image
      w={4 * size}
      h={3 * size}
      alt={id}
      m={1}
      src={`/flags/${id ? _toLower(id.substring(0, 2)) : "ph"}.svg`}
      fallbackSrc={"/flags/404.png"}
    />
  );

  return noTooltip ? icon : <Tooltip label={label}>{icon}</Tooltip>;
};

export default CountryIcon;

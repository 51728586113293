import _get from "lodash/get";
import _map from "lodash/map";
import _capitalize from "lodash/capitalize";
import { Box, Tabs as MantineTabs, Select } from "@mantine/core";
import { ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toFinite } from "lodash";
import useIsMobile from "src/hooks/useIsMobile";

interface Props {
  tabs: {
    id: string;
    title: string;
    Icon?: ReactNode;
    content: ReactNode;
  }[];
  isHorizontal?: boolean;
}

export default function Tabs({ tabs, isHorizontal = false }: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [index, setIndex] = useState<number>(0);

  const handleChange = (i: string | null) => {
    setIndex(toFinite(i) ?? 0);
  };
  const currentContent = useMemo(
    () => _get(tabs, [toFinite(index), "content"]),
    [index, tabs]
  );

  if (isMobile) {
    return (
      <>
        <Select
          variant="filled"
          value={`${index}`}
          onChange={handleChange}
          data={_map(tabs, (t, i) => ({
            value: `${i}`,
            label: t.title,
          }))}
        />
        <Box>{currentContent}</Box>
      </>
    );
  }

  return (
    <MantineTabs
      orientation={isHorizontal ? "horizontal" : "vertical"}
      defaultValue={tabs[0]?.id || "0"}
    >
      <MantineTabs.List mb="xl" mr="md">
        {_map(tabs, ({ id, title, Icon }) => (
          <MantineTabs.Tab
            key={id}
            value={id}
            leftSection={Icon}
            miw={isHorizontal ? undefined : 200}
            fw={600}
            style={{ border: "1px solid" }}
          >
            {_capitalize(t(title))}
          </MantineTabs.Tab>
        ))}
      </MantineTabs.List>
      {_map(tabs, ({ id, content }) => (
        <MantineTabs.Panel value={id} key={id} p="md">
          {content}
        </MantineTabs.Panel>
      ))}
    </MantineTabs>
  );
}

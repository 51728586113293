import { useTranslation } from "react-i18next";
import { Text } from "@mantine/core";
import _toLower from "lodash/toLower";

interface Props {
  list?: string[];
  cap?: boolean;
}

const FieldList = ({ list = [], cap = false }: Props) => {
  const { t } = useTranslation();
  const transList = list.map((l) => t(_toLower(l)));
  const size = cap ? "sm" : "md";

  return (
    <Text tt="capitalize" lineClamp={cap ? 2 : 10} size={size}>
      {transList.join(", ")}
    </Text>
  );
};

export default FieldList;

import EmployeeForm from "src/components/forms/Employee.form";
import Employee from "src/types/Employee.type";
import { useNavigate } from "react-router-dom";
import useApi from "src/hooks/useApi";
import CONFIG_KEYS from "src/constants/config.const";

export default function AddEmployee() {
  const navigate = useNavigate();
  const API = useApi();

  const onSubmit = async (values: Partial<Employee>, file?: File) => {
    if (file) {
      const res = await API.UPLOAD(file);
      values.profilePic = res.name;
    }
    const res = await API.POST(`res/employee`, values);
    if (res?.success) {
      navigate("/app/section/employees");
    }
  };

  return (
    <EmployeeForm
      title="create_employee"
      onSubmit={onSubmit}
      costId={CONFIG_KEYS.ADD_EMPLOYEE_COST}
      expiryId={CONFIG_KEYS.EMPLOYEE_EXPIRY_PERIOD}
    />
  );
}

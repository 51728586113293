import { useMemo } from "react";
import getExpiryColor from "src/utils/getExpiryColor";
import getExpiryStatus from "src/utils/getExpiryStatus";

const useExpiryValues = (expiryDate: Date | null) => {
  const values = useMemo(() => {
    const status = getExpiryStatus(expiryDate);
    const color = getExpiryColor(expiryDate, status);
    const label = expiryDate ? expiryDate?.toLocaleDateString() : "";
    const variant = status === "unknown" ? "default" : "light";

    return {
      expiryDate,
      label,
      status,
      color,
      variant,
    };
  }, [expiryDate]);

  return values;
};

export default useExpiryValues;

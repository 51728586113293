import Actions from "src/components/Actions";
import Loader from "src/components/Loader";
import { InviteRequestTable } from "src/components/tables/InviteRequestTable";
import { useGET } from "src/hooks/useApi";
import { InviteRequest } from "src/types/InviteRequest";

const ListInvites = () => {
  const {
    data: invites,
    isLoading,
    error,
  } = useGET<InviteRequest[]>("res/inviteRequest");

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!invites}>
      <>
        <Actions title="invites_received" />
        <InviteRequestTable data={invites} readOnly />
      </>
    </Loader>
  );
};

export default ListInvites;

import { Button, Stack, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface Props {
  resetFilters: () => void;
}

const SearchNoResults = (props: Props) => {
  const { resetFilters } = props;
  const { t } = useTranslation();

  return (
    <Stack align="center" m="auto">
      <Title>{t("no_results_found")}</Title>
      <Button onClick={resetFilters}>{t("reset_filters")}</Button>
    </Stack>
  );
};

export default SearchNoResults;

import _get from "lodash/get";
import { fetchUserAttributes } from "aws-amplify/auth";

async function getUser() {
  try {
    const userAttributes = await fetchUserAttributes();

    return {
      id: userAttributes?.sub,
      name: userAttributes?.name,
      surname:
        userAttributes?.surname ??
        userAttributes?.family_name ??
        _get(userAttributes, ["custom:surname"]),
      phone:
        userAttributes?.phone_number ?? _get(userAttributes, ["custom:phone"]),
      origin: _get(userAttributes, "custom:origin"),
      ref: _get(userAttributes, "custom:ref_id"),
      email: userAttributes?.email,
      emailVerified: userAttributes?.email_verified === "true",
    };
  } catch (error) {
    return {
      id: null,
      email: null,
      name: null,
      surname: null,
      phone: null,
      origin: null,
      ref: null,
      emailVerified: false,
    };
  }
}

export default getUser;

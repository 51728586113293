import _omit from "lodash/omit.js";
import { Stack, Title } from "@mantine/core";
import Actions from "../Actions";
import { useForm } from "@mantine/form";
import Proposal from "src/types/Proposal.type";
import { ProposalValidate } from "src/pages/data/validates/proposal.validate";
import EmployeeCard from "../cards/Employee.Card";
import SelectJobOfferTable from "../tables/SelectJobOfferTable";
import useFormActions from "src/hooks/useFormActions";
import useUnloadAlert from "src/hooks/useUnloadAlert";
import { useTranslation } from "react-i18next";
import { META_FIELDS } from "src/constants/metaFields.const";
import DescriptionInput from "src/components/DescriptionInput";

interface Props {
  title: string;
  initialValues?: Partial<Proposal>;
  onSubmit?: (values: Partial<Proposal>) => void;
  costId?: string;
  expiryId?: string;
}

export default function ProposalForm({
  title,
  initialValues = {},
  onSubmit = () => {},
  costId,
  expiryId,
}: Props) {
  const form = useForm<Partial<Proposal>>({
    validateInputOnChange: true,
    initialValues,
    validate: ProposalValidate,
    transformValues: (values) => ({
      ..._omit(values, ["toEmployee", ...META_FIELDS]),
    }),
  });
  useUnloadAlert();
  const formActions = useFormActions(
    form,
    onSubmit,
    undefined,
    costId,
    expiryId
  );
  const { t } = useTranslation();

  return (
    <Stack maw={1000} m="auto">
      <Title order={2}>{t(title)}</Title>

      <Title order={3}>{t("to_employee_info")}</Title>
      <EmployeeCard employee={initialValues.toEmployee} />

      <Title order={3}>{t("select_job_offer")}</Title>
      <SelectJobOfferTable {...form.getInputProps("jobOfferId")} />

      <Title order={3}>{t("add_your_cover_letter")}</Title>

      <DescriptionInput
        required
        label={t("description")}
        {...form.getInputProps("desc")}
      />

      {/* <FormSection title="set_status">
        <Select
          label={t("status")}
          placeholder={t("status_ph")}
          searchable
          data={["open", "draft"]}
          {...form.getInputProps("status")}
        />
      </FormSection> */}

      <Actions actions={formActions} isReady={form.isValid()} />
    </Stack>
  );
}

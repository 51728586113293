import { methodEnum } from "src/types/Method.type";
import fetchFn from "./fetcher";
const baseUrl = import.meta.env.VITE_API_URL;

const API = (token: string | undefined) => ({
  [methodEnum.GET]: (url: string, opts?: { silent: boolean }) =>
    fetchFn(
      {
        method: "GET",
        url: `${baseUrl}/${url}`,
        token,
      },
      opts
    ),
  [methodEnum.POST]: (url: string, body: object, opts?: { silent: boolean }) =>
    fetchFn(
      {
        method: "POST",
        url: `${baseUrl}/${url}`,
        body,
        token,
      },
      opts
    ),
  [methodEnum.DELETE]: (url: string, opts?: { silent: boolean }) =>
    fetchFn(
      {
        method: "DELETE",
        url: `${baseUrl}/${url}`,
        token,
      },
      opts
    ),
    [methodEnum.PUT]: (
        url: string,
        body: object,
        opts?: { silent: boolean },
        extraHeaders?: object // custom Headers
    ) =>
    fetchFn(
        {
            method: "PUT",
            url: `${baseUrl}/${url}`,
            body,
            token,
            extraHeaders, // Passing the custom headers to fetchFn
        },
        opts
    ),
    [methodEnum.UPLOAD]: (file: File, type = "img") => {
    const body = new FormData();
    body.append("image", file);

    return fetch(`${baseUrl}/upload/${type}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    }).then((res) => res.json());
  },
});
export default API;

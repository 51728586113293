import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import {
  AppShell,
  Box,
  LoadingOverlay,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useViewportSize } from "@mantine/hooks";
import Sidebar from "src/components/Sidebar";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import { layoutConst } from "src/constants/layout.const";
import usePing from "src/hooks/usePing";
import useIsMobile from "src/hooks/useIsMobile";
import useAuth from "src/hooks/useAuth";
import useScrollTop from "src/hooks/useScrollTop";
import SupportLinks from "./SupportLinks";

function MainLayout() {
  usePing();
  useScrollTop();
  const { width } = useViewportSize();
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure();
  const xlScreenSize = width > layoutConst.XL_SCREEN_SIZE;
  const { email } = useAuth();
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const bgColor =
    colorScheme === "light" ? theme.colors.gray[2] : theme.colors.gray[8];
  const isMobile = useIsMobile();
  const headerHeight = layoutConst.HEADER_HEIGHT;
  const toggleOnMobile = () => {
    if (isMobile) {
      toggleMobile();
    }
  };
  const mainHeight = `calc(100vh - ${headerHeight}px)`;
  const mainWidth = xlScreenSize
    ? `calc(100vw - ${layoutConst.SIDEBAR_WIDTH})`
    : "100%";

  // TODO HACK
  // in order to navigate on notification click we need to make notifications inside
  // the router provider, a cleaner solution would be to rewrite the routes as a component
  return (
    <AppShell
      navbar={{
        width: isMobile ? "" : layoutConst.SIDEBAR_WIDTH,
        breakpoint: layoutConst.SIDEBAR_BREAKPOINT,
        collapsed: {
          mobile: !mobileOpened,
          desktop: !desktopOpened && !xlScreenSize,
        },
      }}
      header={{
        height: headerHeight,
      }}
      py="md"
      bg={bgColor}
    >
      <AppShell.Header pr={isMobile ? 0 : "md"} withBorder={false} bg={bgColor}>
        <Header
          showBurger={xlScreenSize}
          toggleDesktop={toggleDesktop}
          toggleMobile={toggleMobile}
        />
      </AppShell.Header>

      <AppShell.Navbar
        onClick={toggleOnMobile}
        withBorder={false}
        bg="transparent"
      >
        <Sidebar />
      </AppShell.Navbar>

      <AppShell.Main h={mainHeight} w={mainWidth}>
        <Box bg={bgColor} mt="md">
          <LoadingOverlay
            visible={!email}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />
          <Box mt={isMobile ? 80 : 0} mx={isMobile ? "xs" : "md"}>
            <Outlet />
          </Box>
        </Box>
        {/* support button */}
        <SupportLinks small />
      </AppShell.Main>
    </AppShell>
  );
}

export default MainLayout;

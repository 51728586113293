import _isNull from "lodash/isNull";
import { Stack, Text, TextInput } from "@mantine/core";
import FormSection from "src/components/forms/FormSection";
import Company from "src/types/Company.type";
import Actions from "../Actions";
import { useForm } from "@mantine/form";
import useFormActions from "src/hooks/useFormActions";
import useUnloadAlert from "src/hooks/useUnloadAlert";
import { useTranslation } from "react-i18next";
import useApi from "src/hooks/useApi";
import { useState } from "react";
import { IconCircleCheck, IconXboxX } from "@tabler/icons-react";
import DescriptionInput from "src/components/DescriptionInput";

interface Props {
  initialValues?: Partial<Company>;
  onSubmit?: (values: Partial<Company>, file?: File) => void;
}

export default function CompanyInviteForm({
  initialValues = {
    vat: "",
    desc: "",
  },
  onSubmit = () => {},
}: Props) {
  const [found, setFound] = useState<boolean | null>(null);

  const form = useForm<Partial<Company>>({
    validateInputOnChange: true,
    initialValues,
    validate: {
      vat: (value: string | undefined) => {
        if (!value) {
          return "vat is required";
        }
        if (found === false) {
          return "No company found with the provided VAT";
        }
      },
    },
    transformValues: (values) => ({
      vat: values.vat,
      desc: values.desc,
    }),
  });
  const API = useApi();
  useUnloadAlert();
  const formActions = useFormActions(form, onSubmit);
  const { t } = useTranslation();

  const checkVat = () => {
    form.setFieldValue("check", null);
    const vat = form.values.vat;
    if (!vat) {
      setFound(null);
      return;
    }
    API.GET(`search/company?vat=${vat}`).then((res) => {
      setFound(res?.result);
      form.setFieldValue("check", res);
    });
  };

  const foundIcon = _isNull(found) ? null : found ? (
    <IconCircleCheck color="green" />
  ) : (
    <IconXboxX color="red" />
  );

  return (
    <Stack maw={1000} miw={300} m="auto">
      <FormSection title={t("join_company_title")}>
        <Text>{t("join_company_title_sub")}</Text>
        <TextInput
          required
          maxLength={16}
          label="vat"
          placeholder=""
          {...form.getInputProps("vat")}
          onBlur={() => checkVat()}
          onFocus={() => setFound(null)}
          rightSection={foundIcon}
        />

        <DescriptionInput
          required
          label={t("description")}
          {...form.getInputProps("desc")}
        />
      </FormSection>
      <Actions actions={formActions} isReady={form.isValid()} />
    </Stack>
  );
}

import JobOffer from "src/types/JobOffer.type";
import EmptyCard from "./EmptyCard";
import Employee from "src/types/Employee.type";
import JobOfferCard from "./Job.Card";
import EmployeeCard from "./Employee.Card";
import Application from "src/types/Application.type";
import Proposal from "src/types/Proposal.type";
import ApplicationCard from "./Application.Card";
import ProposalCard from "./Proposal.Card";
import { InviteRequest } from "src/types/InviteRequest";
import Admin from "src/types/Admin.type";
import AdminCard from "./Admin.Card";
import InviteRequestCard from "./InviteRequest.Card";

interface Props {
  type: string;
  data?:
    | Partial<JobOffer>
    | Partial<Employee>
    | Partial<Application>
    | Partial<Proposal>
    | Partial<Admin>
    // | Partial<Billing>
    | Partial<InviteRequest>;
}

const GenericCard = ({ data, type }: Props) => {
  if (!data) {
    return <EmptyCard name={type} />;
  }

  switch (type) {
    case "job":
    case "job_offer":
    case "jobOffer": {
      return <JobOfferCard jobOffer={data as JobOffer} />;
    }
    case "employee": {
      return <EmployeeCard employee={data as Employee} />;
    }
    case "application": {
      return <ApplicationCard application={data as Application} />;
    }
    case "proposal": {
      return <ProposalCard proposal={data as Proposal} />;
    }
    case "user":
    case "admin": {
      return <AdminCard admin={data as Admin} />;
    }
    // case "billing": {
    //   return null;
    // }
    case "inviteRequest": {
      return <InviteRequestCard inviteRequest={data as InviteRequest} />;
    }

    default: {
      console.log("type not supported", type);
      return null;
    }
  }
};

export default GenericCard;

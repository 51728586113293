// import ReviewForm from "src/components/forms/Review.form";
// import { useNavigate, useParams } from "react-router-dom";
// import Review from "src/types/Review.type";
// import useApi, { useGET } from "src/hooks/useApi";
// import JobOffer from "src/types/JobOffer.type";
// import Loader from "src/components/Loader";

export default function AddReview() {
  return <>Creating reviews is currently disabled, try again later</>;
  // const API = useApi();
  // const navigate = useNavigate();
  // const { jobId } = useParams();
  // const url = `res/job/${jobId}`;
  // const { data, isLoading, error } = useGET<JobOffer>(url);
  // const initialValues: Partial<Review> = {
  //   JobOffer: data,
  //   jobOfferId: jobId,
  // };

  // const onSubmit = async (values: Partial<Review>) => {
  //   const res = await API.POST(`res/review`, values);
  //   if (res?.success) {
  //     navigate("/app/section/reviews");
  //   }
  // };

  // return (
  //   <Loader isLoading={isLoading} error={error} hasData={!!data}>
  //     <ReviewForm
  //       title="create_review"
  //       onSubmit={onSubmit}
  //       initialValues={initialValues}
  //     />
  //   </Loader>
  // );
}

import { Group, ScrollArea } from "@mantine/core";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export default function HorizontalScroll({ children }: Props) {
  return (
    <ScrollArea w="100%" my="md" mx="auto">
      <Group justify="start" align="top" wrap="nowrap" mb="md">
        {children}
      </Group>
    </ScrollArea>
  );
}

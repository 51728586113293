import _map from "lodash/map";
import {
  Anchor,
  Button,
  Flex,
  GridCol,
  Select,
  Switch,
  Text,
  Title,
  useMantineColorScheme,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Row } from "src/components/Row";
import notificationsSettings from "src/constants/notificationsSettings.const";
import i18n from "src/helpers/initLanguage";
import useSettings from "src/hooks/useSetting";
import supportedLocales from "src/constants/supportedLocales.const";
import useIsMobile from "src/hooks/useIsMobile";

export default function SettingsGeneral() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { toggleColorScheme, colorScheme } = useMantineColorScheme();
  const { load, save } = useSettings();

  const updateLocale = (l: string | null) => {
    if (!l) {
      return;
    }
    i18n.changeLanguage(l);
    save("settings_language", l);
  };

  const updateTheme = (t: string | null) => {
    if (!t || t === colorScheme) {
      return;
    }
    toggleColorScheme();
    save(`settings_theme`, t);
  };

  const toggleSetting = (id: string, value: boolean) => {
    save(`settings_show_${id}`, value);
  };

  const span = isMobile ? 1 : 8;

  return (
    <Flex p="md" mx="auto" maw={isMobile ? 300 : 1000} direction="column">
      <Row title={t("language")} desc={t("platform_language_ph")}>
        <GridCol span={span}>
          <Select
            defaultValue={i18n.language}
            data={supportedLocales}
            onChange={updateLocale}
            allowDeselect={false}
          />
        </GridCol>
      </Row>
      <Row title={t("theme")} desc={t("platform_theme_ph")}>
        <GridCol span={span}>
          <Select
            data={["light", "dark"]}
            defaultValue={colorScheme}
            onChange={updateTheme}
            allowDeselect={false}
          />
        </GridCol>
      </Row>
      <Row title={t("notifications")} desc={t("notifications_desc")}>
        <GridCol span={span}>
          <Flex direction="column">
            {_map(notificationsSettings, (n) => (
              <Flex key={n.id}>
                <Switch
                  defaultChecked={!!load(`settings_show_${n.id}`)}
                  onChange={(e) => toggleSetting(n.id, e.currentTarget.checked)}
                />
                <Flex direction="column" mx="md">
                  <Title order={4}>{t(n.title)}</Title>
                  <Text>{t(n.desc)}</Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </GridCol>
      </Row>
      <Row title={t("danger_zone")} desc={t("acc_opts")}>
        <GridCol span={span}>
          <Anchor
            href="mailto:support@autom8deal.com?subject=richiesta di transferimento azienda"
            target="_blank"
          >
            <Button variant="outline" c="red" fullWidth my="md">
              {t("transfer_ownership")}
            </Button>
          </Anchor>
          <Anchor
            href="mailto:support@autom8deal.com?subject=richiesta di rimozione azienda"
            target="_blank"
          >
            <Button variant="outline" c="red" fullWidth my="md">
              {t("delete_company")}
            </Button>
          </Anchor>
        </GridCol>
      </Row>
    </Flex>
  );
}

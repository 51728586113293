import { fetchAuthSession } from "aws-amplify/auth";

async function getToken() {
  try {
    const res = await fetchAuthSession();

    return res?.tokens?.accessToken?.toString();
  } catch (err) {
    console.log(err);
  }
}

export default getToken;

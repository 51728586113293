import { Card, Stack, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import useIsMobile from "src/hooks/useIsMobile";
import useUser from "src/hooks/useUser";

export default function BalanceCard() {
  const { user } = useUser();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return (
    <Card
      w="40%"
      miw={isMobile ? "100%" : 370}
      maw={570}
      mih={90}
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      c="white"
      bg="primary"
    >
      <Stack ta="center">
        <Title fw={900}>
          {user?.Company?.account_credits} {t("credits")}
        </Title>
        <Title order={3}>{t("balance_card_title")}</Title>
        <Text>{t("balance_card_sub")}</Text>
      </Stack>
    </Card>
  );
}

import { Badge } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { JobOfferStatus as JobOfferStatusType } from "src/types/JobOfferStatus.type";

interface Props {
  status: JobOfferStatusType;
}

const JobOfferStatusPill = (props: Props) => {
  const { status } = props;
  const { t } = useTranslation();

  switch (status) {
    case JobOfferStatusType.cancelled: {
      return (
        <Badge size="lg" c="yellow">
          {t("canceled")}
        </Badge>
      );
    }
    case JobOfferStatusType.closed: {
      return (
        <Badge size="lg" color="orange">
          {t("closed")}
        </Badge>
      );
    }
    case JobOfferStatusType.open: {
      return (
        <Badge size="lg" color="green">
          {t("open")}
        </Badge>
      );
    }
    case JobOfferStatusType.draft: {
      return (
        <Badge size="lg" color="grey">
          {t("draft")}
        </Badge>
      );
    }
    default: {
      return <>{t("n/a")}</>;
    }
  }
};

export default JobOfferStatusPill;

import { Space } from "@mantine/core";
import ListApplicationReceived from "./ListApplicationReceived";
import ListProposalDone from "./ListProposalDone";

const ListTalentHunting = () => {
  return (
    <>
      <ListApplicationReceived />
      <Space py="xl" />
      <ListProposalDone />
    </>
  );
};

export default ListTalentHunting;

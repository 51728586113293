import _get from "lodash/get";
import { useGET } from "./useApi";

const DEFAULT_VALUE = undefined;

const useConfig = () => {
  const { data, isLoading, error } = useGET<object>(`config`);

  return {
    all: data,
    isLoading,
    error,
    get: (key?: string) => {
      if (!key) {
        return null;
      }
      if (isLoading || error) {
        return DEFAULT_VALUE;
      }

      return _get(data, key, DEFAULT_VALUE);
    },
  };
};

export default useConfig;

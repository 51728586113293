import {
  title,
  jobTitle,
  skills,
  level,
  desc,
  hourlyRate,
  duration,
} from "./validations";

export const JobValidate = {
  title,
  jobTitle,
  skills,
  level,
  desc,
  hourlyRate,
  duration,
};

import { Notification, Stack } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CompanyInviteForm from "src/components/forms/CompanyInvite.form";
import useApi from "src/hooks/useApi";
import Company from "src/types/Company.type";

interface Props {
  nextTab: () => void;
}

export default function RegisterCompanyJoin({ nextTab }: Props) {
  const api = useApi();
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState(false);

  const onSubmit = async (values: Partial<Company>) => {
    const response = await api.POST(`account/invite`, values);
    if (response.result) {
      nextTab();
    } else {
      setShowMessage(true);
    }
  };

  return (
    <Stack mx="auto">
      <CompanyInviteForm onSubmit={onSubmit} />
      {showMessage && (
        <Notification title={t("invite_fail_title")} color="red">
          {t("invite_fail_message")}
        </Notification>
      )}
    </Stack>
  );
}

import { rem } from "@mantine/core";
import {
  IconX,
  IconCheck,
  IconInfoCircleFilled,
  IconAlertTriangle,
} from "@tabler/icons-react";

interface Props {
  type: "info" | "success" | "warning" | "danger" | string;
}

const getTypeAttributes = ({ type }: Props) => {
  switch (type) {
    case "info":
      return {
        icon: <IconInfoCircleFilled />,
        color: "blue",
      };
    case "success":
      return {
        icon: <IconCheck />,
        color: "green",
      };
    case "warning":
      return {
        icon: <IconAlertTriangle style={{ width: rem(20), height: rem(20) }} />,
        color: "yellow",
      };
    case "danger":
      return {
        icon: <IconX />,
        color: "red",
      };
    default:
      return {
        icon: "",
        color: "",
      };
  }
};

export default getTypeAttributes;

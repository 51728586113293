import { Box, Group, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface Props {
  info: string;
  children?: React.ReactNode;
  wide?: boolean;
}

export const InfoList = ({ info, children, wide }: Props) => {
  const { t } = useTranslation();
  const w = wide ? 160 : 80;

  return (
    <Group wrap="nowrap" align="top">
      <Box>
        <Title order={5} mr="xs" tt="capitalize" ta="left" w={w}>
          {t(info)}
        </Title>
      </Box>
      <Box>{children}</Box>
    </Group>
  );
};

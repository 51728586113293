import { Center, Image, Paper, Stack, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import SupportLinks from "./SupportLinks";

export default function Support() {
  const { t } = useTranslation();

  return (
    <Paper mx="xl" py="xl" radius="xl">
      <Center>
        <Stack align="center">
          <Image src="/support.webp" w={150} my="xl" />
          <Title ta="center" mb="sm">
            {t("need_support")}
          </Title>
          <SupportLinks />
        </Stack>
      </Center>
    </Paper>
  );
}

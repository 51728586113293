import { Badge } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Subscription } from "src/types/Subscription.type";

interface Props {
  subscription?: Subscription;
}

const today = new Date();

const SubscriptionStatus = (props: Props) => {
  const { subscription } = props;
  const { t } = useTranslation();

  if (!subscription) {
    return null;
  }

  if (!subscription.isActive) {
    const renew = new Date(subscription?.renewDate);

    if (renew < today) {
      return <Badge color="red">{t("inactive")}</Badge>;
    }
    return (
      <Badge color="yellow">
        {t("status_active")} {renew?.toLocaleDateString()}
      </Badge>
    );
  }

  return <Badge color="green">{t("active")}</Badge>;
};

export default SubscriptionStatus;

import { Text } from "@mantine/core";
import SelectTable from "./SelectTable";
import JobOfferTableRow from "./rows/JobOfferTable.Row";
import { useGET } from "src/hooks/useApi";
import JobOffer from "src/types/JobOffer.type";
import { useTranslation } from "react-i18next";

interface Props {
  value?: string;
  onChange: (curr: unknown) => void;
}

const cols = ["title", "job_title", "fields", "date_from", "active"];

function SelectJobOfferTable({ value = "", onChange }: Props) {
  const url = "res/job?active=true";
  const { data: jobs } = useGET<JobOffer[]>(url);
  const { t } = useTranslation();

  if (!jobs) {
    <Text>{t("no_jobs_found_create_one")}</Text>;
  }

  return (
    <SelectTable<JobOffer>
      section="job"
      rows={jobs}
      cols={cols}
      Row={JobOfferTableRow}
      selection={value}
      setSelection={onChange}
    />
  );
}

export default SelectJobOfferTable;

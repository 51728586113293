import { GridCol, Input, Notification, Stack } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LogoutButton from "src/components/LogoutButton";
import { Row } from "src/components/Row";
import useIsMobile from "src/hooks/useIsMobile";
import useUser from "src/hooks/useUser";
import { handleUpdatePassword } from "src/utils/updatePassword";
import Actions from "src/components/Actions";

export default function SettingsAccount() {
  const { t } = useTranslation();
  const { user } = useUser();
  const isMobile = useIsMobile();

  const [oldPassword, setOldPass] = useState("");
  const [newPassword, setPass] = useState("");
  const [newPasswordBis, setPass2] = useState("");
  const [updateDisabled, setUpdateDisabled] = useState(true);

  const reset = () => {
    setOldPass("");
    setPass("");
    setPass2("");
  };

  const submitNewPassword = () => {
    handleUpdatePassword({ oldPassword, newPassword });
    reset();
    // show pass update success
  };

  useEffect(() => {
    if (newPassword !== newPasswordBis) {
      setUpdateDisabled(true);
      // show diff password
    } else {
      if (oldPassword) {
        // regexp from aws error message
        const validateNewPass = /^[\S]+.*[\S]+$/;
        const okPass = validateNewPass.test(newPassword);
        console.log({
          newPassword,
          okPass,
        });
        setUpdateDisabled(!okPass);
      } else {
        setUpdateDisabled(false);
      }
    }
  }, [oldPassword, newPassword, newPasswordBis]);

  const span = isMobile ? 1 : 8;

  return (
    <Stack>
      <Row title={t("email_address")}>
        <GridCol span={span}>
          <Input
            type="email"
            placeholder={t("email")}
            value={user?.email}
            readOnly
            mb="xl"
          />
        </GridCol>
      </Row>

      <Row title={t("password")} desc={t("reset_password")}>
        <GridCol span={span}>
          <Input
            my="md"
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPass(e.target.value)}
            placeholder={t("current_pwd")}
          />
          <Notification title="Note">{t("pwd_reqs")}</Notification>
          <Input
            my="md"
            type="password"
            value={newPasswordBis}
            onChange={(e) => setPass2(e.target.value)}
            placeholder={t("new_pwd")}
          />
          <Input
            my="md"
            type="password"
            value={newPassword}
            onChange={(e) => setPass(e.target.value)}
            placeholder={t("confirm_pwd")}
          />
          <Actions
            actions={[
              {
                label: "reset",
                variant: "default",
                onClick: reset,
              },
              {
                label: "update",
                onClick: submitNewPassword,
                disabled: updateDisabled,
              },
            ]}
          />
        </GridCol>
      </Row>

      <Row title={t("logout")}>
        <GridCol span={span}>
          <LogoutButton />
        </GridCol>
      </Row>
    </Stack>
  );
}

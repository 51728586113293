import _set from "lodash/set";
import {
  Image,
  View,
  components,
  useTheme,
  SelectField,
  Input,
  Label,
  CheckboxField,
} from "@aws-amplify/ui-react";
import { Anchor, Box, Flex, Space } from "@mantine/core";
import { translate as t } from "src/helpers/initLanguage";
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
import useIsMobile from "./hooks/useIsMobile";
import { useMemo } from "react";

const originOptions = [
  "linkedin",
  "ads",
  "expo",
  "telemarketing",
  "wom",
  "other",
];

const terms = ["terms", "privacy", "cookies"];

// integrate basic translations with missing ones
_set(translations, "it.Name", "Nome");
_set(translations, "it.Surname", "Cognome");
_set(translations, "it.Enter your Name", "Inserisci il tuo Nome");
_set(translations, "it.Enter your Surname", "Inserisci il tuo Cognome");
_set(translations, "it.Enter your Password", "Inserisci la password");
_set(translations, "it.Please confirm your Password", "Conferma la password");
I18n.putVocabularies(translations);

const AppComponents = {
  ...components,
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Amplify logo"
          src="/logo-light.png"
          style={{ margin: "50px 0" }}
        />
      </View>
    );
  },
  SignUp: {
    Footer() {
      const isMobile = useIsMobile();

      return isMobile ? (
        <Box>
          <Space p="xl" />
        </Box>
      ) : null;
    },
    FormFields() {
      // scan from query params for a ref code
      const refId = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("ref") ?? "";
      }, []);

      return (
        <Box>
          <components.Authenticator.SignUp.FormFields />
          <Label fontSize={16} textTransform="capitalize">
            {t("surname")}
          </Label>
          <Input name="custom:surname" placeholder={t("surname_ph")} />
          <Label fontSize={16} textTransform="capitalize">
            {t("phone")}
          </Label>
          <Input name="custom:phone" placeholder={t("phone_ph")} />
          <SelectField
            name="custom:origin"
            label={t("register_origin")}
            descriptiveText=""
          >
            {originOptions.map((o: string) => (
              <option value={o} key={o}>
                {t("origin_" + o)}
              </option>
            ))}
          </SelectField>
          <Label fontSize={16}>{t("toc")}</Label>
          {terms.map((section) => (
            <Flex gap="xs" align="top" my="sm">
              <CheckboxField required name="terms" label={""} />
              <Label fontSize={14.4}>
                {t("i_accept_the")}
                <Anchor
                  href={`https://autom8deal.com/${section}`}
                  target="_blank"
                  ml="xs"
                >
                  {t(`${section}_desc`)}
                </Anchor>
              </Label>
            </Flex>
          ))}
          <Space my="md" />
          <input type="hidden" name="custom:ref_id" value={refId} />
        </Box>
      );
    },
  },
};

export default AppComponents;

import SimpleTable from "./SimpleTable";
import Proposal from "src/types/Proposal.type";
import ProposalTableRow from "./rows/ProposalTable.Row";

interface Props {
  data?: Proposal[];
}

const cols = ["to_employee", "job_offer", "desc", "status"];

function ProposalTable({ data = [] }: Props) {
  return (
    <SimpleTable
      section="proposal"
      cols={cols}
      rows={data}
      Row={ProposalTableRow}
      readOnly
    />
  );
}

export default ProposalTable;

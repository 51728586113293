import _map from "lodash/map";
import { Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface Props {
  list?: string[];
  cap?: boolean;
  asInputBox?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  onChange?: (v: string[]) => void;
}

const SkillsList = ({ list = [], cap = false, size = "md" }: Props) => {
  const { t } = useTranslation();

  return (
    <Text tt="capitalize" lineClamp={cap ? 2 : 10} size={size}>
      {_map(list, (l) => t(l)).join(", ")}
    </Text>
  );
};

export default SkillsList;

import { Space } from "@mantine/core";
import ListApplicationDone from "./ListApplicationDone";
import ListProposalReceived from "./ListProposalReceived";

const ListJobHunting = () => {
  return (
    <>
      <ListApplicationDone />
      <Space py="xl" />
      <ListProposalReceived />
    </>
  );
};

export default ListJobHunting;

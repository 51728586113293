import { Stack } from "@mantine/core";
import useIsMobile from "src/hooks/useIsMobile";

interface Props {
  url: string;
}

const Video = ({ url }: Props) => {
  const isMobile = useIsMobile();

  return (
    <Stack>
      <iframe
        width={isMobile ? "300" : "853"}
        height={isMobile ? "200" : "480"}
        src={`https://www.youtube.com/embed/${url}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          borderRadius: 16,
        }}
      />
    </Stack>
  );
};

export default Video;

import { Fragment } from "react";
import { Table, Text } from "@mantine/core";
import Application from "src/types/Application.type";
import { EmployeeList } from "src/components/badges/EmployeeList";
import { TableRow } from "src/types/TableRow.type";
import ApplicationStatusPill from "src/components/ApplicationStatusPill";

const ApplicationTableRow = ({ item, onClick }: TableRow<Application>) => (
  <Fragment key={item.id}>
    <Table.Td onClick={onClick}>
      <Text fw={700} tt={"capitalize"}>
        {item?.JobOffer?.title}
      </Text>
    </Table.Td>
    <Table.Td onClick={onClick}>
      <EmployeeList data={item?.Employees} />
    </Table.Td>
    <Table.Td onClick={onClick} maw={300}>
      {item?.desc}
    </Table.Td>
    <Table.Td onClick={onClick}>
      <ApplicationStatusPill status={item?.status} />
    </Table.Td>
  </Fragment>
);

export default ApplicationTableRow;

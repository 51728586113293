import { useTranslation } from "react-i18next";
import { useDetectLocale } from "./useDetectLocale";
import { useTour } from "@reactour/tour";
import { useEffect } from "react";
import tourSteps from "src/constants/tourSteps";
import { useNavigate } from "react-router-dom";

export function useI81nTour() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locale = useDetectLocale();
  const { setIsOpen, setSteps, setCurrentStep } = useTour();

  useEffect(() => {
    const steps = tourSteps(t) ?? [];
    setSteps?.(steps);
  }, [locale, setSteps, t]);

  return {
    setIsOpen: (b: boolean) => {
      if (b) {
        navigate("/app");
      }
      setCurrentStep(0);
      setIsOpen(b);
    },
  };
}

import { Box, Image } from "@mantine/core";
import { Link } from "react-router-dom";
import { layoutConst } from "src/constants/layout.const";

const Logo = () => {
  // NOTE to switch logo color based on theme
  // const { colorScheme } = useMantineColorScheme();
  // <Image src={`/logo-${colorScheme}.png`} alt="Autom8deal logo" my="lg" />

  return (
    <Box
      component={Link}
      to="/app"
      w={200}
      h={layoutConst.HEADER_HEIGHT}
      mx="auto"
    >
      <Image src={`/logo-black.png`} alt="Autom8deal logo" my="lg" />
    </Box>
  );
};

export default Logo;

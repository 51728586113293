import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import Notification from "src/types/Notification.type";
const ON_SCREEN_DELAY = 5000;

export function showNotificationModal(n: Notification, onClose: () => void) {
  modals.openContextModal({
    modal: "notification",
    title: n.title,
    innerProps: {
      modalBody: n.descFull,
      onClose,
    },
  });
}

export function showNotificationPopup(n: Notification, onClose: () => void) {
  notifications.show({
    title: n.title,
    message: n.desc,
    autoClose: n.sticky ? false : ON_SCREEN_DELAY,
    withCloseButton: false,
    onClick: () => {
      if (n.link) {
        window.location.pathname = n.link;
      }
      onClose();
    },
  });
}

import { TradeType } from "src/types/TradeType";
import useConfig from "./useConfig";

function useTradeTypeValues(type: TradeType) {
  const config = useConfig();
  const label = type + "_label";
  const desc = type + "_desc";
  const cost = config.get(type + "_cost") ?? 0;

  return {
    label,
    cost,
    desc,
  };
}

export default useTradeTypeValues;

import { IconPlus } from "@tabler/icons-react";
import Actions from "src/components/Actions";
import Loader from "src/components/Loader";
import { EmployeeTable } from "src/components/tables/EmployeeTable";
import { useGET } from "src/hooks/useApi";
import Employee from "src/types/Employee.type";

const ListEmployee = () => {
  const { data, isLoading, error } = useGET<Employee[]>(`res/employee`);

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <Actions
        title="my_employees"
        actions={[
          { to: "/app/add/employee", label: "add_new", Icon: IconPlus },
        ]}
      />
      <EmployeeTable employees={data} />
    </Loader>
  );
};

export default ListEmployee;

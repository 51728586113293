const primary = "#fa8b0e";
const secondary = "darkorange";
const tertiary = "dimgrey";

const theme = {
  name: "Autom8deal Theme",
  tokens: {
    components: {
      button: {
        primary: {
          backgroundColor: primary,
          _hover: {
            backgroundColor: secondary,
          },
          _active: {
            backgroundColor: secondary,
          },
        },
        link: {
          color: tertiary,
        },
      },
      fieldcontrol: {
        _focus: {
          boxShadow: `0 0 0 2px ${primary}`,
        },
      },
      tabs: {
        item: {
          color: tertiary,
          _hover: {
            color: secondary,
          },
          _focus: {
            backgroundColor: secondary,
          },
          _active: {
            borderColor: secondary,
            color: primary,
          },
        },
      },
    },
  },
};

export default theme;

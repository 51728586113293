const COMPANY_PROTECTED_FIELDS = [
  "id",
  "account_Notifications",
  "account_Subscription",
  "account_subscriptionId",
  "account_credits",
  "account_activation",
  "JobOffers",
  "Applications_done",
  "Reviews_done",
  "Reviews_received",
  "Proposals_done",
  "Proposals_received",
  "Employees",
  "Admins",
];

export default COMPANY_PROTECTED_FIELDS;

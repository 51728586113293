import { useNavigate, useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import JobForm from "src/components/forms/Job.form";
import JobOffer from "src/types/JobOffer.type";
import useApi, { useGET } from "src/hooks/useApi";

export default function EditJob() {
  const navigate = useNavigate();
  const { id } = useParams();
  const api = useApi();

  const url = `res/job/${id}`;
  const { data, isLoading, error } = useGET<JobOffer>(url);

  const onSubmit = async (values: Partial<JobOffer>) => {
    const res = await api.PUT(`res/job/${id}`, values);
    if (res?.success) {
      navigate(`/app/view/job/${id}`);
    }
  };

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <JobForm
        title="update_job_offer"
        initialValues={data}
        onSubmit={onSubmit}
      />
    </Loader>
  );
}

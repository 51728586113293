import ViewCompanyPage from "src/pages/data/view/ViewCompany.page";
import ViewEmployeePage from "src/pages/data/view/ViewEmployee.page";
import ViewJobPage from "src/pages/data/view/ViewJob.page";
import AddReview from "src/pages/data/add/AddReview";
import AddEmployee from "src/pages/data/add/AddEmployee";
import AddJob from "src/pages/data/add/AddJob";
import EditCompany from "src/pages/data/edit/EditCompany";
import EditEmployee from "src/pages/data/edit/EditEmployee";
import EditJob from "src/pages/data/edit/EditJob";
import RemoveReview from "src/pages/data/remove/RemoveReview";
import RemoveEmployee from "src/pages/data/remove/RemoveEmployee";
import RemoveJob from "src/pages/data/remove/RemoveJob";
import ViewApplicationPage from "src/pages/data/view/ViewApplication.page";
import AddApplication from "src/pages/data/add/AddApplication";
import EditApplication from "src/pages/data/edit/EditApplication";
import RemoveApplication from "src/pages/data/remove/RemoveApplication";
import ViewReviewPage from "src/pages/data/view/ViewReview.page";
import AddAdmin from "src/pages/data/add/AddAdmin";
import EditAdmin from "src/pages/data/edit/EditAdmin";
import RemoveAdmin from "src/pages/data/remove/RemoveAdmin";
import ViewAdminPage from "src/pages/data/view/ViewAdmin.page";
import ListEmployees from "src/pages/data/list/SearchEmployee";
import ListJobs from "src/pages/data/list/SearchJob";
import SearchReview from "src/pages/data/list/SearchReview";
import ViewProposalPage from "src/pages/data/view/ViewProposal.page";
import AddProposal from "src/pages/data/add/AddProposal";
import RemoveProposal from "src/pages/data/remove/RemoveProposal";
import EditProposal from "src/pages/data/edit/EditProposal";
import RedirectToApp from "src/pages/RedirectToApp.tsx";
import ListApplicationDone from "src/pages/data/list/ListApplicationDone";
import ListApplicationReceived from "src/pages/data/list/ListApplicationReceived";
import ListProposalDone from "src/pages/data/list/ListProposalDone";
import ListProposalReceived from "src/pages/data/list/ListProposalReceived";
import ViewInviteRequestPage from "src/pages/data/view/ViewInviteRequest.page";

const crudRoutes = [
  // view data
  {
    path: "view",
    children: [
      {
        index: true,
        element: <RedirectToApp />,
      },
      {
        path: "job",
        element: <ListJobs />,
      },
      {
        path: "employee",
        element: <ListEmployees />,
      },
      {
        path: "application",
        element: (
          <>
            <ListApplicationDone />,
            <ListApplicationReceived />,
          </>
        ),
      },
      {
        path: "proposal",
        element: (
          <>
            <ListProposalDone />,
            <ListProposalReceived />,
          </>
        ),
      },
      {
        path: "review",
        element: <SearchReview />,
      },
      {
        path: "user/:id",
        element: <ViewAdminPage />,
      },
      {
        path: "company/:id",
        element: <ViewCompanyPage />,
      },
      {
        path: "match/:id",
        element: <ViewCompanyPage />,
      },
      {
        path: "employee/:id",
        element: <ViewEmployeePage />,
      },
      {
        path: "application/:id",
        element: <ViewApplicationPage />,
      },
      {
        path: "proposal/:id",
        element: <ViewProposalPage />,
      },
      {
        path: "job/:id",
        element: <ViewJobPage />,
      },
      {
        path: "review/:id",
        element: <ViewReviewPage />,
      },
      {
        path: "inviteRequest/:id",
        element: <ViewInviteRequestPage />,
      },
    ],
  },
  // add data
  {
    path: "add",
    children: [
      {
        index: true,
        element: <RedirectToApp />,
      },
      {
        path: "employee",
        element: <AddEmployee />,
      },
      {
        path: "admin",
        element: <AddAdmin />,
      },
      {
        path: "job",
        element: <AddJob />,
      },
      {
        path: "application/:jobId",
        element: <AddApplication />,
      },
      {
        path: "proposal/:employeeId",
        element: <AddProposal />,
      },
      {
        path: "review/:jobId",
        element: <AddReview />,
      },
      {
        path: "user",
        element: <AddAdmin />,
      },
    ],
  },
  // edit data
  {
    path: "edit",
    children: [
      {
        index: true,
        element: <RedirectToApp />,
      },
      {
        path: "company/:id",
        element: <EditCompany />,
      },
      {
        path: "employee/:id",
        element: <EditEmployee />,
      },
      {
        path: "admin/:id",
        element: <EditAdmin />,
      },
      {
        path: "job/:id",
        element: <EditJob />,
      },
      {
        path: "application/:id",
        element: <EditApplication />,
      },
      {
        path: "proposal/:id",
        element: <EditProposal />,
      },
      {
        path: "user/:id",
        element: <EditAdmin />,
      },
    ],
  },
  // remove data
  {
    path: "remove",
    children: [
      {
        index: true,
        element: <RedirectToApp />,
      },
      {
        path: "employee/:id",
        element: <RemoveEmployee />,
      },
      {
        path: "admin/:id",
        element: <RemoveAdmin />,
      },
      {
        path: "job/:id",
        element: <RemoveJob />,
      },
      {
        path: "review/:id",
        element: <RemoveReview />,
      },
      {
        path: "application/:id",
        element: <RemoveApplication />,
      },
      {
        path: "proposal/:id",
        element: <RemoveProposal />,
      },
      {
        path: "user/:id",
        element: <RemoveAdmin />,
      },
    ],
  },
];

export default crudRoutes;

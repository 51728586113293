import {
  Badge,
  Button,
  InputLabel,
  NavLink,
  Paper,
  Pill,
  Table,
  Tabs,
  Title,
  createTheme,
} from "@mantine/core";

export const autom8dealTheme = createTheme({
  white: "#F9FAFB",
  primaryColor: "primary",
  cursorType: "pointer",
  scale: 0.9,
  colors: {
    primary: [
      "#fff4e3",
      "#ffe8ce",
      "#fdcf9e",
      "#fab469",
      "#f99d3d",
      "#f89022",
      "#f88812",
      "#dd7506",
      "#c66700",
      "#ac5700",
    ],
    secondary: [
      "#f6eeff",
      "#e7daf7",
      "#cab1ea",
      "#ad86dd",
      "#9562d2",
      "#854bcb",
      "#7d3ec9",
      "#6b31b2",
      "#5f2aa0",
      "#52228d",
    ],
    tertiary: [
      "#eef3ff",
      "#dee2f2",
      "#bdc2de",
      "#98a0ca",
      "#7a84ba",
      "#6672b0",
      "#5c68ac",
      "#4c5897",
      "#424e88",
      "#364379",
    ],
    rates: [
      "#000000",
      "#C91A52",
      "#FC8C0C",
      "#6BD731",
      "#1F32C4",
      "#C91A52",
      "#7B2EDA",
      "#b31220",
      "#9e0419",
      "#9e0419",
    ],
    scores: [
      "#C91A25",
      "#C91A25",
      "#FC8C0C",
      "#FC8C0C",
      "#FFAB09",
      "#FFAB09",
      "#D9D02F",
      "#D9D02F",
      "#6BD731",
      "#6BD731",
    ],
  },
  components: {
    Tabs: Tabs.extend({
      defaultProps: {
        variant: "pills",
      },
    }),
    Table: Table.extend({
      defaultProps: {
        withTableBorder: true,
        withColumnBorders: false,
      },
    }),
    Button: Button.extend({
      defaultProps: {
        tt: "capitalize",
      },
    }),
    Title: Title.extend({
      defaultProps: {
        tt: "capitalize",
      },
    }),
    NavLink: NavLink.extend({
      defaultProps: {
        tt: "capitalize",
      },
    }),
    Paper: Paper.extend({
      defaultProps: {
        withBorder: true,
        radius: "md",
        p: "md",
        shadow: "sm",
      },
    }),
    InputLabel: InputLabel.extend({
      defaultProps: {
        tt: "capitalize",
      },
    }),
    Badge: Badge.extend({
      defaultProps: {
        variant: "light",
      },
    }),
    Pill: Pill.extend({
      defaultProps: {
        variant: "light",
        c: "black",
      },
    }),
  },
});

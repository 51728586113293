import { Button, Card, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import useApi from "src/hooks/useApi";
import { useNavigate } from "react-router-dom";

const isBetaMode = import.meta.env.VITE_BETA_MODE === "true";

export default function CreditsBetaCard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const API = useApi();
  const assignFreeCredits = () => {
    API.PUT("demo/package", {});
    navigate("/app");
  };

  if (!isBetaMode) {
    return null;
  }

  return (
    <Card
      miw={300}
      maw={300}
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      mx={0}
    >
      <Title order={3} fw={900} ta="center" my="md" c="darkred">
        {t("recharge_credits")}
      </Title>

      <Title fw={900} ta="center">
        ({t("free")})
      </Title>

      <Button fullWidth mt="md" radius="md" onClick={assignFreeCredits}>
        {t("buy_now")} ({t("free")})
      </Button>
    </Card>
  );
}

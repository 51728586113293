import { Box } from "@mantine/core";
import { Outlet } from "react-router-dom";
import Activity from "src/pages/Activity";
import ListAdmin from "src/pages/data/list/ListAdmin";
import ListApplicationDone from "src/pages/data/list/ListApplicationDone";
import ListApplicationReceived from "src/pages/data/list/ListApplicationReceived";
import ListEmployee from "src/pages/data/list/ListEmployee";
import ListInvites from "src/pages/data/list/ListInvites";
import ListJobOffer from "src/pages/data/list/ListJobOffer";
import ListMatches from "src/pages/data/list/ListMatches";
import ListProposalDone from "src/pages/data/list/ListProposalDone";
import ListProposalReceived from "src/pages/data/list/ListProposalReceived";

const sectionRoutes = [
  {
    path: "section",
    element: (
      <Box my="md">
        <Outlet />
      </Box>
    ),
    children: [
      {
        path: "activity",
        element: <Activity />,
      },
      {
        path: "jobs",
        element: <ListJobOffer />,
      },
      {
        path: "employees",
        element: <ListEmployee />,
      },
      {
        path: "admins",
        element: <ListAdmin />,
      },
      {
        path: "applications_received",
        element: <ListApplicationReceived />,
      },
      {
        path: "applications_sent",
        element: <ListApplicationDone />,
      },
      {
        path: "proposals_received",
        element: <ListProposalReceived />,
      },
      {
        path: "proposals_sent",
        element: <ListProposalDone />,
      },
      {
        path: "matches",
        element: <ListMatches />,
      },
      {
        path: "reviews",
        element: <>TODO</>,
      },
      {
        path: "invites",
        element: <ListInvites />,
      },
    ],
  },
];

export default sectionRoutes;

import { Flex, Paper, Stack, Title } from "@mantine/core";
import Proposal from "src/types/Proposal.type";
import Actions from "../Actions";
import { Row } from "../Row";
import JobOfferCard from "../cards/Job.Card";
import EmployeeCard from "../cards/Employee.Card";
import CompanyFromTo from "../CompanyFromTo";
import { ProposalStatus } from "src/types/ProposalStatus.type";
import useApi from "src/hooks/useApi";
import { useNavigate } from "react-router-dom";
import SeeMore from "../SeeMore";
import ProposalStatusPill from "../ProposalStatusPill";
import { useTranslation } from "react-i18next";
import ExpiryStatusPill from "../ExpiryStatusPill";
import Description from "../Description";
import useIsOwn from "src/hooks/useIsOwn";

interface Props {
  proposal: Proposal;
}

const ProposalView = ({ proposal }: Props) => {
  const { id, desc, status, toEmployee, JobOffer } = proposal;
  const isOwn = useIsOwn(proposal);

  const API = useApi();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const editProposal = (newStatus: ProposalStatus) => {
    API.PUT(`res/proposal/${id}`, { status: newStatus });
    if (newStatus === ProposalStatus.accepted) {
      navigate(`/app/section/matches`);
    }
  };

  return (
    <Stack gap="md">
      <Title>{t("proposal")}</Title>

      <Row title={t("company")}>
        <Flex direction="column">
          <Paper my="md">
            <CompanyFromTo el={proposal} />
          </Paper>

          <SeeMore isOwn={isOwn} type="proposal" />
        </Flex>
      </Row>

      <Row title={t("job_offer")}>
        <JobOfferCard jobOffer={JobOffer} />
      </Row>

      <Row title={t("status")}>
        <ProposalStatusPill status={status} />
      </Row>

      {isOwn && (
        <Row title={t("expiry_date_title")}>
          <ExpiryStatusPill item={proposal} type="proposal" />
        </Row>
      )}

      <Row title={t("description")}>
        <Description text={desc} />
      </Row>

      <Row title={t("proposed_employee")}>
        <EmployeeCard employee={toEmployee} />
      </Row>

      <Actions
        actions={[
          // send proposal
          isOwn && status === ProposalStatus.draft
            ? {
                label: "send_proposal",
                onClick: () => editProposal(ProposalStatus.open),
              }
            : null,

          // decline proposal
          isOwn
            ? null
            : {
                label: "decline_proposal",
                onClick: () => editProposal(ProposalStatus.denied),
                disabled: status !== ProposalStatus.open,
              },

          // accept proposal
          isOwn
            ? null
            : {
                label: "accept_proposal",
                onClick: () => editProposal(ProposalStatus.accepted),
                disabled: status !== ProposalStatus.open,
              },
        ]}
      />
    </Stack>
  );
};

export default ProposalView;

import API from "../helpers/api";
import useSWR from "swr";
import { useMemo } from "react";
import { methodEnum } from "src/types/Method.type";
import useAuth from "./useAuth";

export const useApi = () => {
  const { token } = useAuth();

  return useMemo(() => API(token), [token]);
};

export default useApi;

export function useGET<T>(url: string | null, opts?: { isPublic?: boolean }) {
  const { token } = useAuth();
  const api = useApi();
  const key = opts?.isPublic || token ? url : null;

  const { data, isLoading, error, mutate } = useSWR(key, api[methodEnum.GET], {
    errorRetryInterval: 4000,
    errorRetryCount: 5,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
  });

  return {
    data: data as T,
    isLoading: isLoading || !token,
    error,
    mutate,
  };
}

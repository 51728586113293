import _isNull from "lodash/isNull";
import { Box, InputLabel, Text, TextInput } from "@mantine/core";
import useApi from "src/hooks/useApi";
import { ReactNode, useState } from "react";
import { IconCircleCheck, IconXboxX } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

interface Props {
  value: string;
  setValue: (s: string) => void;
  fieldName: "vat" | "email" | "slug";
  leftSection?: ReactNode;
  leftSectionWidth?: number;
}

export default function InputUnique({
  fieldName,
  value,
  setValue,
  leftSection,
  leftSectionWidth,
}: Props) {
  const [valid, setValid] = useState<boolean | null>(null);
  const [err, setErr] = useState<string | null>(null);
  const API = useApi();
  const { t } = useTranslation();

  const checkValue = async () => {
    setValid(null);
    setErr(null);
    if (!value) {
      setValid(false);
      setErr("missing_value");
      return;
    }
    // ex. /search/is_already_taken/vat/123
    const res = await API.GET(`search/is_already_taken/${fieldName}/${value}`);
    const validity = !res?.result;
    setValid(validity);
    if (!validity) {
      setErr("value_taken_already");
    }
  };

  const foundIcon = _isNull(valid) ? null : valid ? (
    <IconCircleCheck color="green" />
  ) : (
    <IconXboxX color="red" />
  );

  return (
    <Box my="md">
      <InputLabel tt="capitalize">{t(fieldName)} *</InputLabel>
      <TextInput
        value={value ?? ""}
        onChange={(ev) => setValue(ev.currentTarget.value)}
        onBlur={() => checkValue()}
        onFocus={() => setValid(null)}
        rightSection={foundIcon}
        leftSection={leftSection}
        leftSectionWidth={leftSectionWidth}
      />
      {err && (
        <Text size="xs" c="red">
          {t(err)}
        </Text>
      )}
    </Box>
  );
}

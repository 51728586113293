import { formatDate } from "src/helpers/formatDate";
import { useTranslation } from "react-i18next";
import { Badge } from "@mantine/core";

interface Props {
  from?: string | null;
  to?: string | null;
  small?: boolean;
}

function Availability({ from, to }: Props) {
  const { t } = useTranslation();
  const now = new Date();
  const dateFrom = from ? new Date(from) : from;
  const dateTo = to ? new Date(to) : to;
  const available = <Badge color="teal">{t("available")}</Badge>;
  const unavailable = <Badge color="grey">{t("unavailable")}</Badge>;

  if (!dateFrom && !dateTo) {
    return available;
  }

  const isAvailableNow =
    (!dateFrom || dateFrom < now) && (!dateTo || now < dateTo);
  const color = isAvailableNow ? "teal" : "grey";

  // if (small) {
  //   const content = [dateFrom, dateTo]
  //     .filter((d) => !!d)
  //     .map(formatDate)
  //     .join(" > ");

  //   return content && <Pill label={content} bg="gray" c="white" Icon={null} />;
  // }

  if (!dateTo && dateFrom) {
    return dateFrom < now ? (
      available
    ) : (
      <Badge color={color}>{`${t("from")} ${formatDate(dateFrom)}`}</Badge>
    );
  }

  if (dateTo && (!dateFrom || dateFrom < now)) {
    return dateTo > now ? (
      <Badge color={color}>{`${t("until")} ${formatDate(dateTo)}`}</Badge>
    ) : (
      unavailable
    );
  }

  if (dateFrom && dateTo) {
    if (dateTo < dateFrom || dateTo < now) {
      return unavailable;
    }
  }

  return (
    <Badge color={color}>{`${t("from")} ${formatDate(dateFrom)} ${t(
      "to"
    )} ${formatDate(dateTo)}`}</Badge>
  );
}

export default Availability;

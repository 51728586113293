import { modals } from "@mantine/modals";
import { translate } from "./initLanguage";

function showReloadModal(title: string, desc: string) {
  modals.closeAll();
  modals.openContextModal({
    modal: "notification",
    title: translate(title),
    trapFocus: true,
    closeOnEscape: false,
    closeOnClickOutside: false,
    withCloseButton: false,
    innerProps: {
      modalBody: translate(desc),
      onClose: () => {
        window.location.reload();
      },
    },
  });
}

export default showReloadModal;

// import { mutate } from "swr";
import logout from "src/utils/logout";
import { Button } from "@mantine/core";
import { useTranslation } from "react-i18next";
import useUser from "src/hooks/useUser";

const LogoutButton = () => {
  const { user } = useUser();
  const { t } = useTranslation();

  if (!user) {
    return null;
  }

  const clickHandler = () => {
    logout();
    window.location.reload();
  };

  return (
    <Button onClick={clickHandler} fullWidth>
      {t("logout")}
    </Button>
  );
};

export default LogoutButton;

import { Badge } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { ProposalStatus } from "src/types/ProposalStatus.type";

interface Props {
  status: ProposalStatus;
}

const ProposalStatusPill = (props: Props) => {
  const { status } = props;
  const { t } = useTranslation();

  switch (status) {
    case ProposalStatus.cancelled: {
      return <Badge color="yellow">{t("canceled")}</Badge>;
    }
    case ProposalStatus.denied: {
      return <Badge color="orange">{t("denied")}</Badge>;
    }
    case ProposalStatus.open: {
      return <Badge color="green">{t("open")}</Badge>;
    }
    case ProposalStatus.draft: {
      return <Badge color="grey">{t("draft")}</Badge>;
    }
    case ProposalStatus.archived: {
      return <Badge color="grey">{t("archived")}</Badge>;
    }
    default: {
      return <>{status}</>;
    }
  }
};

export default ProposalStatusPill;

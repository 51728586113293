import _size from "lodash/size";
import { TooltipGroup, AvatarGroup, Flex, Tooltip, Box } from "@mantine/core";
import { TooltipAvatar } from "src/components/badges/TooltipAvatar";
import Employee from "src/types/Employee.type";
import Avatar from "../Avatar";

interface Props {
  data: Employee[];
  cap?: number;
}

export const EmployeeList = ({ data, cap = 3 }: Props) => {
  const visibleEls = data.slice(0, cap);
  const hiddenEls = data.slice(cap);

  return (
    <TooltipGroup>
      <AvatarGroup>
        <Flex align="center">
          {visibleEls.map(({ name, profilePic }, index) => (
            <TooltipAvatar key={index} label={name} src={profilePic} />
          ))}
          {_size(hiddenEls) > 0 && (
            <Tooltip
              withArrow
              label={
                <>
                  {hiddenEls.map(({ name }, index) => (
                    <Box key={index}>{name}</Box>
                  ))}
                </>
              }
            >
              <Avatar size={36}>{`+${_size(hiddenEls)}`}</Avatar>
            </Tooltip>
          )}
        </Flex>
      </AvatarGroup>
    </TooltipGroup>
  );
};

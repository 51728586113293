const supportedLocales = [
  { label: "italiano", value: "it" },
  { label: "english", value: "en" },
];

export const DEFAULT_LOCALE = "en";
export const SUPPORTED_LOCALES = ["en", "it"];

export default supportedLocales;

export const supportedLocalesCodes = supportedLocales.map((l) => l.value);

import _get from "lodash/get";
import _map from "lodash/map";
import _isString from "lodash/isString";
import { Badge } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface Props {
  value?: string | string[];
  naked?: boolean;
}

const colors = {
  Mechanic: "orange",
  Expert: "violet",
  Technician: "teal",
  Manager: "green",
  Speaker: "blue",
  Developer: "red",
  Tester: "yellow",
};

const JobTitle = ({ value, naked = true }: Props) => {
  const { t } = useTranslation();

  if (_isString(value)) {
    if (naked) {
      return t(value);
    }

    return (
      <Badge color={_get(colors, value, "default")} variant="transparent">
        {t(value)}
      </Badge>
    );
  }

  if (naked) {
    return <>{_map(value, (v) => t(v)).join(", ")}</>;
  }

  return _map(value, (v) => (
    <Badge c={_get(colors, v, "default")} variant="transparent">
      {t(v)}
    </Badge>
  ));
};

export default JobTitle;
